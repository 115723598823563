export const names = [
  {
    "id": "relation/938659",
    "name": "Ики-Бурульский район"
  },
  {
    "id": "relation/938660",
    "name": "Юстинский район"
  },
  {
    "id": "relation/938661",
    "name": "Сарпинский район"
  },
  {
    "id": "relation/938662",
    "name": "Кетченеровский район"
  },
  {
    "id": "relation/938663",
    "name": "Яшкульский район"
  },
  {
    "id": "relation/938664",
    "name": "Черноземельский район"
  },
  {
    "id": "relation/938665",
    "name": "Целинный район"
  },
  {
    "id": "relation/938666",
    "name": "Яшалтинский район"
  },
  {
    "id": "relation/938668",
    "name": "Малодербетовский район"
  },
  {
    "id": "relation/938732",
    "name": "Городовиковский район"
  },
  {
    "id": "relation/938733",
    "name": "Октябрьский район"
  },
  {
    "id": "relation/938734",
    "name": "Лаганский район"
  },
  {
    "id": "relation/942471",
    "name": "городской округ Элиста"
  },
  {
    "id": "relation/945066",
    "name": "Приютненский район"
  },
  {
    "id": "node/191648647"
  },
  {
    "id": "node/810728424"
  },
  {
    "id": "node/895164397"
  },
  {
    "id": "node/935391368"
  },
  {
    "id": "node/935471197"
  },
  {
    "id": "node/935566173"
  },
  {
    "id": "node/936162170"
  },
  {
    "id": "node/936169076"
  },
  {
    "id": "node/936186502"
  },
  {
    "id": "node/1401400307"
  },
  {
    "id": "node/1401401726"
  },
  {
    "id": "node/1784377069"
  },
  {
    "id": "node/2766548814"
  },
  {
    "id": "node/3030179770"
  }
]
;