import {useAppSelector} from './app';
import {useParams} from 'react-router-dom';
import {useEffect, useState} from 'react';
import {formatRegionId} from '../helpers';

export const useCreateParams = (): { params: string; skip: boolean; } => {
    const {regionId: fromParamsId} = useParams<{ regionId: string }>();
    const {selectSchemaQuestion, regionId} = useAppSelector(state => state.home);
    const [params, setParams] = useState<string>('');
    useEffect(() => {
        const ID_Sect = selectSchemaQuestion?.ID_Sect;

        //@todo здесь FGeoIDs принимает все подряд и отдает только КБР relation/rus (надо чтоб отдавало Россию)
        let params: string = `FGeoIDs=${!fromParamsId
            ? formatRegionId(regionId.includes('relation/') ? 'removeRelation' : 'addRelation', String(regionId))
            : formatRegionId('addRelation', fromParamsId)}&GroupYears=1`;

        if (ID_Sect) {
            params = `${params}&ID_Sect=${ID_Sect}`;
        }

        setParams(params);
    }, [selectSchemaQuestion, regionId, fromParamsId]);

    return {params, skip: selectSchemaQuestion === null};
};
