export const names = [
  {
    "id": "relation/1029226",
    "name": "Рыбновский район"
  },
  {
    "id": "relation/1029227",
    "name": "Клепиковский район"
  },
  {
    "id": "relation/1042919",
    "name": "Касимовский район"
  },
  {
    "id": "relation/1046955",
    "name": "Пителинский район"
  },
  {
    "id": "relation/1046957",
    "name": "Сасовский муниципальный округ"
  },
  {
    "id": "relation/1046958",
    "name": "Кадомский район"
  },
  {
    "id": "relation/1046959",
    "name": "Ермишинский район"
  },
  {
    "id": "relation/1047039",
    "name": "Шацкий район"
  },
  {
    "id": "relation/1047040",
    "name": "Сараевский район"
  },
  {
    "id": "relation/1047071",
    "name": "Ухоловский район"
  },
  {
    "id": "relation/1047072",
    "name": "Александро-Невский район"
  },
  {
    "id": "relation/1047073",
    "name": "Ряжский муниципальный округ"
  },
  {
    "id": "relation/1059364",
    "name": "городской округ Скопин"
  },
  {
    "id": "relation/1059365",
    "name": "Михайловский район"
  },
  {
    "id": "relation/1059366",
    "name": "Захаровский район"
  },
  {
    "id": "relation/1059367",
    "name": "Скопинский район"
  },
  {
    "id": "relation/1059368",
    "name": "Милославский район"
  },
  {
    "id": "relation/1059427",
    "name": "Пронский район"
  },
  {
    "id": "relation/1059470",
    "name": "Кораблинский район"
  },
  {
    "id": "relation/1066073",
    "name": "Чучковский район"
  },
  {
    "id": "relation/1066074",
    "name": "Сапожковский район"
  },
  {
    "id": "relation/1066075",
    "name": "Путятинский муниципальный округ"
  },
  {
    "id": "relation/1066111",
    "name": "Рязанский район"
  },
  {
    "id": "relation/1066112",
    "name": "Шиловский район"
  },
  {
    "id": "relation/1066113",
    "name": "Старожиловский район"
  },
  {
    "id": "relation/1066114",
    "name": "Спасский район"
  },
  {
    "id": "relation/1066115",
    "name": "городской округ Рязань"
  },
  {
    "id": "relation/3537901",
    "name": "городской округ Касимов"
  },
  {
    "id": "node/27505582"
  },
  {
    "id": "node/265857299"
  },
  {
    "id": "node/275666218"
  },
  {
    "id": "node/278762572"
  },
  {
    "id": "node/278769413"
  },
  {
    "id": "node/278770412"
  },
  {
    "id": "node/300156975"
  },
  {
    "id": "node/301648440"
  },
  {
    "id": "node/309353206"
  },
  {
    "id": "node/333892499"
  },
  {
    "id": "node/333892554"
  },
  {
    "id": "node/333892583"
  },
  {
    "id": "node/334723403"
  },
  {
    "id": "node/334723420"
  },
  {
    "id": "node/334723480"
  },
  {
    "id": "node/336521645"
  },
  {
    "id": "node/336521940"
  },
  {
    "id": "node/346273331"
  },
  {
    "id": "node/346273375"
  },
  {
    "id": "node/346277367"
  },
  {
    "id": "node/603272915"
  },
  {
    "id": "node/1410764043"
  },
  {
    "id": "node/2106879314"
  },
  {
    "id": "node/2721095815"
  },
  {
    "id": "node/3854547689"
  },
  {
    "id": "node/3916235884"
  }
]
;