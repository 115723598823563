import {Action, configureStore, Reducer, ThunkAction} from '@reduxjs/toolkit';
import {persistReducer, persistStore} from 'redux-persist';
// @ts-ignore
import {encryptTransform} from 'redux-persist-transform-encrypt';
import storage from 'redux-persist/lib/storage';
import {api} from './api';
import {rootReducer} from './rootReducer';
import {logout} from '../screens/ayth/store/auth.slice';

const persistConfig = {
    key: 'statistic',
    storage,
    transforms: []
};

type StateReducer = ReturnType<typeof rootReducer>;


const appReducer: Reducer<StateReducer | undefined, Action> = (state: any, action) => {
    if (action.type === logout.type) {
        state = undefined;
    }
    return rootReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, appReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware => getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false
    }).concat(api.middleware as any)
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType,
    RootState,
    unknown,
    Action<string>>;
