export const names = [
  {
    "id": "relation/382772",
    "name": "Лузский муниципальный округ"
  },
  {
    "id": "relation/382773",
    "name": "Подосиновский район"
  },
  {
    "id": "relation/382774",
    "name": "Опаринский муниципальный округ"
  },
  {
    "id": "relation/382775",
    "name": "Даровской район"
  },
  {
    "id": "relation/382776",
    "name": "городской округ Киров"
  },
  {
    "id": "relation/382777",
    "name": "Мурашинский муниципальный округ"
  },
  {
    "id": "relation/382778",
    "name": "Нагорский район"
  },
  {
    "id": "relation/382779",
    "name": "Верхнекамский муниципальный округ"
  },
  {
    "id": "relation/382780",
    "name": "Белохолуницкий район"
  },
  {
    "id": "relation/382781",
    "name": "Афанасьевский муниципальный округ"
  },
  {
    "id": "relation/382782",
    "name": "Омутнинский район"
  },
  {
    "id": "relation/382783",
    "name": "Слободской район"
  },
  {
    "id": "relation/382784",
    "name": "Юрьянский район"
  },
  {
    "id": "relation/382785",
    "name": "Шабалинский район"
  },
  {
    "id": "relation/382786",
    "name": "Свечинский муниципальный округ"
  },
  {
    "id": "relation/382787",
    "name": "Котельничский район"
  },
  {
    "id": "relation/382788",
    "name": "Орловский район"
  },
  {
    "id": "relation/382789",
    "name": "Оричевский район"
  },
  {
    "id": "relation/383504",
    "name": "Тужинский район"
  },
  {
    "id": "relation/383505",
    "name": "Кикнурский муниципальный округ"
  },
  {
    "id": "relation/383506",
    "name": "Санчурский муниципальный округ"
  },
  {
    "id": "relation/383507",
    "name": "Яранский район"
  },
  {
    "id": "relation/383508",
    "name": "Пижанский муниципальный округ"
  },
  {
    "id": "relation/383509",
    "name": "Арбажский муниципальный округ"
  },
  {
    "id": "relation/383510",
    "name": "Верхошижемский район"
  },
  {
    "id": "relation/383511",
    "name": "Кирово-Чепецкий район"
  },
  {
    "id": "relation/383512",
    "name": "Кумёнский район"
  },
  {
    "id": "relation/383513",
    "name": "Зуевский район"
  },
  {
    "id": "relation/383514",
    "name": "Фалёнский муниципальный округ"
  },
  {
    "id": "relation/383515",
    "name": "Унинский муниципальный округ"
  },
  {
    "id": "relation/383516",
    "name": "Богородский муниципальный округ"
  },
  {
    "id": "relation/383517",
    "name": "Сунский район"
  },
  {
    "id": "relation/383518",
    "name": "Советский район"
  },
  {
    "id": "relation/383519",
    "name": "Нолинский район"
  },
  {
    "id": "relation/383520",
    "name": "Немский муниципальный округ"
  },
  {
    "id": "relation/383521",
    "name": "Кильмезский район"
  },
  {
    "id": "relation/383522",
    "name": "Лебяжский муниципальный округ"
  },
  {
    "id": "relation/383523",
    "name": "Уржумский район"
  },
  {
    "id": "relation/383524",
    "name": "Малмыжский район"
  },
  {
    "id": "relation/383525",
    "name": "Вятскополянский район"
  },
  {
    "id": "relation/939894",
    "name": "городской округ Кирово-Чепецк"
  },
  {
    "id": "relation/1257459",
    "name": "городской округ Слободской"
  },
  {
    "id": "relation/1257470",
    "name": "городской округ Вятские Поляны"
  },
  {
    "id": "relation/1257471",
    "name": "городской округ Котельнич"
  },
  {
    "id": "relation/1957690",
    "name": "ЗАТО Первомайский"
  },
  {
    "id": "node/113548786"
  },
  {
    "id": "node/206864037"
  },
  {
    "id": "node/256827443"
  },
  {
    "id": "node/256827445"
  },
  {
    "id": "node/256827452"
  },
  {
    "id": "node/262830281"
  },
  {
    "id": "node/264358670"
  },
  {
    "id": "node/264365304"
  },
  {
    "id": "node/264365776"
  },
  {
    "id": "node/268926481"
  },
  {
    "id": "node/296081305"
  },
  {
    "id": "node/296086848"
  },
  {
    "id": "node/296087893"
  },
  {
    "id": "node/296100014"
  },
  {
    "id": "node/296113691"
  },
  {
    "id": "node/308664931"
  },
  {
    "id": "node/308800172"
  },
  {
    "id": "node/335978498"
  },
  {
    "id": "node/335978567"
  },
  {
    "id": "node/335978754"
  },
  {
    "id": "node/335978758"
  },
  {
    "id": "node/335978775"
  },
  {
    "id": "node/335978841"
  },
  {
    "id": "node/380396038"
  },
  {
    "id": "node/391759642"
  },
  {
    "id": "node/431436050"
  },
  {
    "id": "node/579616277"
  },
  {
    "id": "node/579616280"
  },
  {
    "id": "node/699584905"
  },
  {
    "id": "node/699584906"
  },
  {
    "id": "node/699584907"
  },
  {
    "id": "node/699584909"
  },
  {
    "id": "node/699584912"
  },
  {
    "id": "node/699584915"
  },
  {
    "id": "node/699584917"
  },
  {
    "id": "node/1376452597"
  },
  {
    "id": "node/1376491063"
  },
  {
    "id": "node/1418597188"
  },
  {
    "id": "node/1453829692"
  },
  {
    "id": "node/2204273096"
  }
]
;