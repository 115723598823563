import React from 'react';
import {
    WorkActivityBlock,
    WorkActivityCard,
    WorkActivityCircle,
    WorkActivityCol,
    WorkActivityIcon,
    WorkActivityRow,
    WorkActivitySeparator
} from '../styled';
import {workActivity} from '../config';
import {TextS} from '../../../fields';

export const WorkActivity = () => {
    return (
        <WorkActivityBlock>
            {workActivity.map((item, index) => (
                <WorkActivityRow key={index} reverse={index % 2 === 0}>
                    <WorkActivityCol>
                        {index % 2 === 0
                            ? <WorkActivityIcon>
                                <WorkActivityCircle reverse={index % 2 === 0}/>
                            </WorkActivityIcon> : null}
                        <WorkActivityCard reverse={index % 2 === 0}>
                            <TextS
                                as={'span'} fontSize={0.8} fontWeight={400}
                                color={'var(--text-color)'}
                            >
                                {item.text}
                            </TextS>
                        </WorkActivityCard>
                        {index % 2 !== 0 ? <WorkActivityIcon>
                            <WorkActivityCircle reverse={index % 2 === 0}/>
                        </WorkActivityIcon> : null}
                    </WorkActivityCol>
                    <WorkActivitySeparator/>
                    <WorkActivityCol>
                        <TextS
                            as={'span'} fontSize={0.8} fontWeight={400}
                            color={'var(--text-color)'}
                        >
                            {item.date}
                        </TextS>
                    </WorkActivityCol>
                </WorkActivityRow>
            ))}
        </WorkActivityBlock>
    );
};
