import {createSlice} from '@reduxjs/toolkit';
import {regionMapStore} from './types';
import {regionMapApi} from './regionMap.api';

export const defaultRegionsData = {
    data: [],
    totalValues: 0,
    min: 0,
    max: 0
};

const initialState: regionMapStore = {
    subjectRegionName: '',
    regions: defaultRegionsData,
    regionObjects: [],
    selectedGroupIndex: null
};

const regionMapSlice = createSlice({
    name: 'regionMap',
    initialState,
    reducers: {
        changeSubjectRegionName: (state, {payload}) => {
            state.subjectRegionName = payload;
        },
        setSelectedGroupIndex: (state, {payload}) => {
            state.selectedGroupIndex = payload;
        },
        clearRegions: (state) => {
            state.regions = defaultRegionsData;
        }
    },
    extraReducers: builder => {
        builder.addMatcher(
            regionMapApi.endpoints.getRegionsParams.matchFulfilled,
            (state, {payload}) => {
                state.regions = payload;
            }
        );
        builder.addMatcher(
            regionMapApi.endpoints.getRegionsParams.matchRejected,
            (state) => {
                state.regions = defaultRegionsData;
            },
        );
        builder.addMatcher(
            regionMapApi.endpoints.getRegionObjectList.matchFulfilled,
            (state, {payload}) => {
                state.regionObjects = payload;
            }
        );
    }
});

export const {
    changeSubjectRegionName,
    setSelectedGroupIndex,
    clearRegions
} = regionMapSlice.actions;
export default regionMapSlice.reducer;
