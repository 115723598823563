import React from 'react';

export const Down = ({rotate}: { rotate: boolean }) => {
    return (
        <svg
            style={{transform: `rotate(${rotate ? 180 : 0}deg)`}}
            width="20"
            height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M5.70711 6.79289C5.31658 6.40237 4.68342 6.40237 4.29289 6.79289C3.90237 7.18342 3.90237 7.81658 4.29289 8.20711L9.29289 13.2071C9.68342 13.5976 10.3166 13.5976 10.7071 13.2071L15.7071 8.20711C16.0976 7.81658 16.0976 7.18342 15.7071 6.79289C15.3166 6.40237 14.6834 6.40237 14.2929 6.79289L10 11.0858L5.70711 6.79289Z"
                fill={'#205998FF'} fillOpacity="0.85"/>
        </svg>

    );
};
