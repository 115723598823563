export const names = [
  {
    "id": "relation/191279",
    "name": "Енисейский район"
  },
  {
    "id": "relation/1143780",
    "name": "Назаровский район"
  },
  {
    "id": "relation/1147807",
    "name": "Ужурский район"
  },
  {
    "id": "relation/1147813",
    "name": "Шарыповский муниципальный округ"
  },
  {
    "id": "relation/1147949",
    "name": "Балахтинский район"
  },
  {
    "id": "relation/1149758",
    "name": "Боготольский район"
  },
  {
    "id": "relation/1149989",
    "name": "Ачинский район"
  },
  {
    "id": "relation/1150328",
    "name": "Козульский район"
  },
  {
    "id": "relation/1152534",
    "name": "Новосёловский район"
  },
  {
    "id": "relation/1152538",
    "name": "Краснотуранский район"
  },
  {
    "id": "relation/1152577",
    "name": "Курагинский район"
  },
  {
    "id": "relation/1152594",
    "name": "Идринский район"
  },
  {
    "id": "relation/1152899",
    "name": "Минусинский район"
  },
  {
    "id": "relation/1152935",
    "name": "Шушенский район"
  },
  {
    "id": "relation/1153023",
    "name": "Каратузский район"
  },
  {
    "id": "relation/1153029",
    "name": "Ермаковский район"
  },
  {
    "id": "relation/1153070",
    "name": "городской округ Сосновоборск"
  },
  {
    "id": "relation/1153097",
    "name": "городской округ Дивногорск"
  },
  {
    "id": "relation/1153131",
    "name": "городской округ Лесосибирск"
  },
  {
    "id": "relation/1153175",
    "name": "городской округ Ачинск"
  },
  {
    "id": "relation/1157389",
    "name": "Емельяновский район"
  },
  {
    "id": "relation/1157393",
    "name": "городской округ Красноярск"
  },
  {
    "id": "relation/1157397",
    "name": "Сухобузимский район"
  },
  {
    "id": "relation/1157414",
    "name": "Канский район"
  },
  {
    "id": "relation/1157476",
    "name": "городской округ Канск"
  },
  {
    "id": "relation/1157550",
    "name": "Иланский район"
  },
  {
    "id": "relation/1157555",
    "name": "Нижнеингашский район"
  },
  {
    "id": "relation/1157565",
    "name": "Рыбинский район"
  },
  {
    "id": "relation/1157597",
    "name": "Уярский район"
  },
  {
    "id": "relation/1157620",
    "name": "Берёзовский район"
  },
  {
    "id": "relation/1157671",
    "name": "Манский район"
  },
  {
    "id": "relation/1157782",
    "name": "Партизанский район"
  },
  {
    "id": "relation/1159135",
    "name": "Саянский район"
  },
  {
    "id": "relation/1159138",
    "name": "Ирбейский район"
  },
  {
    "id": "relation/1159212",
    "name": "Абанский район"
  },
  {
    "id": "relation/1161670",
    "name": "Дзержинский район"
  },
  {
    "id": "relation/1161672",
    "name": "Большемуртинский район"
  },
  {
    "id": "relation/1161676",
    "name": "Казачинский район"
  },
  {
    "id": "relation/1161786",
    "name": "Пировский муниципальный округ"
  },
  {
    "id": "relation/1161797",
    "name": "Большеулуйский район"
  },
  {
    "id": "relation/1162476",
    "name": "Тюхтетский муниципальный округ"
  },
  {
    "id": "relation/1162482",
    "name": "Бирилюсский район"
  },
  {
    "id": "relation/1162501",
    "name": "Мотыгинский район"
  },
  {
    "id": "relation/1162502",
    "name": "Тасеевский район"
  },
  {
    "id": "relation/1162682",
    "name": "Кежемский район"
  },
  {
    "id": "relation/1162689",
    "name": "Богучанский район"
  },
  {
    "id": "relation/1164603",
    "name": "Северо-Енисейский район"
  },
  {
    "id": "relation/1164624",
    "name": "Туруханский район"
  },
  {
    "id": "relation/1164633",
    "name": "городской округ Енисейск"
  },
  {
    "id": "relation/1164668",
    "name": "Эвенкийский район"
  },
  {
    "id": "relation/1165290",
    "name": "ЗАТО Железногорск"
  },
  {
    "id": "relation/1165292",
    "name": "Таймырский Долгано-Ненецкий район"
  },
  {
    "id": "relation/1165299",
    "name": "ЗАТО Солнечный"
  },
  {
    "id": "relation/1209483",
    "name": "городской округ Шарыпово"
  },
  {
    "id": "relation/1241549",
    "name": "городской округ Минусинск"
  },
  {
    "id": "relation/1454742",
    "name": "городской округ Норильск"
  },
  {
    "id": "relation/1547051",
    "name": "ЗАТО Зеленогорск"
  },
  {
    "id": "relation/2886645",
    "name": "городской округ Назарово"
  },
  {
    "id": "relation/3369619",
    "name": "городской округ Кедровый"
  },
  {
    "id": "relation/3429521",
    "name": "городской округ Боготол"
  },
  {
    "id": "relation/3511818",
    "name": "городской округ Бородино"
  },
  {
    "id": "node/178951928"
  },
  {
    "id": "node/191780610"
  },
  {
    "id": "node/191890746"
  },
  {
    "id": "node/191912193"
  },
  {
    "id": "node/204815902"
  },
  {
    "id": "node/204816649"
  },
  {
    "id": "node/210305929"
  },
  {
    "id": "node/246669195"
  },
  {
    "id": "node/275803988"
  },
  {
    "id": "node/275804020"
  },
  {
    "id": "node/275804138"
  },
  {
    "id": "node/275804147"
  },
  {
    "id": "node/275804229"
  },
  {
    "id": "node/275805295"
  },
  {
    "id": "node/275805315"
  },
  {
    "id": "node/275806099"
  },
  {
    "id": "node/275807106"
  },
  {
    "id": "node/275807520"
  },
  {
    "id": "node/275808256"
  },
  {
    "id": "node/276092539"
  },
  {
    "id": "node/286923453"
  },
  {
    "id": "node/290781841"
  },
  {
    "id": "node/291558905"
  },
  {
    "id": "node/295902357"
  },
  {
    "id": "node/295939729"
  },
  {
    "id": "node/295952416"
  },
  {
    "id": "node/300659754"
  },
  {
    "id": "node/359635418"
  },
  {
    "id": "node/429840480"
  },
  {
    "id": "node/431010919"
  },
  {
    "id": "node/450139362"
  },
  {
    "id": "node/450854343"
  },
  {
    "id": "node/451604149"
  },
  {
    "id": "node/461602378"
  },
  {
    "id": "node/463166446"
  },
  {
    "id": "node/465238192"
  },
  {
    "id": "node/475538643"
  },
  {
    "id": "node/706301285"
  },
  {
    "id": "node/842218515"
  },
  {
    "id": "node/894554361"
  },
  {
    "id": "node/894581417"
  },
  {
    "id": "node/895299283"
  },
  {
    "id": "node/897563433"
  },
  {
    "id": "node/905196328"
  },
  {
    "id": "node/943663644"
  },
  {
    "id": "node/945163841"
  },
  {
    "id": "node/948003374"
  },
  {
    "id": "node/955875368"
  },
  {
    "id": "node/955889423"
  },
  {
    "id": "node/961492953"
  },
  {
    "id": "node/1348895093"
  },
  {
    "id": "node/1372682490"
  },
  {
    "id": "node/3331429078"
  },
  {
    "id": "node/8169359044"
  }
]
;