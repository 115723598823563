export const names = [
  {
    "id": "relation/1327492",
    "name": "городской округ Череповец"
  },
  {
    "id": "relation/1327493",
    "name": "Сокольский муниципальный округ"
  },
  {
    "id": "relation/1327494",
    "name": "Тарногский муниципальный округ"
  },
  {
    "id": "relation/1327495",
    "name": "Кирилловский муниципальный округ"
  },
  {
    "id": "relation/1327496",
    "name": "Бабаевский муниципальный округ"
  },
  {
    "id": "relation/1327497",
    "name": "Чагодощенский муниципальный округ"
  },
  {
    "id": "relation/1327498",
    "name": "Устюженский муниципальный округ"
  },
  {
    "id": "relation/1327499",
    "name": "Бабушкинский муниципальный округ"
  },
  {
    "id": "relation/1327500",
    "name": "Вытегорский район"
  },
  {
    "id": "relation/1327502",
    "name": "Междуреченский муниципальный округ"
  },
  {
    "id": "relation/1327503",
    "name": "Великоустюгский муниципальный округ"
  },
  {
    "id": "relation/1327504",
    "name": "Харовский муниципальный округ"
  },
  {
    "id": "relation/1327505",
    "name": "Сямженский муниципальный округ"
  },
  {
    "id": "relation/1327506",
    "name": "Никольский муниципальный округ"
  },
  {
    "id": "relation/1327507",
    "name": "Шекснинский район"
  },
  {
    "id": "relation/1327508",
    "name": "Грязовецкий муниципальный округ"
  },
  {
    "id": "relation/1327510",
    "name": "Вашкинский муниципальный округ"
  },
  {
    "id": "relation/1327511",
    "name": "Череповецкий район"
  },
  {
    "id": "relation/1327512",
    "name": "Кадуйский муниципальный округ"
  },
  {
    "id": "relation/1327513",
    "name": "Белозерский муниципальный округ"
  },
  {
    "id": "relation/1327514",
    "name": "Усть-Кубинский муниципальный округ"
  },
  {
    "id": "relation/1327515",
    "name": "Вожегодский муниципальный округ"
  },
  {
    "id": "relation/1327516",
    "name": "Верховажский муниципальный округ"
  },
  {
    "id": "relation/1327517",
    "name": "Нюксенский муниципальный округ"
  },
  {
    "id": "relation/1327518",
    "name": "Вологодский муниципальный округ"
  },
  {
    "id": "relation/1327519",
    "name": "Тотемский муниципальный округ"
  },
  {
    "id": "relation/1327520",
    "name": "Кичменгско-Городецкий муниципальный округ"
  },
  {
    "id": "relation/1614795",
    "name": "городской округ Вологда"
  },
  {
    "id": "node/26951330"
  },
  {
    "id": "node/188558082"
  },
  {
    "id": "node/246280229"
  },
  {
    "id": "node/246559783"
  },
  {
    "id": "node/246566425"
  },
  {
    "id": "node/248877229"
  },
  {
    "id": "node/249629979"
  },
  {
    "id": "node/251710853"
  },
  {
    "id": "node/254855683"
  },
  {
    "id": "node/254862887"
  },
  {
    "id": "node/276104425"
  },
  {
    "id": "node/330402488"
  },
  {
    "id": "node/335978507"
  },
  {
    "id": "node/335978687"
  },
  {
    "id": "node/335978750"
  },
  {
    "id": "node/417072768"
  },
  {
    "id": "node/487102792"
  },
  {
    "id": "node/576932612"
  },
  {
    "id": "node/904985374"
  },
  {
    "id": "node/907101663"
  },
  {
    "id": "node/1028723788"
  },
  {
    "id": "node/1028724063"
  },
  {
    "id": "node/1028724239"
  },
  {
    "id": "node/1045656817"
  },
  {
    "id": "node/1045662261"
  },
  {
    "id": "node/1045663790"
  }
]
;