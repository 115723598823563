export const names = [
  {
    "id": "relation/930932",
    "name": "городской округ Сухой Лог"
  },
  {
    "id": "relation/933808",
    "name": "городской округ Богданович"
  },
  {
    "id": "relation/1014759",
    "name": "Камышловский район"
  },
  {
    "id": "relation/1014778",
    "name": "Пышминский городской округ"
  },
  {
    "id": "relation/1014788",
    "name": "Талицкий городской округ"
  },
  {
    "id": "relation/1014789",
    "name": "Тугулымский городской округ"
  },
  {
    "id": "relation/1016575",
    "name": "Слободо-Туринский район"
  },
  {
    "id": "relation/1019865",
    "name": "Белоярский городской округ"
  },
  {
    "id": "relation/1046967",
    "name": "Каменский городской округ"
  },
  {
    "id": "relation/1047000",
    "name": "Байкаловский район"
  },
  {
    "id": "relation/1047028",
    "name": "городской округ Ирбитское"
  },
  {
    "id": "relation/1059715",
    "name": "Артёмовский городской округ"
  },
  {
    "id": "relation/1059796",
    "name": "Берёзовский городской округ"
  },
  {
    "id": "relation/1067529",
    "name": "Малышевский городской округ"
  },
  {
    "id": "relation/1067582",
    "name": "городской округ Алапаевск"
  },
  {
    "id": "relation/1067601",
    "name": "городской округ Алапаевское"
  },
  {
    "id": "relation/1074051",
    "name": "городской округ Нижняя Салда"
  },
  {
    "id": "relation/1074090",
    "name": "Верхнесалдинский городской округ"
  },
  {
    "id": "relation/1074591",
    "name": "Режевской городской округ"
  },
  {
    "id": "relation/1104258",
    "name": "городской округ Екатеринбург"
  },
  {
    "id": "relation/1110126",
    "name": "Туринский городской округ"
  },
  {
    "id": "relation/1110131",
    "name": "Тавдинский городской округ"
  },
  {
    "id": "relation/1110851",
    "name": "Серовский городской округ"
  },
  {
    "id": "relation/1110855",
    "name": "Полевской городской округ"
  },
  {
    "id": "relation/1111949",
    "name": "городской округ Краснотурьинск"
  },
  {
    "id": "relation/1112083",
    "name": "городской округ Красноуральск"
  },
  {
    "id": "relation/1112110",
    "name": "Таборинский район"
  },
  {
    "id": "relation/1113021",
    "name": "городской округ Первоуральск"
  },
  {
    "id": "relation/1120596",
    "name": "Верхотурский городской округ"
  },
  {
    "id": "relation/1120607",
    "name": "Сосьвинский городской округ"
  },
  {
    "id": "relation/1120630",
    "name": "Гаринский городской округ"
  },
  {
    "id": "relation/1124064",
    "name": "Новолялинский городской округ"
  },
  {
    "id": "relation/1124099",
    "name": "Качканарский городской округ"
  },
  {
    "id": "relation/1125463",
    "name": "Ачитский городской округ"
  },
  {
    "id": "relation/1240372",
    "name": "Артинский городской округ"
  },
  {
    "id": "relation/1240375",
    "name": "Красноуфимский округ"
  },
  {
    "id": "relation/1288740",
    "name": "городской округ Красноуфимск"
  },
  {
    "id": "relation/1288760",
    "name": "Камышловский городской округ"
  },
  {
    "id": "relation/1288919",
    "name": "городской округ Староуткинск"
  },
  {
    "id": "relation/1288920",
    "name": "Шалинский городской округ"
  },
  {
    "id": "relation/1289032",
    "name": "городской округ Ревда"
  },
  {
    "id": "relation/1289033",
    "name": "городской округ Дегтярск"
  },
  {
    "id": "relation/1289095",
    "name": "ЗАТО Свободный"
  },
  {
    "id": "relation/1291581",
    "name": "городской округ Верхнее Дуброво"
  },
  {
    "id": "relation/1291582",
    "name": "городской округ Заречный"
  },
  {
    "id": "relation/1291599",
    "name": "ЗАТО Уральский"
  },
  {
    "id": "relation/1297876",
    "name": "Нижнесергинский район"
  },
  {
    "id": "relation/1297877",
    "name": "Бисертский городской округ"
  },
  {
    "id": "relation/1297899",
    "name": "городской округ Верхняя Пышма"
  },
  {
    "id": "relation/1297900",
    "name": "городской округ Среднеуральск"
  },
  {
    "id": "relation/1298865",
    "name": "Сысертский городской округ"
  },
  {
    "id": "relation/1298866",
    "name": "Арамильский городской округ"
  },
  {
    "id": "relation/1298867",
    "name": "городской округ Каменск-Уральский"
  },
  {
    "id": "relation/1305624",
    "name": "Новоуральский городской округ"
  },
  {
    "id": "relation/1305625",
    "name": "Невьянский городской округ"
  },
  {
    "id": "relation/1305626",
    "name": "городской округ Верхний Тагил"
  },
  {
    "id": "relation/1305627",
    "name": "городской округ Верх-Нейвинский"
  },
  {
    "id": "relation/1317756",
    "name": "городской округ Нижний Тагил"
  },
  {
    "id": "relation/1328971",
    "name": "Горноуральский городской округ"
  },
  {
    "id": "relation/1328972",
    "name": "городской округ Верхняя Тура"
  },
  {
    "id": "relation/1328973",
    "name": "Кушвинский городской округ"
  },
  {
    "id": "relation/1328974",
    "name": "Кировградский городской округ"
  },
  {
    "id": "relation/1359253",
    "name": "Волчанский городской округ"
  },
  {
    "id": "relation/1359256",
    "name": "Североуральский городской округ"
  },
  {
    "id": "relation/1374013",
    "name": "городской округ Пелым"
  },
  {
    "id": "relation/1374176",
    "name": "городской округ Лесной"
  },
  {
    "id": "relation/1375628",
    "name": "Ивдельский городской округ"
  },
  {
    "id": "relation/1375632",
    "name": "городской округ Карпинск"
  },
  {
    "id": "relation/1375635",
    "name": "Нижнетуринский городской округ"
  },
  {
    "id": "relation/1468147",
    "name": "городской округ Махнёвское"
  },
  {
    "id": "relation/1643659",
    "name": "Асбестовский городской округ"
  },
  {
    "id": "relation/1643660",
    "name": "Рефтинский городской округ"
  },
  {
    "id": "relation/3360366",
    "name": "городской округ Ирбит"
  },
  {
    "id": "node/27503892"
  },
  {
    "id": "node/34506055"
  },
  {
    "id": "node/191843504"
  },
  {
    "id": "node/191871001"
  },
  {
    "id": "node/206131650"
  },
  {
    "id": "node/247704625"
  },
  {
    "id": "node/288119622"
  },
  {
    "id": "node/288126203"
  },
  {
    "id": "node/289146963"
  },
  {
    "id": "node/290611540"
  },
  {
    "id": "node/290884279"
  },
  {
    "id": "node/290884698"
  },
  {
    "id": "node/290885736"
  },
  {
    "id": "node/291942235"
  },
  {
    "id": "node/306457009"
  },
  {
    "id": "node/306457427"
  },
  {
    "id": "node/306458157"
  },
  {
    "id": "node/310352142"
  },
  {
    "id": "node/310353722"
  },
  {
    "id": "node/310353809"
  },
  {
    "id": "node/310353926"
  },
  {
    "id": "node/310354163"
  },
  {
    "id": "node/310354793"
  },
  {
    "id": "node/310877933"
  },
  {
    "id": "node/313659859"
  },
  {
    "id": "node/335978564"
  },
  {
    "id": "node/335978823"
  },
  {
    "id": "node/335978853"
  },
  {
    "id": "node/369993438"
  },
  {
    "id": "node/369993440"
  },
  {
    "id": "node/370000267"
  },
  {
    "id": "node/370000269"
  },
  {
    "id": "node/413318751"
  },
  {
    "id": "node/426306407"
  },
  {
    "id": "node/431589635"
  },
  {
    "id": "node/432829088"
  },
  {
    "id": "node/434467604"
  },
  {
    "id": "node/435390923"
  },
  {
    "id": "node/437365198"
  },
  {
    "id": "node/438260314"
  },
  {
    "id": "node/438263230"
  },
  {
    "id": "node/438264676"
  },
  {
    "id": "node/439191804"
  },
  {
    "id": "node/445599992"
  },
  {
    "id": "node/445601648"
  },
  {
    "id": "node/445609883"
  },
  {
    "id": "node/445677282"
  },
  {
    "id": "node/446086359"
  },
  {
    "id": "node/446086379"
  },
  {
    "id": "node/446086381"
  },
  {
    "id": "node/446086385"
  },
  {
    "id": "node/446086387"
  },
  {
    "id": "node/449717412"
  },
  {
    "id": "node/454887449"
  },
  {
    "id": "node/455229098"
  },
  {
    "id": "node/458804566"
  },
  {
    "id": "node/471302856"
  },
  {
    "id": "node/523846749"
  },
  {
    "id": "node/558093784"
  },
  {
    "id": "node/558102086"
  },
  {
    "id": "node/558112417"
  },
  {
    "id": "node/558128295"
  },
  {
    "id": "node/558160094"
  },
  {
    "id": "node/558194657"
  },
  {
    "id": "node/558450575"
  },
  {
    "id": "node/794147794"
  },
  {
    "id": "node/830339911"
  },
  {
    "id": "node/960137391"
  },
  {
    "id": "node/1415303531"
  },
  {
    "id": "node/1679769809"
  },
  {
    "id": "node/1851064990"
  },
  {
    "id": "node/8900097372"
  }
]
;