import {groupByName} from './groupByName';
import {RegionData} from '../screens/regionMap/store/types';
import {ChartData} from '../components/chart/Chart';

export const groupRegionByYear = ({regions}: { regions: RegionData[] }): ChartData[][] => {
    return regions ? groupByName(regions ?? []).map(i => i.items.flatMap(i => ({
        value: Number(i.Value.toFixed()),
        year: i.Year
    }))) : [];
};
