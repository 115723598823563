export const names = [
  {
    "id": "relation/897992",
    "name": "городской округ Тюмень"
  },
  {
    "id": "relation/1647391",
    "name": "Упоровский район"
  },
  {
    "id": "relation/1647392",
    "name": "Исетский район"
  },
  {
    "id": "relation/1660242",
    "name": "Тюменский район"
  },
  {
    "id": "relation/1660243",
    "name": "Ярковский район"
  },
  {
    "id": "relation/1660245",
    "name": "Нижнетавдинский район"
  },
  {
    "id": "relation/1665573",
    "name": "Тобольский район"
  },
  {
    "id": "relation/1672820",
    "name": "Ялуторовский район"
  },
  {
    "id": "relation/1686348",
    "name": "Армизонский район"
  },
  {
    "id": "relation/1690636",
    "name": "Абатский район"
  },
  {
    "id": "relation/1690637",
    "name": "Бердюжский район"
  },
  {
    "id": "relation/1690638",
    "name": "Викуловский район"
  },
  {
    "id": "relation/1690639",
    "name": "Ишимский район"
  },
  {
    "id": "relation/1690640",
    "name": "Казанский район"
  },
  {
    "id": "relation/1690641",
    "name": "Сладковский район"
  },
  {
    "id": "relation/1690642",
    "name": "Сорокинский район"
  },
  {
    "id": "relation/1690646",
    "name": "Аромашевский район"
  },
  {
    "id": "relation/1690647",
    "name": "Голышмановский городской округ"
  },
  {
    "id": "relation/1690648",
    "name": "Омутинский район"
  },
  {
    "id": "relation/1690649",
    "name": "Юргинский район"
  },
  {
    "id": "relation/1711398",
    "name": "Вагайский район"
  },
  {
    "id": "relation/1711399",
    "name": "Уватский район"
  },
  {
    "id": "relation/1711405",
    "name": "городской округ Ишим"
  },
  {
    "id": "relation/1711441",
    "name": "городской округ Тобольск"
  },
  {
    "id": "relation/1711462",
    "name": "городской округ Ялуторовск"
  },
  {
    "id": "relation/1711469",
    "name": "Заводоуковский городской округ"
  },
  {
    "id": "node/27505666"
  },
  {
    "id": "node/174842795"
  },
  {
    "id": "node/178108820"
  },
  {
    "id": "node/191914736"
  },
  {
    "id": "node/210122762"
  },
  {
    "id": "node/210133788"
  },
  {
    "id": "node/243991814"
  },
  {
    "id": "node/243991822"
  },
  {
    "id": "node/243993517"
  },
  {
    "id": "node/243993518"
  },
  {
    "id": "node/243993519"
  },
  {
    "id": "node/243995008"
  },
  {
    "id": "node/268739870"
  },
  {
    "id": "node/278907444"
  },
  {
    "id": "node/286953863"
  },
  {
    "id": "node/310963315"
  },
  {
    "id": "node/310969957"
  },
  {
    "id": "node/474368614"
  },
  {
    "id": "node/580682865"
  },
  {
    "id": "node/616721630"
  },
  {
    "id": "node/1028723750"
  },
  {
    "id": "node/2215651801"
  }
]
;