export const names = [
  {
    "id": "relation/171922",
    "name": "Сергиево-Посадский городской округ"
  },
  {
    "id": "relation/171929",
    "name": "Талдомский городской округ"
  },
  {
    "id": "relation/173406",
    "name": "городской округ Мытищи"
  },
  {
    "id": "relation/173763",
    "name": "Дмитровский городской округ"
  },
  {
    "id": "relation/178921",
    "name": "Волоколамский городской округ"
  },
  {
    "id": "relation/178922",
    "name": "Рузский городской округ"
  },
  {
    "id": "relation/179494",
    "name": "Пушкинский городской округ"
  },
  {
    "id": "relation/179588",
    "name": "городской округ Щёлково"
  },
  {
    "id": "relation/179629",
    "name": "Богородский городской округ"
  },
  {
    "id": "relation/179639",
    "name": "Павлово-Посадский городской округ"
  },
  {
    "id": "relation/179640",
    "name": "Орехово-Зуевский городской округ"
  },
  {
    "id": "relation/180416",
    "name": "городской округ Шатура"
  },
  {
    "id": "relation/180621",
    "name": "Раменский городской округ"
  },
  {
    "id": "relation/180838",
    "name": "городской округ Солнечногорск"
  },
  {
    "id": "relation/181165",
    "name": "городской округ Чехов"
  },
  {
    "id": "relation/181189",
    "name": "городской округ Подольск"
  },
  {
    "id": "relation/181290",
    "name": "Ленинский городской округ"
  },
  {
    "id": "relation/181345",
    "name": "городской округ Красногорск"
  },
  {
    "id": "relation/181346",
    "name": "Одинцовский городской округ"
  },
  {
    "id": "relation/181355",
    "name": "городской округ Краснознаменск"
  },
  {
    "id": "relation/181356",
    "name": "городской округ Коломна"
  },
  {
    "id": "relation/184001",
    "name": "городской округ Домодедово"
  },
  {
    "id": "relation/184002",
    "name": "городской округ Люберцы"
  },
  {
    "id": "relation/184071",
    "name": "городской округ Клин"
  },
  {
    "id": "relation/184072",
    "name": "городской округ Истра"
  },
  {
    "id": "relation/184073",
    "name": "городской округ Лотошино"
  },
  {
    "id": "relation/184074",
    "name": "городской округ Шаховская"
  },
  {
    "id": "relation/184075",
    "name": "Можайский городской округ"
  },
  {
    "id": "relation/184106",
    "name": "городской округ Реутов"
  },
  {
    "id": "relation/184108",
    "name": "городской округ Балашиха"
  },
  {
    "id": "relation/184621",
    "name": "городской округ Серпухов"
  },
  {
    "id": "relation/184664",
    "name": "городской округ Кашира"
  },
  {
    "id": "relation/184665",
    "name": "городской округ Зарайск"
  },
  {
    "id": "relation/184667",
    "name": "городской округ Луховицы"
  },
  {
    "id": "relation/184684",
    "name": "городской округ Егорьевск"
  },
  {
    "id": "relation/184744",
    "name": "Наро-Фоминский городской округ"
  },
  {
    "id": "relation/184745",
    "name": "городской округ Дубна"
  },
  {
    "id": "relation/184746",
    "name": "городской округ Лосино-Петровский"
  },
  {
    "id": "relation/184751",
    "name": "городской округ Молодёжный"
  },
  {
    "id": "relation/186462",
    "name": "городской округ Черноголовка"
  },
  {
    "id": "relation/186463",
    "name": "городской округ Ступино"
  },
  {
    "id": "relation/189473",
    "name": "городской округ Жуковский"
  },
  {
    "id": "relation/974560",
    "name": "городской округ Фрязино"
  },
  {
    "id": "relation/1026233",
    "name": "городской округ Воскресенск"
  },
  {
    "id": "relation/1026234",
    "name": "городской округ Серебряные Пруды"
  },
  {
    "id": "relation/1028434",
    "name": "городской округ Котельники"
  },
  {
    "id": "relation/1028612",
    "name": "городской округ Восход"
  },
  {
    "id": "relation/1644065",
    "name": "городской округ Власиха"
  },
  {
    "id": "relation/1703082",
    "name": "городской округ Долгопрудный"
  },
  {
    "id": "relation/1703084",
    "name": "городской округ Звёздный городок"
  },
  {
    "id": "relation/1703085",
    "name": "городской округ Бронницы"
  },
  {
    "id": "relation/1703086",
    "name": "городской округ Дзержинский"
  },
  {
    "id": "relation/1703090",
    "name": "городской округ Королёв"
  },
  {
    "id": "relation/1703091",
    "name": "городской округ Лобня"
  },
  {
    "id": "relation/1703092",
    "name": "городской округ Лыткарино"
  },
  {
    "id": "relation/1703094",
    "name": "городской округ Химки"
  },
  {
    "id": "relation/1703097",
    "name": "городской округ Электросталь"
  },
  {
    "id": "node/34389205"
  },
  {
    "id": "node/82452220"
  },
  {
    "id": "node/93384125"
  },
  {
    "id": "node/93384127"
  },
  {
    "id": "node/140594314"
  },
  {
    "id": "node/143561186"
  },
  {
    "id": "node/143564891"
  },
  {
    "id": "node/156159426"
  },
  {
    "id": "node/156167469"
  },
  {
    "id": "node/156167485"
  },
  {
    "id": "node/191849717"
  },
  {
    "id": "node/191858851"
  },
  {
    "id": "node/191867420"
  },
  {
    "id": "node/191871925"
  },
  {
    "id": "node/191880180"
  },
  {
    "id": "node/191885615"
  },
  {
    "id": "node/191892583"
  },
  {
    "id": "node/191913805"
  },
  {
    "id": "node/253016504"
  },
  {
    "id": "node/253338946"
  },
  {
    "id": "node/253438546"
  },
  {
    "id": "node/253438739"
  },
  {
    "id": "node/253732932"
  },
  {
    "id": "node/256361815"
  },
  {
    "id": "node/256361832"
  },
  {
    "id": "node/256361842"
  },
  {
    "id": "node/256361843"
  },
  {
    "id": "node/256361846"
  },
  {
    "id": "node/256361850"
  },
  {
    "id": "node/256361856"
  },
  {
    "id": "node/256361865"
  },
  {
    "id": "node/256361869"
  },
  {
    "id": "node/256361870"
  },
  {
    "id": "node/256361874"
  },
  {
    "id": "node/256361882"
  },
  {
    "id": "node/256361884"
  },
  {
    "id": "node/256361889"
  },
  {
    "id": "node/258828718"
  },
  {
    "id": "node/258834478"
  },
  {
    "id": "node/270420987"
  },
  {
    "id": "node/271231306"
  },
  {
    "id": "node/306545784"
  },
  {
    "id": "node/359535254"
  },
  {
    "id": "node/360001149"
  },
  {
    "id": "node/360001189"
  },
  {
    "id": "node/360001289"
  },
  {
    "id": "node/360001895"
  },
  {
    "id": "node/428048526"
  },
  {
    "id": "node/462089127"
  },
  {
    "id": "node/534803361"
  },
  {
    "id": "node/821630088"
  },
  {
    "id": "node/922694649"
  },
  {
    "id": "node/1344276769"
  },
  {
    "id": "node/1344276773"
  },
  {
    "id": "node/1684082165"
  },
  {
    "id": "node/2095029787"
  },
  {
    "id": "node/5307944274"
  },
  {
    "id": "node/4559181618"
  }
]
;