import React from 'react';

export const Close = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M0 11C0 4.92487 4.92487 0 11 0C17.0751 0 22 4.92487 22 11C22 17.0751 17.0751 22 11 22C4.92487 22 0 17.0751 0 11ZM11 2C6.02943 2 2 6.02943 2 11C2 15.9706 6.02943 20 11 20C15.9706 20 20 15.9706 20 11C20 6.02943 15.9706 2 11 2ZM7.46449 7.46444C7.85502 7.07392 8.48818 7.07392 8.87871 7.46444L11 9.58576L13.1213 7.46444C13.5119 7.07392 14.145 7.07392 14.5356 7.46444C14.9261 7.85497 14.9261 8.48813 14.5356 8.87866L12.4142 11L14.5356 13.1213C14.9261 13.5118 14.9261 14.145 14.5356 14.5355C14.145 14.926 13.5119 14.926 13.1213 14.5355L11 12.4142L8.87871 14.5355C8.48818 14.926 7.85502 14.926 7.46449 14.5355C7.07397 14.145 7.07397 13.5118 7.46449 13.1213L9.58581 11L7.46449 8.87866C7.07397 8.48813 7.07397 7.85497 7.46449 7.46444Z"
                  fill="black" fillOpacity="0.85"/>
        </svg>

    );
};
