export const names = [
  {
    "id": "relation/390099",
    "name": "Сосновоборский городской округ"
  },
  {
    "id": "relation/391133",
    "name": "Ломоносовский район"
  },
  {
    "id": "relation/393909",
    "name": "Кингисеппский район"
  },
  {
    "id": "relation/393977",
    "name": "Сланцевский район"
  },
  {
    "id": "relation/1116466",
    "name": "Кировский район"
  },
  {
    "id": "relation/1117608",
    "name": "Приозерский район"
  },
  {
    "id": "relation/1118633",
    "name": "Тихвинский район"
  },
  {
    "id": "relation/1118829",
    "name": "Подпорожский район"
  },
  {
    "id": "relation/1124135",
    "name": "Лодейнопольский район"
  },
  {
    "id": "relation/1127515",
    "name": "Бокситогорский район"
  },
  {
    "id": "relation/1127562",
    "name": "Киришский район"
  },
  {
    "id": "relation/1128773",
    "name": "Волховский район"
  },
  {
    "id": "relation/1128809",
    "name": "Тосненский район"
  },
  {
    "id": "relation/1130269",
    "name": "Гатчинский муниципальный округ"
  },
  {
    "id": "relation/1130439",
    "name": "Лужский район"
  },
  {
    "id": "relation/1145712",
    "name": "Волосовский район"
  },
  {
    "id": "relation/1162549",
    "name": "Всеволожский район"
  },
  {
    "id": "relation/1162550",
    "name": "Выборгский район"
  },
  {
    "id": "node/27023177"
  },
  {
    "id": "node/27025179"
  },
  {
    "id": "node/27446511"
  },
  {
    "id": "node/34392911"
  },
  {
    "id": "node/60079145"
  },
  {
    "id": "node/82545393"
  },
  {
    "id": "node/206092285"
  },
  {
    "id": "node/217317194"
  },
  {
    "id": "node/243068681"
  },
  {
    "id": "node/246678899"
  },
  {
    "id": "node/248517049"
  },
  {
    "id": "node/273215574"
  },
  {
    "id": "node/310912076"
  },
  {
    "id": "node/312404669"
  },
  {
    "id": "node/356482509"
  },
  {
    "id": "node/411691832"
  },
  {
    "id": "node/1009135181"
  },
  {
    "id": "node/1833198964"
  }
]
;