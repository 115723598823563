import styled from 'styled-components';

export const BoxMap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0 10px;
`;

export const BoxSelectSects = styled.div`
  display: flex;
  justify-content: center;
  max-width: 680px;
  width: 100%;
  position: relative;
`;

export const BoxSelectClose = styled.div`
  width: 30px;
  height: 30px;
  position: absolute;
  top: 0;
  right: -10px;

  &:hover {
    cursor: pointer;
  }
`;

export const BoxText = styled.p`
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  color: var(--text-color);
`;
