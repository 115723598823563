export const names = [
  {
    "id": "relation/960628",
    "name": "Бессоновский район"
  },
  {
    "id": "relation/960633",
    "name": "Мокшанский район"
  },
  {
    "id": "relation/960657",
    "name": "Иссинский район"
  },
  {
    "id": "relation/960672",
    "name": "Лунинский район"
  },
  {
    "id": "relation/961209",
    "name": "Нижнеломовский район"
  },
  {
    "id": "relation/961213",
    "name": "Наровчатский район"
  },
  {
    "id": "relation/961214",
    "name": "Спасский район"
  },
  {
    "id": "relation/961234",
    "name": "Вадинский район"
  },
  {
    "id": "relation/961236",
    "name": "Земетчинский район"
  },
  {
    "id": "relation/961242",
    "name": "Башмаковский район"
  },
  {
    "id": "relation/961246",
    "name": "Пачелмский район"
  },
  {
    "id": "relation/961250",
    "name": "Каменский район"
  },
  {
    "id": "relation/961255",
    "name": "Белинский район"
  },
  {
    "id": "relation/968914",
    "name": "Пензенский район"
  },
  {
    "id": "relation/968954",
    "name": "Тамалинский район"
  },
  {
    "id": "relation/968959",
    "name": "Бековский район"
  },
  {
    "id": "relation/968967",
    "name": "Сердобский район"
  },
  {
    "id": "relation/968979",
    "name": "Колышлейский район"
  },
  {
    "id": "relation/968981",
    "name": "Малосердобинский район"
  },
  {
    "id": "relation/969618",
    "name": "Никольский район"
  },
  {
    "id": "relation/969647",
    "name": "Городищенский район"
  },
  {
    "id": "relation/969661",
    "name": "Сосновоборский район"
  },
  {
    "id": "relation/969679",
    "name": "Кузнецкий район"
  },
  {
    "id": "relation/969712",
    "name": "Шемышейский район"
  },
  {
    "id": "relation/969729",
    "name": "Лопатинский район"
  },
  {
    "id": "relation/969735",
    "name": "Камешкирский район"
  },
  {
    "id": "relation/969739",
    "name": "Неверкинский район"
  },
  {
    "id": "relation/1846778",
    "name": "ЗАТО Заречный"
  },
  {
    "id": "relation/1846780",
    "name": "городской округ Кузнецк"
  },
  {
    "id": "relation/1846781",
    "name": "городской округ Пенза"
  },
  {
    "id": "node/27505663"
  },
  {
    "id": "node/288419752"
  },
  {
    "id": "node/316080804"
  },
  {
    "id": "node/317365509"
  },
  {
    "id": "node/317365952"
  },
  {
    "id": "node/336140265"
  },
  {
    "id": "node/336140289"
  },
  {
    "id": "node/336140293"
  },
  {
    "id": "node/336522195"
  },
  {
    "id": "node/336522229"
  },
  {
    "id": "node/336522249"
  },
  {
    "id": "node/336523406"
  },
  {
    "id": "node/336523464"
  },
  {
    "id": "node/336523490"
  },
  {
    "id": "node/336523625"
  },
  {
    "id": "node/336523650"
  },
  {
    "id": "node/336523727"
  },
  {
    "id": "node/336526085"
  },
  {
    "id": "node/336526102"
  },
  {
    "id": "node/336526204"
  },
  {
    "id": "node/336526404"
  },
  {
    "id": "node/336526440"
  },
  {
    "id": "node/336526467"
  },
  {
    "id": "node/446137074"
  },
  {
    "id": "node/485782420"
  },
  {
    "id": "node/485793517"
  },
  {
    "id": "node/485827196"
  },
  {
    "id": "node/614392957"
  },
  {
    "id": "node/1665036195"
  }
]
;