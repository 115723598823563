export const names = [
  {
    "id": "relation/189384",
    "name": "Мирнинский улус"
  },
  {
    "id": "relation/189388",
    "name": "Оленёкский улус"
  },
  {
    "id": "relation/189403",
    "name": "Анабарский национальный улус"
  },
  {
    "id": "relation/189766",
    "name": "Ленский улус"
  },
  {
    "id": "relation/189815",
    "name": "Олёкминский улус"
  },
  {
    "id": "relation/190087",
    "name": "Нерюнгринский улус"
  },
  {
    "id": "relation/190089",
    "name": "Алданский улус"
  },
  {
    "id": "relation/1399814",
    "name": "Сунтарский улус"
  },
  {
    "id": "relation/1399815",
    "name": "Оймяконский улус"
  },
  {
    "id": "relation/1399817",
    "name": "Хангаласский улус"
  },
  {
    "id": "relation/1399818",
    "name": "Верхневилюйский улус"
  },
  {
    "id": "relation/1399819",
    "name": "Среднеколымский улус"
  },
  {
    "id": "relation/1399820",
    "name": "Намский улус"
  },
  {
    "id": "relation/1399821",
    "name": "Вилюйский улус"
  },
  {
    "id": "relation/1399822",
    "name": "Жиганский национальный эвенкийский улус"
  },
  {
    "id": "relation/1399823",
    "name": "Мегино-Кангаласский улус"
  },
  {
    "id": "relation/1399824",
    "name": "Томпонский улус"
  },
  {
    "id": "relation/1399825",
    "name": "Верхоянский улус"
  },
  {
    "id": "relation/1399826",
    "name": "Нижнеколымский улус"
  },
  {
    "id": "relation/1399827",
    "name": "Усть-Алданский улус"
  },
  {
    "id": "relation/1399828",
    "name": "Усть-Майский улус"
  },
  {
    "id": "relation/1399829",
    "name": "Нюрбинский улус"
  },
  {
    "id": "relation/1399830",
    "name": "Верхнеколымский улус"
  },
  {
    "id": "relation/1399831",
    "name": "Абыйский улус"
  },
  {
    "id": "relation/1399832",
    "name": "Кобяйский улус"
  },
  {
    "id": "relation/1399833",
    "name": "Булунский улус"
  },
  {
    "id": "relation/1399834",
    "name": "Таттинский улус"
  },
  {
    "id": "relation/1399835",
    "name": "Усть-Янский улус"
  },
  {
    "id": "relation/1399836",
    "name": "Момский улус"
  },
  {
    "id": "relation/1399837",
    "name": "Амгинский улус"
  },
  {
    "id": "relation/1399838",
    "name": "Эвено-Бытантайский национальный улус"
  },
  {
    "id": "relation/1399839",
    "name": "Чурапчинский улус"
  },
  {
    "id": "relation/1399840",
    "name": "Аллаиховский улус"
  },
  {
    "id": "relation/1399841",
    "name": "Горный улус"
  },
  {
    "id": "relation/1444344",
    "name": "городской округ Якутск"
  },
  {
    "id": "relation/3506777",
    "name": "городской округ Жатай"
  },
  {
    "id": "node/191652335"
  },
  {
    "id": "node/280109123"
  },
  {
    "id": "node/280183184"
  },
  {
    "id": "node/282596576"
  },
  {
    "id": "node/286554085"
  },
  {
    "id": "node/301639437"
  },
  {
    "id": "node/301761027"
  },
  {
    "id": "node/301775279"
  },
  {
    "id": "node/301778109"
  },
  {
    "id": "node/301822109"
  },
  {
    "id": "node/301959493"
  },
  {
    "id": "node/302066783"
  },
  {
    "id": "node/302878865"
  },
  {
    "id": "node/304552309"
  },
  {
    "id": "node/335978670"
  },
  {
    "id": "node/335978691"
  },
  {
    "id": "node/413805673"
  },
  {
    "id": "node/436918452"
  },
  {
    "id": "node/440614116"
  },
  {
    "id": "node/442301557"
  },
  {
    "id": "node/444271257"
  },
  {
    "id": "node/447905020"
  },
  {
    "id": "node/457263234"
  },
  {
    "id": "node/624020909"
  },
  {
    "id": "node/1028723813"
  },
  {
    "id": "node/1028723871"
  },
  {
    "id": "node/1028724019"
  },
  {
    "id": "node/1028724199"
  },
  {
    "id": "node/1028724296"
  },
  {
    "id": "node/1049441889"
  },
  {
    "id": "node/1217884720"
  },
  {
    "id": "node/1625195915"
  },
  {
    "id": "node/2071063097"
  },
  {
    "id": "node/2077124058"
  },
  {
    "id": "node/2414002280"
  },
  {
    "id": "node/2958944836"
  }
]
;