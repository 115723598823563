export const names = [
  {
    "id": "relation/1077203",
    "name": "городской округ Алексин"
  },
  {
    "id": "relation/1453190",
    "name": "Чернский район"
  },
  {
    "id": "relation/1524022",
    "name": "Щёкинский район"
  },
  {
    "id": "relation/1741297",
    "name": "городской округ Новомосковск"
  },
  {
    "id": "relation/1748968",
    "name": "Арсеньевский район"
  },
  {
    "id": "relation/1748969",
    "name": "Белёвский район"
  },
  {
    "id": "relation/1748970",
    "name": "Богородицкий район"
  },
  {
    "id": "relation/1748971",
    "name": "Венёвский район"
  },
  {
    "id": "relation/1748972",
    "name": "Воловский район"
  },
  {
    "id": "relation/1748973",
    "name": "городской округ Донской"
  },
  {
    "id": "relation/1748974",
    "name": "Дубенский район"
  },
  {
    "id": "relation/1748975",
    "name": "городской округ Ефремов"
  },
  {
    "id": "relation/1748976",
    "name": "городской округ Славный"
  },
  {
    "id": "relation/1748977",
    "name": "Заокский район"
  },
  {
    "id": "relation/1748978",
    "name": "Каменский район"
  },
  {
    "id": "relation/1748979",
    "name": "Кимовский район"
  },
  {
    "id": "relation/1748980",
    "name": "Киреевский район"
  },
  {
    "id": "relation/1748981",
    "name": "Куркинский район"
  },
  {
    "id": "relation/1748983",
    "name": "городской округ Новогуровский"
  },
  {
    "id": "relation/1748985",
    "name": "Одоевский район"
  },
  {
    "id": "relation/1748986",
    "name": "Плавский район"
  },
  {
    "id": "relation/1748987",
    "name": "Суворовский район"
  },
  {
    "id": "relation/1748988",
    "name": "Тёпло-Огарёвский район"
  },
  {
    "id": "relation/1748990",
    "name": "Узловский район"
  },
  {
    "id": "relation/1748991",
    "name": "Ясногорский район"
  },
  {
    "id": "relation/4775559",
    "name": "городской округ Тула"
  },
  {
    "id": "node/34389350"
  },
  {
    "id": "node/256361872"
  },
  {
    "id": "node/259862730"
  },
  {
    "id": "node/332349574"
  },
  {
    "id": "node/332350145"
  },
  {
    "id": "node/336306280"
  },
  {
    "id": "node/336306293"
  },
  {
    "id": "node/336306323"
  },
  {
    "id": "node/336306335"
  },
  {
    "id": "node/336306337"
  },
  {
    "id": "node/336306378"
  },
  {
    "id": "node/336306424"
  },
  {
    "id": "node/336306458"
  },
  {
    "id": "node/336306468"
  },
  {
    "id": "node/336306480"
  },
  {
    "id": "node/336306490"
  },
  {
    "id": "node/336306541"
  },
  {
    "id": "node/336306635"
  },
  {
    "id": "node/355945765"
  },
  {
    "id": "node/529071567"
  },
  {
    "id": "node/544428039"
  },
  {
    "id": "node/1213895243"
  },
  {
    "id": "node/1493423723"
  },
  {
    "id": "node/1799268458"
  },
  {
    "id": "node/2717185789"
  },
  {
    "id": "node/4250183710"
  }
]
;