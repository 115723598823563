import {RegionData} from '../screens/regionMap/store/types';

export function groupByName(data: RegionData[]): { name: string; items: RegionData[] }[] {
    const groups: Record<string, RegionData[]> = {};

    data.forEach((item) => {
        const name = item.ObjName; // Предполагаем, что в объекте есть поле `year`
        if (!groups[name]) {
            groups[name] = [];
        }
        groups[name].push(item);
    });

    // Преобразуем объект групп в массив
    return Object.keys(groups).map((name) => ({
        name: name,
        items: groups[name],
    }));
}
