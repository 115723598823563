export const names = [
  {
    "id": "relation/1858712",
    "name": "Агульский район"
  },
  {
    "id": "relation/1858713",
    "name": "Акушинский район"
  },
  {
    "id": "relation/1858714",
    "name": "Ахвахский район"
  },
  {
    "id": "relation/1858715",
    "name": "Ахтынский район"
  },
  {
    "id": "relation/1858716",
    "name": "Бабаюртовский район"
  },
  {
    "id": "relation/1858717",
    "name": "Ботлихский район"
  },
  {
    "id": "relation/1858718",
    "name": "Буйнакский район"
  },
  {
    "id": "relation/1858719",
    "name": "Гергебильский район"
  },
  {
    "id": "relation/1858720",
    "name": "Гумбетовский район"
  },
  {
    "id": "relation/1858721",
    "name": "Гунибский район"
  },
  {
    "id": "relation/1858722",
    "name": "Дахадаевский район"
  },
  {
    "id": "relation/1858723",
    "name": "Дербентский район"
  },
  {
    "id": "relation/1858724",
    "name": "Докузпаринский район"
  },
  {
    "id": "relation/1858725",
    "name": "Казбековский район"
  },
  {
    "id": "relation/1858726",
    "name": "Кайтагский район"
  },
  {
    "id": "relation/1858727",
    "name": "Карабудахкентский район"
  },
  {
    "id": "relation/1858728",
    "name": "Каякентский район"
  },
  {
    "id": "relation/1858729",
    "name": "Кизилюртовский район"
  },
  {
    "id": "relation/1858730",
    "name": "Кизлярский район"
  },
  {
    "id": "relation/1858731",
    "name": "Кулинский район"
  },
  {
    "id": "relation/1858732",
    "name": "Кумторкалинский район"
  },
  {
    "id": "relation/1858733",
    "name": "Курахский район"
  },
  {
    "id": "relation/1858734",
    "name": "Лакский район"
  },
  {
    "id": "relation/1858735",
    "name": "Левашинский район"
  },
  {
    "id": "relation/1858749",
    "name": "Магарамкентский район"
  },
  {
    "id": "relation/1858750",
    "name": "Новолакский район"
  },
  {
    "id": "relation/1858751",
    "name": "Ногайский район"
  },
  {
    "id": "relation/1858752",
    "name": "Рутульский район"
  },
  {
    "id": "relation/1858753",
    "name": "Сергокалинский район"
  },
  {
    "id": "relation/1858754",
    "name": "Сулейман-Стальский район"
  },
  {
    "id": "relation/1858755",
    "name": "Табасаранский район"
  },
  {
    "id": "relation/1858756",
    "name": "Тарумовский район"
  },
  {
    "id": "relation/1858757",
    "name": "Тляратинский район"
  },
  {
    "id": "relation/1858758",
    "name": "Унцукульский район"
  },
  {
    "id": "relation/1858759",
    "name": "Хасавюртовский район"
  },
  {
    "id": "relation/1858760",
    "name": "Хивский район"
  },
  {
    "id": "relation/1858761",
    "name": "Хунзахский район"
  },
  {
    "id": "relation/1858762",
    "name": "Цумадинский район"
  },
  {
    "id": "relation/1858763",
    "name": "Цунтинский район"
  },
  {
    "id": "relation/1858764",
    "name": "Чародинский район"
  },
  {
    "id": "relation/1858765",
    "name": "Шамильский район"
  },
  {
    "id": "relation/1858766",
    "name": "городской округ Буйнакск"
  },
  {
    "id": "relation/1858767",
    "name": "городской округ Дагестанские Огни"
  },
  {
    "id": "relation/1858768",
    "name": "городской округ Дербент"
  },
  {
    "id": "relation/1858769",
    "name": "городской округ Избербаш"
  },
  {
    "id": "relation/1858770",
    "name": "городской округ Кизилюрт"
  },
  {
    "id": "relation/1858771",
    "name": "городской округ Кизляр"
  },
  {
    "id": "relation/1858773",
    "name": "городской округ Каспийск"
  },
  {
    "id": "relation/1858774",
    "name": "городской округ Хасавюрт"
  },
  {
    "id": "relation/1858775",
    "name": "городской округ Южно-Сухокумск"
  },
  {
    "id": "relation/1963798",
    "name": "городской округ Махачкала"
  },
  {
    "id": "node/191644503"
  },
  {
    "id": "node/191880979"
  },
  {
    "id": "node/191903740"
  },
  {
    "id": "node/287660963"
  },
  {
    "id": "node/287661153"
  },
  {
    "id": "node/287662481"
  },
  {
    "id": "node/287664289"
  },
  {
    "id": "node/287670803"
  },
  {
    "id": "node/287673747"
  },
  {
    "id": "node/287686736"
  },
  {
    "id": "node/287687179"
  },
  {
    "id": "node/679031958"
  },
  {
    "id": "node/825444068"
  },
  {
    "id": "node/892719055"
  },
  {
    "id": "node/892808214"
  },
  {
    "id": "node/898812522"
  },
  {
    "id": "node/898817951"
  },
  {
    "id": "node/899119183"
  },
  {
    "id": "node/899136752"
  },
  {
    "id": "node/900323082"
  },
  {
    "id": "node/900689532"
  },
  {
    "id": "node/900694358"
  },
  {
    "id": "node/900699751"
  },
  {
    "id": "node/900702305"
  },
  {
    "id": "node/900928222"
  },
  {
    "id": "node/900935959"
  },
  {
    "id": "node/902322589"
  },
  {
    "id": "node/903973187"
  },
  {
    "id": "node/904020615"
  },
  {
    "id": "node/904026153"
  },
  {
    "id": "node/904033469"
  },
  {
    "id": "node/904048044"
  },
  {
    "id": "node/913343870"
  },
  {
    "id": "node/913358321"
  },
  {
    "id": "node/913380726"
  },
  {
    "id": "node/915301872"
  },
  {
    "id": "node/915304822"
  },
  {
    "id": "node/915315156"
  },
  {
    "id": "node/915337806"
  },
  {
    "id": "node/915383442"
  },
  {
    "id": "node/915383466"
  },
  {
    "id": "node/915392873"
  },
  {
    "id": "node/915404881"
  },
  {
    "id": "node/915412150"
  },
  {
    "id": "node/4438373074"
  },
  {
    "id": "node/7226593477"
  }
]
;