export const names = [
  {
    "id": "relation/399255",
    "name": "Азовский район"
  },
  {
    "id": "relation/407876",
    "name": "городской округ Батайск"
  },
  {
    "id": "relation/537731",
    "name": "Аксайский район"
  },
  {
    "id": "relation/944524",
    "name": "городской округ Азов"
  },
  {
    "id": "relation/960764",
    "name": "городской округ Таганрог"
  },
  {
    "id": "relation/962081",
    "name": "Неклиновский район"
  },
  {
    "id": "relation/964245",
    "name": "Мясниковский район"
  },
  {
    "id": "relation/964255",
    "name": "городской округ Ростов-на-Дону"
  },
  {
    "id": "relation/966497",
    "name": "городской округ Волгодонск"
  },
  {
    "id": "relation/968430",
    "name": "Матвеево-Курганский район"
  },
  {
    "id": "relation/970737",
    "name": "Куйбышевский район"
  },
  {
    "id": "relation/971964",
    "name": "Родионово-Несветайский район"
  },
  {
    "id": "relation/973238",
    "name": "Кагальницкий район"
  },
  {
    "id": "relation/974538",
    "name": "Ремонтненский район"
  },
  {
    "id": "relation/975782",
    "name": "Заветинский район"
  },
  {
    "id": "relation/1000503",
    "name": "Песчанокопский район"
  },
  {
    "id": "relation/1060675",
    "name": "Багаевский район"
  },
  {
    "id": "relation/1060701",
    "name": "Белокалитвинский район"
  },
  {
    "id": "relation/1060737",
    "name": "Боковский район"
  },
  {
    "id": "relation/1060754",
    "name": "Верхнедонской район"
  },
  {
    "id": "relation/1060757",
    "name": "Весёловский район"
  },
  {
    "id": "relation/1281563",
    "name": "городской округ Новочеркасск"
  },
  {
    "id": "relation/1482450",
    "name": "городской округ Шахты"
  },
  {
    "id": "relation/1491892",
    "name": "городской округ Новошахтинск"
  },
  {
    "id": "relation/1501938",
    "name": "Октябрьский район"
  },
  {
    "id": "relation/1503701",
    "name": "городской округ Зверево"
  },
  {
    "id": "relation/1515377",
    "name": "городской округ Донецк"
  },
  {
    "id": "relation/1515475",
    "name": "городской округ Гуково"
  },
  {
    "id": "relation/1517228",
    "name": "Красносулинский район"
  },
  {
    "id": "relation/1517393",
    "name": "городской округ Каменск-Шахтинский"
  },
  {
    "id": "relation/1518808",
    "name": "Шолоховский район"
  },
  {
    "id": "relation/1521746",
    "name": "Каменский район"
  },
  {
    "id": "relation/1526041",
    "name": "Тарасовский район"
  },
  {
    "id": "relation/1528452",
    "name": "Миллеровский район"
  },
  {
    "id": "relation/1549274",
    "name": "Чертковский район"
  },
  {
    "id": "relation/1550748",
    "name": "Кашарский район"
  },
  {
    "id": "relation/1551292",
    "name": "Усть-Донецкий район"
  },
  {
    "id": "relation/1552931",
    "name": "Зерноградский район"
  },
  {
    "id": "relation/1553914",
    "name": "Егорлыкский район"
  },
  {
    "id": "relation/1554872",
    "name": "Целинский район"
  },
  {
    "id": "relation/1554967",
    "name": "Сальский район"
  },
  {
    "id": "relation/1559626",
    "name": "Волгодонской район"
  },
  {
    "id": "relation/1561754",
    "name": "Дубовский район"
  },
  {
    "id": "relation/1588570",
    "name": "Зимовниковский район"
  },
  {
    "id": "relation/1589348",
    "name": "Орловский район"
  },
  {
    "id": "relation/1589840",
    "name": "Пролетарский район"
  },
  {
    "id": "relation/1590261",
    "name": "Мартыновский район"
  },
  {
    "id": "relation/1590700",
    "name": "Семикаракорский район"
  },
  {
    "id": "relation/1599759",
    "name": "Константиновский район"
  },
  {
    "id": "relation/1600453",
    "name": "Цимлянский район"
  },
  {
    "id": "relation/1606277",
    "name": "Тацинский район"
  },
  {
    "id": "relation/1606910",
    "name": "Морозовский район"
  },
  {
    "id": "relation/1607220",
    "name": "Милютинский район"
  },
  {
    "id": "relation/1607332",
    "name": "Советский район"
  },
  {
    "id": "relation/1607368",
    "name": "Обливский район"
  },
  {
    "id": "node/29980667"
  },
  {
    "id": "node/160569651"
  },
  {
    "id": "node/191771467"
  },
  {
    "id": "node/191846117"
  },
  {
    "id": "node/191851580"
  },
  {
    "id": "node/191889906"
  },
  {
    "id": "node/283030477"
  },
  {
    "id": "node/287039558"
  },
  {
    "id": "node/287040070"
  },
  {
    "id": "node/332370326"
  },
  {
    "id": "node/332371008"
  },
  {
    "id": "node/332371503"
  },
  {
    "id": "node/332371954"
  },
  {
    "id": "node/339961651"
  },
  {
    "id": "node/339980655"
  },
  {
    "id": "node/339986600"
  },
  {
    "id": "node/346915853"
  },
  {
    "id": "node/356772271"
  },
  {
    "id": "node/356772503"
  },
  {
    "id": "node/356772506"
  },
  {
    "id": "node/356772521"
  },
  {
    "id": "node/356772546"
  },
  {
    "id": "node/356772614"
  },
  {
    "id": "node/356772629"
  },
  {
    "id": "node/356772687"
  },
  {
    "id": "node/356772744"
  },
  {
    "id": "node/356772759"
  },
  {
    "id": "node/356772865"
  },
  {
    "id": "node/356772879"
  },
  {
    "id": "node/356772892"
  },
  {
    "id": "node/356772903"
  },
  {
    "id": "node/356772932"
  },
  {
    "id": "node/356772933"
  },
  {
    "id": "node/356772948"
  },
  {
    "id": "node/356772951"
  },
  {
    "id": "node/356773000"
  },
  {
    "id": "node/356773052"
  },
  {
    "id": "node/356773092"
  },
  {
    "id": "node/356773128"
  },
  {
    "id": "node/356773133"
  },
  {
    "id": "node/356773139"
  },
  {
    "id": "node/356773216"
  },
  {
    "id": "node/356773218"
  },
  {
    "id": "node/356773219"
  },
  {
    "id": "node/356773231"
  },
  {
    "id": "node/654179991"
  },
  {
    "id": "node/806434175"
  },
  {
    "id": "node/1026627263"
  },
  {
    "id": "node/1026649837"
  },
  {
    "id": "node/1216129223"
  },
  {
    "id": "node/1224164975"
  },
  {
    "id": "node/1403886680"
  },
  {
    "id": "node/1826787139"
  },
  {
    "id": "node/2902998396"
  }
]
;