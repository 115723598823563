export const names = [
  {
    "id": "relation/1161438",
    "name": "Новодугинский район"
  },
  {
    "id": "relation/1161439",
    "name": "Сычёвский район"
  },
  {
    "id": "relation/1242485",
    "name": "Рославльский район"
  },
  {
    "id": "relation/1242486",
    "name": "Ершичский район"
  },
  {
    "id": "relation/1619448",
    "name": "городской округ Десногорск"
  },
  {
    "id": "relation/1674536",
    "name": "городской округ Смоленск"
  },
  {
    "id": "relation/1694037",
    "name": "Вяземский район"
  },
  {
    "id": "relation/1694038",
    "name": "Гагаринский район"
  },
  {
    "id": "relation/1694039",
    "name": "Глинковский район"
  },
  {
    "id": "relation/1694040",
    "name": "Демидовский район"
  },
  {
    "id": "relation/1694041",
    "name": "Дорогобужский район"
  },
  {
    "id": "relation/1694043",
    "name": "Ельнинский район"
  },
  {
    "id": "relation/1694044",
    "name": "Кардымовский район"
  },
  {
    "id": "relation/1694047",
    "name": "Починковский район"
  },
  {
    "id": "relation/1694049",
    "name": "Сафоновский район"
  },
  {
    "id": "relation/1694050",
    "name": "Смоленский район"
  },
  {
    "id": "relation/1694051",
    "name": "Тёмкинский район"
  },
  {
    "id": "relation/1694052",
    "name": "Угранский район"
  },
  {
    "id": "relation/1694053",
    "name": "Хиславичский район"
  },
  {
    "id": "relation/1694054",
    "name": "Холм-Жирковский район"
  },
  {
    "id": "relation/1694055",
    "name": "Шумячский район"
  },
  {
    "id": "relation/1694056",
    "name": "Ярцевский район"
  },
  {
    "id": "relation/3341976",
    "name": "Духовщинский район"
  },
  {
    "id": "relation/3342303",
    "name": "Велижский район"
  },
  {
    "id": "relation/3343863",
    "name": "Руднянский район"
  },
  {
    "id": "relation/3344404",
    "name": "Краснинский район"
  },
  {
    "id": "relation/3345860",
    "name": "Монастырщинский район"
  },
  {
    "id": "node/27072633"
  },
  {
    "id": "node/60078445"
  },
  {
    "id": "node/60699747"
  },
  {
    "id": "node/94075151"
  },
  {
    "id": "node/173833660"
  },
  {
    "id": "node/173835805"
  },
  {
    "id": "node/262522404"
  },
  {
    "id": "node/276588782"
  },
  {
    "id": "node/312992742"
  },
  {
    "id": "node/336306078"
  },
  {
    "id": "node/336306145"
  },
  {
    "id": "node/336306159"
  },
  {
    "id": "node/336306167"
  },
  {
    "id": "node/336306191"
  },
  {
    "id": "node/336306193"
  },
  {
    "id": "node/336306203"
  },
  {
    "id": "node/336306252"
  },
  {
    "id": "node/336306289"
  },
  {
    "id": "node/336306405"
  },
  {
    "id": "node/356483039"
  },
  {
    "id": "node/393764498"
  },
  {
    "id": "node/500151213"
  },
  {
    "id": "node/682379577"
  },
  {
    "id": "node/703370872"
  },
  {
    "id": "node/1352189181"
  },
  {
    "id": "node/1921466406"
  }
]
;