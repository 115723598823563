export const names = [
  {
    "id": "relation/912501",
    "name": "городской округ Переславль-Залесский"
  },
  {
    "id": "relation/912503",
    "name": "Ростовский район"
  },
  {
    "id": "relation/942455",
    "name": "Ярославский район"
  },
  {
    "id": "relation/944598",
    "name": "Борисоглебский район"
  },
  {
    "id": "relation/959859",
    "name": "Угличский район"
  },
  {
    "id": "relation/1188369",
    "name": "Любимский район"
  },
  {
    "id": "relation/1203359",
    "name": "Гаврилов-Ямский район"
  },
  {
    "id": "relation/1203372",
    "name": "Некрасовский район"
  },
  {
    "id": "relation/1277971",
    "name": "Даниловский район"
  },
  {
    "id": "relation/1375570",
    "name": "Первомайский район"
  },
  {
    "id": "relation/1431914",
    "name": "городской округ Рыбинск"
  },
  {
    "id": "relation/1432258",
    "name": "Рыбинский район"
  },
  {
    "id": "relation/1549144",
    "name": "Пошехонский район"
  },
  {
    "id": "relation/1549157",
    "name": "Тутаевский район"
  },
  {
    "id": "relation/1553748",
    "name": "Большесельский район"
  },
  {
    "id": "relation/1561591",
    "name": "Брейтовский район"
  },
  {
    "id": "relation/1561779",
    "name": "Некоузский район"
  },
  {
    "id": "relation/1562650",
    "name": "Мышкинский район"
  },
  {
    "id": "relation/1701436",
    "name": "городской округ Ярославль"
  },
  {
    "id": "node/27464354"
  },
  {
    "id": "node/27464486"
  },
  {
    "id": "node/27505266"
  },
  {
    "id": "node/92383389"
  },
  {
    "id": "node/92441557"
  },
  {
    "id": "node/92774502"
  },
  {
    "id": "node/148899210"
  },
  {
    "id": "node/148899607"
  },
  {
    "id": "node/276646949"
  },
  {
    "id": "node/278789264"
  },
  {
    "id": "node/356482546"
  },
  {
    "id": "node/356482599"
  },
  {
    "id": "node/356482725"
  },
  {
    "id": "node/385350289"
  },
  {
    "id": "node/561074536"
  },
  {
    "id": "node/924463542"
  },
  {
    "id": "node/1357158658"
  }
]
;