export const names = [
  {
    "id": "relation/1111195",
    "name": "Дивеевский муниципальный округ"
  },
  {
    "id": "relation/1537172",
    "name": "Теньгушевский район"
  },
  {
    "id": "relation/1537468",
    "name": "Зубово-Полянский район"
  },
  {
    "id": "relation/1537474",
    "name": "Торбеевский район"
  },
  {
    "id": "relation/1537485",
    "name": "Атюрьевский район"
  },
  {
    "id": "relation/1537570",
    "name": "Ковылкинский район"
  },
  {
    "id": "relation/1537974",
    "name": "Кочкуровский район"
  },
  {
    "id": "relation/1537975",
    "name": "Рузаевский район"
  },
  {
    "id": "relation/1537977",
    "name": "Инсарский район"
  },
  {
    "id": "relation/1537978",
    "name": "Кадошкинский район"
  },
  {
    "id": "relation/1537985",
    "name": "городской округ Саранск"
  },
  {
    "id": "relation/1537996",
    "name": "Большеберезниковский район"
  },
  {
    "id": "relation/1538010",
    "name": "Дубёнский район"
  },
  {
    "id": "relation/1538030",
    "name": "Чамзинский район"
  },
  {
    "id": "relation/1538051",
    "name": "Темниковский район"
  },
  {
    "id": "relation/1538158",
    "name": "Ельниковский район"
  },
  {
    "id": "relation/1538198",
    "name": "Краснослободский район"
  },
  {
    "id": "relation/1538212",
    "name": "Старошайговский район"
  },
  {
    "id": "relation/1538218",
    "name": "Лямбирский район"
  },
  {
    "id": "relation/1538225",
    "name": "Ромодановский район"
  },
  {
    "id": "relation/1538226",
    "name": "Ичалковский район"
  },
  {
    "id": "relation/1538235",
    "name": "Атяшевский район"
  },
  {
    "id": "relation/1538259",
    "name": "Большеигнатовский район"
  },
  {
    "id": "relation/1538280",
    "name": "Ардатовский район"
  },
  {
    "id": "relation/2031968",
    "name": "городской округ Саров"
  },
  {
    "id": "node/191651581"
  },
  {
    "id": "node/288418650"
  },
  {
    "id": "node/292879578"
  },
  {
    "id": "node/316226231"
  },
  {
    "id": "node/336521599"
  },
  {
    "id": "node/336521656"
  },
  {
    "id": "node/336521799"
  },
  {
    "id": "node/336521806"
  },
  {
    "id": "node/336522062"
  },
  {
    "id": "node/336522107"
  },
  {
    "id": "node/336522134"
  },
  {
    "id": "node/336524920"
  },
  {
    "id": "node/336525015"
  },
  {
    "id": "node/336525378"
  },
  {
    "id": "node/336525399"
  },
  {
    "id": "node/336525506"
  },
  {
    "id": "node/336525565"
  },
  {
    "id": "node/336525771"
  },
  {
    "id": "node/1028724108"
  },
  {
    "id": "node/1028724313"
  },
  {
    "id": "node/1031307883"
  },
  {
    "id": "node/1031307953"
  },
  {
    "id": "node/1160184877"
  },
  {
    "id": "node/1520151475"
  },
  {
    "id": "node/3057548478"
  }
]
;