export const names = [
  {
    "id": "relation/1643987",
    "name": "Анучинский муниципальный округ"
  },
  {
    "id": "relation/1644088",
    "name": "Черниговский муниципальный округ"
  },
  {
    "id": "relation/1644091",
    "name": "Михайловский район"
  },
  {
    "id": "relation/1644096",
    "name": "Хорольский муниципальный округ"
  },
  {
    "id": "relation/1644100",
    "name": "Октябрьский муниципальный округ"
  },
  {
    "id": "relation/1644127",
    "name": "Пограничный муниципальный округ"
  },
  {
    "id": "relation/1644129",
    "name": "Ханкайский муниципальный округ"
  },
  {
    "id": "relation/1644131",
    "name": "Спасский район"
  },
  {
    "id": "relation/1644137",
    "name": "Кировский район"
  },
  {
    "id": "relation/1644955",
    "name": "Лесозаводский городской округ"
  },
  {
    "id": "relation/1645042",
    "name": "Дальнереченский район"
  },
  {
    "id": "relation/1645097",
    "name": "Арсеньевский городской округ"
  },
  {
    "id": "relation/1645107",
    "name": "Яковлевский муниципальный округ"
  },
  {
    "id": "relation/1645836",
    "name": "Чугуевский муниципальный округ"
  },
  {
    "id": "relation/1645854",
    "name": "Кавалеровский муниципальный округ"
  },
  {
    "id": "relation/1645855",
    "name": "Красноармейский муниципальный округ"
  },
  {
    "id": "relation/1645869",
    "name": "Лазовский муниципальный округ"
  },
  {
    "id": "relation/1645870",
    "name": "Ольгинский муниципальный округ"
  },
  {
    "id": "relation/1645917",
    "name": "Партизанский муниципальный округ"
  },
  {
    "id": "relation/1645919",
    "name": "Пожарский муниципальный округ"
  },
  {
    "id": "relation/1645940",
    "name": "Тернейский муниципальный округ"
  },
  {
    "id": "relation/1645984",
    "name": "Надеждинский район"
  },
  {
    "id": "relation/1645985",
    "name": "Хасанский муниципальный округ"
  },
  {
    "id": "relation/1646004",
    "name": "Уссурийский городской округ"
  },
  {
    "id": "relation/1646005",
    "name": "Шкотовский муниципальный округ"
  },
  {
    "id": "relation/1647077",
    "name": "Артёмовский городской округ"
  },
  {
    "id": "relation/1647169",
    "name": "Дальнереченский городской округ"
  },
  {
    "id": "relation/1647394",
    "name": "Владивостокский городской округ"
  },
  {
    "id": "relation/1647407",
    "name": "Дальнегорский городской округ"
  },
  {
    "id": "relation/1647413",
    "name": "Партизанский городской округ"
  },
  {
    "id": "relation/1723640",
    "name": "городской округ Спасск-Дальний"
  },
  {
    "id": "relation/1814986",
    "name": "городской округ Большой Камень"
  },
  {
    "id": "relation/1820446",
    "name": "ЗАТО Фокино"
  },
  {
    "id": "relation/1836793",
    "name": "Находкинский городской округ"
  },
  {
    "id": "node/27503886"
  },
  {
    "id": "node/191850710"
  },
  {
    "id": "node/191864581"
  },
  {
    "id": "node/191911296"
  },
  {
    "id": "node/277007085"
  },
  {
    "id": "node/277057968"
  },
  {
    "id": "node/412248593"
  },
  {
    "id": "node/467758650"
  },
  {
    "id": "node/469152074"
  },
  {
    "id": "node/472352522"
  },
  {
    "id": "node/472574443"
  },
  {
    "id": "node/472597489"
  },
  {
    "id": "node/494081798"
  },
  {
    "id": "node/695107367"
  },
  {
    "id": "node/775688857"
  },
  {
    "id": "node/783692798"
  },
  {
    "id": "node/821038874"
  },
  {
    "id": "node/967688763"
  },
  {
    "id": "node/978627022"
  },
  {
    "id": "node/1028723881"
  },
  {
    "id": "node/1028724150"
  },
  {
    "id": "node/1028724304"
  },
  {
    "id": "node/1055662126"
  },
  {
    "id": "node/1072066946"
  },
  {
    "id": "node/1076354382"
  },
  {
    "id": "node/1076441937"
  },
  {
    "id": "node/1076537444"
  },
  {
    "id": "node/1078579692"
  },
  {
    "id": "node/1080083182"
  },
  {
    "id": "node/1215862324"
  },
  {
    "id": "node/1345473951"
  },
  {
    "id": "node/1463882415"
  }
]
;