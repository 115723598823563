export const names = [
  {
    "id": "relation/177604",
    "name": "Новгородский район"
  },
  {
    "id": "relation/177605",
    "name": "Батецкий район"
  },
  {
    "id": "relation/177606",
    "name": "Солецкий муниципальный округ"
  },
  {
    "id": "relation/177608",
    "name": "Шимский район"
  },
  {
    "id": "relation/177609",
    "name": "Волотовский муниципальный округ"
  },
  {
    "id": "relation/177610",
    "name": "Старорусский район"
  },
  {
    "id": "relation/177611",
    "name": "Поддорский район"
  },
  {
    "id": "relation/177612",
    "name": "Холмский район"
  },
  {
    "id": "relation/177620",
    "name": "Марёвский муниципальный округ"
  },
  {
    "id": "relation/177624",
    "name": "Парфинский район"
  },
  {
    "id": "relation/177626",
    "name": "Маловишерский район"
  },
  {
    "id": "relation/177627",
    "name": "Крестецкий муниципальный округ"
  },
  {
    "id": "relation/177628",
    "name": "Чудовский район"
  },
  {
    "id": "relation/178002",
    "name": "Любытинский район"
  },
  {
    "id": "relation/178003",
    "name": "Окуловский район"
  },
  {
    "id": "relation/178004",
    "name": "Боровичский район"
  },
  {
    "id": "relation/178006",
    "name": "Валдайский район"
  },
  {
    "id": "relation/178007",
    "name": "Демянский муниципальный округ"
  },
  {
    "id": "relation/178022",
    "name": "Хвойнинский муниципальный округ"
  },
  {
    "id": "relation/178030",
    "name": "Пестовский муниципальный округ"
  },
  {
    "id": "relation/178031",
    "name": "Мошенской муниципальный округ"
  },
  {
    "id": "relation/2417529",
    "name": "городской округ Великий Новгород"
  },
  {
    "id": "node/27017589"
  },
  {
    "id": "node/27492465"
  },
  {
    "id": "node/248560493"
  },
  {
    "id": "node/248561620"
  },
  {
    "id": "node/250133468"
  },
  {
    "id": "node/276637835"
  },
  {
    "id": "node/282462417"
  },
  {
    "id": "node/282467443"
  },
  {
    "id": "node/282624702"
  },
  {
    "id": "node/282635858"
  },
  {
    "id": "node/282636449"
  },
  {
    "id": "node/282656834"
  },
  {
    "id": "node/282668287"
  },
  {
    "id": "node/282671987"
  },
  {
    "id": "node/287260036"
  },
  {
    "id": "node/411447188"
  },
  {
    "id": "node/411447217"
  },
  {
    "id": "node/2259729877"
  },
  {
    "id": "node/2261998876"
  },
  {
    "id": "node/2291707774"
  },
  {
    "id": "node/2363198292"
  }
]
;