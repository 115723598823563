export const names = [
      {
        'id': 'relation/1711487',
        'name': 'Константиновский район'
      },
      {
        'id': 'relation/1712124',
        'name': 'Тамбовский муниципальный округ'
      },
      {
        'id': 'relation/1714060',
        'name': 'Архаринский муниципальный округ'
      },
      {
        'id': 'relation/1714061',
        'name': 'Бурейский муниципальный округ'
      },
      {
        'id': 'relation/1714062',
        'name': 'Михайловский район'
      },
      {
        'id': 'relation/1736631',
        'name': 'Завитинский муниципальный округ'
      },
      {
        'id': 'relation/1736632',
        'name': 'Октябрьский район'
      },
      {
        'id': 'relation/1736633',
        'name': 'городской округ Райчихинск'
      },
      {
        'id': 'relation/1742201',
        'name': 'Белогорский муниципальный округ'
      },
      {
        'id': 'relation/1742202',
        'name': 'Ивановский муниципальный округ'
      },
      {
        'id': 'relation/1742203',
        'name': 'Ромненский муниципальный округ'
      },
      {
        'id': 'relation/1742215',
        'name': 'городской округ Белогорск'
      },
      {
        'id': 'relation/1742216',
        'name': 'Серышевский муниципальный округ'
      },
      {
        'id': 'relation/1744411',
        'name': 'Мазановский район'
      },
      {
        'id': 'relation/1744412',
        'name': 'Селемджинский район'
      },
      {
        'id': 'relation/1745510',
        'name': 'городской округ Тында'
      },
      {
        'id': 'relation/1748664',
        'name': 'Свободненский район'
      },
      {
        'id': 'relation/1748665',
        'name': 'городской округ Свободный'
      },
      {
        'id': 'relation/1748666',
        'name': 'ЗАТО Циолковский'
      },
      {
        'id': 'relation/1748667',
        'name': 'городской округ Шимановск'
      },
      {
        'id': 'relation/1748668',
        'name': 'Шимановский муниципальный округ'
      },
      {
        'id': 'relation/1748844',
        'name': 'городской округ Зея'
      },
      {
        'id': 'relation/1748894',
        'name': 'Зейский муниципальный округ'
      },
      {
        'id': 'relation/1748895',
        'name': 'Тындинский муниципальный округ'
      },
      {
        'id': 'relation/1749238',
        'name': 'Магдагачинский район'
      },
      {
        'id': 'relation/1749239',
        'name': 'городской округ Прогресс'
      },
      {
        'id': 'relation/1749240',
        'name': 'Сковородинский муниципальный округ'
      },
      {
        'id': 'relation/1749246',
        'name': 'Благовещенский муниципальный округ'
      },
      {
        'id': 'relation/1749247',
        'name': 'городской округ Благовещенск'
      }
    ]
;
