export const names = [
  {
    "id": "relation/1760228",
    "name": "Базарносызганский район"
  },
  {
    "id": "relation/1760229",
    "name": "Барышский район"
  },
  {
    "id": "relation/1760230",
    "name": "Вешкаймский район"
  },
  {
    "id": "relation/1760231",
    "name": "Инзенский район"
  },
  {
    "id": "relation/1760232",
    "name": "Карсунский район"
  },
  {
    "id": "relation/1760233",
    "name": "Кузоватовский район"
  },
  {
    "id": "relation/1760234",
    "name": "Майнский район"
  },
  {
    "id": "relation/1760235",
    "name": "Мелекесский район"
  },
  {
    "id": "relation/1760236",
    "name": "Николаевский район"
  },
  {
    "id": "relation/1760237",
    "name": "Новомалыклинский район"
  },
  {
    "id": "relation/1760238",
    "name": "Новоспасский район"
  },
  {
    "id": "relation/1760239",
    "name": "Павловский район"
  },
  {
    "id": "relation/1760240",
    "name": "Радищевский район"
  },
  {
    "id": "relation/1760241",
    "name": "Сенгилеевский район"
  },
  {
    "id": "relation/1760242",
    "name": "Старокулаткинский район"
  },
  {
    "id": "relation/1760243",
    "name": "Старомайнский район"
  },
  {
    "id": "relation/1760244",
    "name": "Сурский район"
  },
  {
    "id": "relation/1760245",
    "name": "Тереньгульский район"
  },
  {
    "id": "relation/1760246",
    "name": "Ульяновский район"
  },
  {
    "id": "relation/1760247",
    "name": "Цильнинский район"
  },
  {
    "id": "relation/1760248",
    "name": "Чердаклинский район"
  },
  {
    "id": "relation/1760250",
    "name": "городской округ Новоульяновск"
  },
  {
    "id": "relation/1760251",
    "name": "городской округ Ульяновск"
  },
  {
    "id": "relation/2621608",
    "name": "городской округ Димитровград"
  },
  {
    "id": "node/191878451"
  },
  {
    "id": "node/313570754"
  },
  {
    "id": "node/336525345"
  },
  {
    "id": "node/336525405"
  },
  {
    "id": "node/336525429"
  },
  {
    "id": "node/336525476"
  },
  {
    "id": "node/336525530"
  },
  {
    "id": "node/336525537"
  },
  {
    "id": "node/336525705"
  },
  {
    "id": "node/336525781"
  },
  {
    "id": "node/336525886"
  },
  {
    "id": "node/336526121"
  },
  {
    "id": "node/336526221"
  },
  {
    "id": "node/336526368"
  },
  {
    "id": "node/336526506"
  },
  {
    "id": "node/341304823"
  },
  {
    "id": "node/344967812"
  },
  {
    "id": "node/344967818"
  },
  {
    "id": "node/711715624"
  },
  {
    "id": "node/757436886"
  },
  {
    "id": "node/1028723760"
  },
  {
    "id": "node/1028723919"
  },
  {
    "id": "node/1028724046"
  }
]
;