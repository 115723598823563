import React, {ReactNode} from 'react';
import styled from 'styled-components';
import {TextS} from './Text';
import {useCollapse} from 'react-collapsed';
import {Down} from '../assets/icons';

type CollapseProps = {
    buttonText: string;
    onClick: () => void;
    fontSize: number;
    isInner: boolean;
    border: boolean;
    open?: boolean;
    showIcon: boolean;
    children: ReactNode;
    renderTitle?: ReactNode;
}

export const Collapse = (
    {
        buttonText,
        isInner,
        border,
        showIcon,
        open,
        onClick,
        fontSize,
        renderTitle,
        children
    }: CollapseProps) => {
    const {setExpanded, isExpanded} = useCollapse();

    return (
        <CollapseWrapper border={border}>
            <SidebarCollapseS
                isInner={isInner}
                onClick={() => {
                    setExpanded(!isExpanded);
                    onClick();
                }}

            >
                {renderTitle ? renderTitle : <TextS
                    as={'span'}
                    fontSize={fontSize}
                    fontWeight={400}
                    color={'#000000'}
                    width={showIcon ? '90%' : '100%'}
                >
                    {buttonText}</TextS>}
                {showIcon ? <Down rotate={isExpanded}/> : null}
            </SidebarCollapseS>
            {open !== undefined
                ? open ? <SidebarCollapseBodyS>
                        {children}
                    </SidebarCollapseBodyS>
                    : null
                : isExpanded ? <SidebarCollapseBodyS>
                        {children}
                    </SidebarCollapseBodyS>
                    : null}
        </CollapseWrapper>
    );
};

const CollapseWrapper = styled.div.withConfig({
    shouldForwardProp: prop => !['border'].includes(prop)
})<{ border: boolean }>`

  border: ${({border}) => border ? '1px solid var(--border-color)' : 'none'};
  border-radius: 4px;
  margin-bottom: ${({border}) => border ? '5px' : '0'};
  position: relative;
`;

export const SidebarCollapseS = styled.div.withConfig({
    shouldForwardProp: prop => !['isInner'].includes(prop)
})<{ isInner: boolean }>`
  padding: 5px;
  display: flex;
  align-items: center;
  background-color: ${({isInner}) => isInner ? 'var(--overlay)' : 'transparent'};
  border-bottom: ${({isInner}) => isInner ? '1px solid var(--border-color)' : 'none'};;

  &:hover {
    background-color: var(--light-color);
    cursor: pointer;
  }

`;

export const SidebarCollapseBodyS = styled.div`
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  max-height: 400px;
  overflow-y: auto;
`;



