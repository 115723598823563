export const names = [
  {
    "id": "relation/956297",
    "name": "Пыталовский муниципальный округ"
  },
  {
    "id": "relation/956298",
    "name": "Печорский муниципальный округ"
  },
  {
    "id": "relation/956299",
    "name": "Пушкиногорский район"
  },
  {
    "id": "relation/956300",
    "name": "Палкинский район"
  },
  {
    "id": "relation/956301",
    "name": "Красногородский муниципальный округ"
  },
  {
    "id": "relation/956302",
    "name": "Островский район"
  },
  {
    "id": "relation/956303",
    "name": "Псковский район"
  },
  {
    "id": "relation/956304",
    "name": "Новоржевский муниципальный округ"
  },
  {
    "id": "relation/957270",
    "name": "Струго-Красненский муниципальный округ"
  },
  {
    "id": "relation/957271",
    "name": "Гдовский район"
  },
  {
    "id": "relation/957272",
    "name": "Дновский район"
  },
  {
    "id": "relation/957273",
    "name": "Локнянский муниципальный округ"
  },
  {
    "id": "relation/957274",
    "name": "Плюсский район"
  },
  {
    "id": "relation/957275",
    "name": "Дедовичский район"
  },
  {
    "id": "relation/957276",
    "name": "Бежаницкий район"
  },
  {
    "id": "relation/957277",
    "name": "Порховский район"
  },
  {
    "id": "relation/957291",
    "name": "Опочецкий муниципальный округ"
  },
  {
    "id": "relation/957753",
    "name": "Невельский муниципальный округ"
  },
  {
    "id": "relation/957755",
    "name": "Куньинский район"
  },
  {
    "id": "relation/957756",
    "name": "Пустошкинский район"
  },
  {
    "id": "relation/957757",
    "name": "Усвятский район"
  },
  {
    "id": "relation/957758",
    "name": "Великолукский район"
  },
  {
    "id": "relation/957760",
    "name": "Себежский район"
  },
  {
    "id": "relation/957761",
    "name": "Новосокольнический район"
  },
  {
    "id": "relation/1050399",
    "name": "городской округ Псков"
  },
  {
    "id": "relation/1724617",
    "name": "городской округ Великие Луки"
  },
  {
    "id": "node/26163795"
  },
  {
    "id": "node/27025689"
  },
  {
    "id": "node/27072632"
  },
  {
    "id": "node/27081144"
  },
  {
    "id": "node/27084320"
  },
  {
    "id": "node/76752092"
  },
  {
    "id": "node/173846663"
  },
  {
    "id": "node/173863860"
  },
  {
    "id": "node/246830274"
  },
  {
    "id": "node/278141459"
  },
  {
    "id": "node/285580257"
  },
  {
    "id": "node/301304385"
  },
  {
    "id": "node/304552666"
  },
  {
    "id": "node/342263289"
  },
  {
    "id": "node/342402547"
  },
  {
    "id": "node/342420981"
  },
  {
    "id": "node/356482952"
  },
  {
    "id": "node/369312472"
  },
  {
    "id": "node/706163973"
  },
  {
    "id": "node/738074805"
  },
  {
    "id": "node/884345171"
  },
  {
    "id": "node/2085977899"
  },
  {
    "id": "node/2499164596"
  },
  {
    "id": "node/4370385260"
  }
]
;