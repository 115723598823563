import React from 'react';
import {useSvgInteractive} from '../../hooks';
import {RuOms} from './mapLayouts';
import {RU} from './mapLayouts/RU';
import {RuMos} from './mapLayouts/RuMos';
import {RuRya} from './mapLayouts/RuRya';
import {RuLip} from './mapLayouts/RuLip';
import {RuTam} from './mapLayouts/RuTam';
import {RuVor} from './mapLayouts/RuVor';
import {RuPnz} from './mapLayouts/RuPnz';
import {RuUly} from './mapLayouts/RuUly';
import {RuSar} from './mapLayouts/RuSar';
import {RuSam} from './mapLayouts/RuSam';
import {RuNiz} from './mapLayouts/RuNiz';
import {RuMo} from './mapLayouts/RuMo';
import {RuVla} from './mapLayouts/RuVla';
import {RuKrs} from './mapLayouts/RuKrs';
import {RuOrl} from './mapLayouts/RuOrl';
import {RuVgg} from './mapLayouts/RuVgg';
import {RuOre} from './mapLayouts/RuOre';
import {RuBa} from './mapLayouts/RuBa';
import {RuChe} from './mapLayouts/RuChe';
import {RuTa} from './mapLayouts/RuTa';
import {RuSve} from './mapLayouts/RuSve';
import {RuCu} from './mapLayouts/RuCu';
import {RuTul} from './mapLayouts/RuTul';
import {RuYar} from './mapLayouts/RuYar';
import {RuSmo} from './mapLayouts/RuSmo';
import {RuRos} from './mapLayouts/RuRos';
import {RuKos} from './mapLayouts/RuKos';
import {RuNgr} from './mapLayouts/RuNgr';
import {RuMow} from './mapLayouts/RuMow';
import {RuKgd} from './mapLayouts/RuKgd';
import {RuIva} from './mapLayouts/RuIva';
import {RuSta} from './mapLayouts/RuSta';
import {RuKda} from './mapLayouts/RuKda';
import {RuKl} from './mapLayouts/RuKl';
import {RuDa} from './mapLayouts/RuDa';
import {RuCe} from './mapLayouts/RuCe';
import {RuKc} from './mapLayouts/RuKc';
import {RuSe} from './mapLayouts/RuSe';
import {RuKir} from './mapLayouts/RuKir';
import {RuVlg} from './mapLayouts/RuVlg';
import {RuMe} from './mapLayouts/RuMe';
import {RuUd} from './mapLayouts/RuUd';
import {RuKo} from './mapLayouts/RuKo';
import {RuKgn} from './mapLayouts/RuKgn';
import {RuTyu} from './mapLayouts/RuTyu';
import {RuNvs} from './mapLayouts/RuNvs';
import {RuTom} from './mapLayouts/RuTom';
import {RuKhm} from './mapLayouts/RuKhm';
import {RuKem} from './mapLayouts/RuKem';
import {RuAl} from './mapLayouts/RuAl';
import {RuTy} from './mapLayouts/RuTy';
import {RuIrk} from './mapLayouts/RuIrk';
import {RuBu} from './mapLayouts/RuBu';
import {RuAmu} from './mapLayouts/RuAmu';
import {RuYev} from './mapLayouts/RuYev';
import {RuKha} from './mapLayouts/RuKha';
import {RuPri} from './mapLayouts/RuPri';
import {RuMag} from './mapLayouts/RuMag';
import {RuChu} from './mapLayouts/RuChu';
import {RuKam} from './mapLayouts/RuKam';
import {RuSa} from './mapLayouts/RuSa';
import {RuPsk} from './mapLayouts/RuPsk';
import {RuLen} from './mapLayouts/RuLen';
import {RuKya} from './mapLayouts/RuKya';
import {RuKk} from './mapLayouts/RuKk';
import {RuYan} from './mapLayouts/RuYan';
import {RuIn} from './mapLayouts/RuIn';
import {RuNen} from './mapLayouts/RuNen';
import {RuSpe} from './mapLayouts/RuSpe';
import {RuKr} from './mapLayouts/RuKr';
import {RuSak} from './mapLayouts/RuSak';
import {RuTve} from './mapLayouts/RuTve';
import {RuMur} from './mapLayouts/RuMur';
import * as AL from './regionNames/RU-AL_145194';
import * as AMU from './regionNames/RU-AMU_147166';
import * as BA from './regionNames/RU-BA_77677';
import * as BU from './regionNames/RU-BU_145729';
import * as CE from './regionNames/RU-CE_109877';
import * as CHE from './regionNames/RU-CHE_77687';
import * as CHU from './regionNames/RU-CHU_151231';
import * as CU from './regionNames/RU-CU_80513';
import * as DA from './regionNames/RU-DA_109876';
import * as IN from './regionNames/RU-IN_253252';
import * as IRK from './regionNames/RU-IRK_145454';
import * as IVA from './regionNames/RU-IVA_85617';
import * as KAM from './regionNames/RU-KAM_151233';
import * as KB from './regionNames/RU-KB_109879';
import * as KC from './regionNames/RU-KC_109878';
import * as KDA from './regionNames/RU-KDA_108082';
import * as KEM from './regionNames/RU-KEM_144763';
import * as KGD from './regionNames/RU-KGD_103906';
import * as KGN from './regionNames/RU-KGN_140290';
import * as KHA from './regionNames/RU-KHA_151223';
import * as KHM from './regionNames/RU-KHM_140296';
import * as KIR from './regionNames/RU-KIR_115100';
import * as KK from './regionNames/RU-KK_190911';
import * as KL from './regionNames/RU-KL_108083';
import * as KOS from './regionNames/RU-KOS_85963';
import * as KO from './regionNames/RU-KO_115136';
import * as KRS from './regionNames/RU-KRS_72223';
import * as KR from './regionNames/RU-KR_393980';
import * as KYA from './regionNames/RU-KYA_190090';
import * as LEN from './regionNames/RU-LEN_176095';
import * as LIP from './regionNames/RU-LIP_72169';
import * as MAG from './regionNames/RU-MAG_151228';
import * as ME from './regionNames/RU-ME_115114';
import * as MOS from './regionNames/RU-MOS_51490';
import * as MOW from './regionNames/RU-MOW_102269';
import * as MO from './regionNames/RU-MO_72196';
import * as MUR from './regionNames/RU-MUR_2099216';
import * as NEN from './regionNames/RU-NEN_274048';
import * as NGR from './regionNames/RU-NGR_89331';
import * as NIZ from './regionNames/RU-NIZ_72195';
import * as NVS from './regionNames/RU-NVS_140294';
import * as OMS from './regionNames/RU-OMS_140292';
import * as ORE from './regionNames/RU-ORE_77669';
import * as ORL from './regionNames/RU-ORL_72224';
import * as PNZ from './regionNames/RU-PNZ_72182';
import * as PRI from './regionNames/RU-PRI_151225';
import * as PSK from './regionNames/RU-PSK_155262';
import * as ROS from './regionNames/RU-ROS_85606';
import * as RYA from './regionNames/RU-RYA_71950';
import * as SAK from './regionNames/RU-SAK_394235';
import * as SAM from './regionNames/RU-SAM_72194';
import * as SAR from './regionNames/RU-SAR_72193';
import * as SA from './regionNames/RU-SA_151234';
import * as SE from './regionNames/RU-SE_110032';
import * as SMO from './regionNames/RU-SMO_81996';
import * as SPE from './regionNames/RU-SPE_337422';
import * as STA from './regionNames/RU-STA_108081';
import * as SVE from './regionNames/RU-SVE_79379';
import * as TAM from './regionNames/RU-TAM_72180';
import * as TA from './regionNames/RU-TA_79374';
import * as TOM from './regionNames/RU-TOM_140295';
import * as TUL from './regionNames/RU-TUL_81993';
import * as TVE from './regionNames/RU-TVE_2095259';
import * as TYU from './regionNames/RU-TYU_140291';
import * as TY from './regionNames/RU-TY_145195';
import * as UD from './regionNames/RU-UD_115134';
import * as ULY from './regionNames/RU-ULY_72192';
import * as VGG from './regionNames/RU-VGG_77665';
import * as VLA from './regionNames/RU-VLA_72197';
import * as VLG from './regionNames/RU-VLG_115106';
import * as VOR from './regionNames/RU-VOR_72181';
import * as YAN from './regionNames/RU-YAN_191706';
import * as YAR from './regionNames/RU-YAR_81994';
import * as YEV from './regionNames/RU-YEV_147167';
import * as DON from './regionNames/RU_DON_71980';
import * as ZAP from './regionNames/RU_ZAP_71973';
import * as HERSON from './regionNames/RU_HERSON_71971';
import * as LUG from './regionNames/RU_LUG_71022';
import * as RUS from './regionNames/RU';
import {RuKb} from './mapLayouts/RuKb';
import styled from 'styled-components';
import {RU_HERSON} from './mapLayouts/RU_HERSON';
import {RU_ZAP} from './mapLayouts/RU_ZAP';
import {RU_DON} from './mapLayouts/RU_DON';
import {RU_LUG} from './mapLayouts/RU_LUG';
import {UseSvgHookOnClick} from '../../hooks/useSvgInteractive';

export const regions: any = {
    rus: RUS,
    145194: AL,
    147166: AMU,
    77677: BA,
    145729: BU,
    109877: CE,
    77687: CHE,
    151231: CHU,
    80513: CU,
    109876: DA,
    253252: IN,
    145454: IRK,
    85617: IVA,
    151233: KAM,
    109879: KB,
    109878: KC,
    108082: KDA,
    144763: KEM,
    103906: KGD,
    140290: KGN,
    151223: KHA,
    140296: KHM,
    115100: KIR,
    190911: KK,
    108083: KL,
    85963: KOS,
    115136: KO,
    72223: KRS,
    393980: KR,
    190090: KYA,
    176095: LEN,
    72169: LIP,
    151228: MAG,
    115114: ME,
    51490: MOS,
    102269: MOW,
    72196: MO,
    2099216: MUR,
    274048: NEN,
    89331: NGR,
    72195: NIZ,
    140294: NVS,
    140292: OMS,
    77669: ORE,
    72224: ORL,
    72182: PNZ,
    151225: PRI,
    155262: PSK,
    85606: ROS,
    71950: RYA,
    394235: SAK,
    72194: SAM,
    72193: SAR,
    151234: SA,
    110032: SE,
    81996: SMO,
    337422: SPE,
    108081: STA,
    79379: SVE,
    72180: TAM,
    79374: TA,
    140295: TOM,
    81993: TUL,
    2095259: TVE,
    140291: TYU,
    145195: TY,
    115134: UD,
    72192: ULY,
    77665: VGG,
    72197: VLA,
    115106: VLG,
    72181: VOR,
    191706: YAN,
    81994: YAR,
    147167: YEV,
    71973: DON,
    71980: ZAP,
    71022: HERSON,
    71971: LUG,
};

type RenderMapProps = {
    regionId: string;
    onlyRu: boolean;
    onHover: (regionName: string) => void;
    onClick: ({regionId, name}: UseSvgHookOnClick) => void
}

export const RenderMap = ({regionId, onlyRu, onClick, onHover}: RenderMapProps) => {

    const {svgRef} = useSvgInteractive(
        {
            viewContent: regions[regionId.replace('relation/', '')]?.names ?? [],
            click: onClick,
            hover: id => {
                onHover(id);
            }
        });

    const renderSvg = () => {
        if (onlyRu) {
            return <RU ref={svgRef}/>;
        }

        switch (regionId) {
            case 'relation/51490':
                return <RuMos ref={svgRef}/>;
            case 'relation/71950':
                return <RuRya ref={svgRef}/>;
            case 'relation/72169':
                return <RuLip ref={svgRef}/>;
            case 'relation/72180':
                return <RuTam ref={svgRef}/>;
            case 'relation/72181':
                return <RuVor ref={svgRef}/>;
            case 'relation/72182':
                return <RuPnz ref={svgRef}/>;
            case 'relation/72192':
                return <RuUly ref={svgRef}/>;
            case 'relation/72193':
                return <RuSar ref={svgRef}/>;
            case 'relation/72194':
                return <RuSam ref={svgRef}/>;
            case 'relation/72195':
                return <RuNiz ref={svgRef}/>;
            case 'relation/72196':
                return <RuMo ref={svgRef}/>;
            case 'relation/72197':
                return <RuVla ref={svgRef}/>;
            case 'relation/72223':
                return <RuKrs ref={svgRef}/>;
            case 'relation/72224':
                return <RuOrl ref={svgRef}/>;
            // case 'relation/72639':
            //     return <Ua-43 ref={svgRef}/>;
            case 'relation/77665':
                return <RuVgg ref={svgRef}/>;
            case 'relation/77669':
                return <RuOre ref={svgRef}/>;
            case 'relation/77677':
                return <RuBa ref={svgRef}/>;
            case 'relation/77687':
                return <RuChe ref={svgRef}/>;
            case 'relation/79374':
                return <RuTa ref={svgRef}/>;
            case 'relation/79379':
                return <RuSve ref={svgRef}/>;
            case 'relation/80513':
                return <RuCu ref={svgRef}/>;
            case 'relation/81993':
                return <RuTul ref={svgRef}/>;
            case 'relation/81994':
                return <RuYar ref={svgRef}/>;
            // case 'relation/81995':
            //     return <RuKlu ref={svgRef}/>;
            case 'relation/81996':
                return <RuSmo ref={svgRef}/>;
            // case 'relation/81997':
            //     return <RuBry ref={svgRef}/>;
            // case 'relation/83184':
            //     return <RuBel ref={svgRef}/>;
            case 'relation/85606':
                return <RuRos ref={svgRef}/>;
            case 'relation/85617':
                return <RuIva ref={svgRef}/>;
            case 'relation/85963':
                return <RuKos ref={svgRef}/>;
            case 'relation/89331':
                return <RuNgr ref={svgRef}/>;
            case 'relation/102269':
                return <RuMow ref={svgRef}/>;
            case 'relation/103906':
                return <RuKgd ref={svgRef}/>;
            case 'relation/108081':
                return <RuSta ref={svgRef}/>;
            case 'relation/108082':
                return <RuKda ref={svgRef}/>;
            case 'relation/108083':
                return <RuKl ref={svgRef}/>;
            case 'relation/109876':
                return <RuDa ref={svgRef}/>;
            case 'relation/109877':
                return <RuCe ref={svgRef}/>;
            case 'relation/109878':
                return <RuKc ref={svgRef}/>;
            case 'relation/109879':
                return <RuKb ref={svgRef}/>;
            case 'relation/110032':
                return <RuSe ref={svgRef}/>;
            // case 'relation/112819':
            //     return <RuAst ref={svgRef}/>;
            case 'relation/115100':
                return <RuKir ref={svgRef}/>;
            case 'relation/115106':
                return <RuVlg ref={svgRef}/>;
            case 'relation/115114':
                return <RuMe ref={svgRef}/>;
            case 'relation/115134':
                return <RuUd ref={svgRef}/>;
            // case 'relation/115135':
            //     return <RuPer ref={svgRef}/>;
            case 'relation/115136':
                return <RuKo ref={svgRef}/>;
            case 'relation/140290':
                return <RuKgn ref={svgRef}/>;
            case 'relation/140291':
                return <RuTyu ref={svgRef}/>;
            case 'relation/140292':
                return <RuOms ref={svgRef}/>;
            case 'relation/140294':
                return <RuNvs ref={svgRef}/>;
            case 'relation/140295':
                return <RuTom ref={svgRef}/>;
            case 'relation/140296':
                return <RuKhm ref={svgRef}/>;
            // case 'relation/140337':
            //     return <RuArk ref={svgRef}/>;
            case 'relation/144763':
                return <RuKem ref={svgRef}/>;
            // case 'relation/144764':
            //     return <RuAlt ref={svgRef}/>;
            case 'relation/145194':
                return <RuAl ref={svgRef}/>;
            case 'relation/145195':
                return <RuTy ref={svgRef}/>;
            case 'relation/145454':
                return <RuIrk ref={svgRef}/>;
            case 'relation/145729':
                return <RuBu ref={svgRef}/>;
            // case 'relation/145730':
            //     return <RuZab ref={svgRef}/>;
            case 'relation/147166':
                return <RuAmu ref={svgRef}/>;
            case 'relation/147167':
                return <RuYev ref={svgRef}/>;
            case 'relation/151223':
                return <RuKha ref={svgRef}/>;
            case 'relation/151225':
                return <RuPri ref={svgRef}/>;
            case 'relation/151228':
                return <RuMag ref={svgRef}/>;
            case 'relation/151231':
                return <RuChu ref={svgRef}/>;
            case 'relation/151233':
                return <RuKam ref={svgRef}/>;
            case 'relation/151234':
                return <RuSa ref={svgRef}/>;
            case 'relation/155262':
                return <RuPsk ref={svgRef}/>;
            case 'relation/176095':
                return <RuLen ref={svgRef}/>;
            case 'relation/190090':
                return <RuKya ref={svgRef}/>;
            case 'relation/190911':
                return <RuKk ref={svgRef}/>;
            case 'relation/191706':
                return <RuYan ref={svgRef}/>;
            case 'relation/253252':
                return <RuIn ref={svgRef}/>;
            // case 'relation/253256':
            //     return <RuAd ref={svgRef}/>;
            case 'relation/274048':
                return <RuNen ref={svgRef}/>;
            case 'relation/337422':
                return <RuSpe ref={svgRef}/>;
            case 'relation/393980':
                return <RuKr ref={svgRef}/>;
            case 'relation/394235':
                return <RuSak ref={svgRef}/>;
            case 'relation/2095259':
                return <RuTve ref={svgRef}/>;
            case 'relation/2099216':
                return <RuMur ref={svgRef}/>;
            case 'relation/71022':
                return <RU_HERSON ref={svgRef}/>;
            case 'relation/71980':
                return <RU_ZAP ref={svgRef}/>;
            case 'relation/71973':
                return <RU_DON ref={svgRef}/>;
            case 'relation/71971':
                return <RU_LUG ref={svgRef}/>;
            default:
                return <RU ref={svgRef}/>;
        }
    };

    return <Wrapper>
        {renderSvg()}
    </Wrapper>;
};

const Wrapper = styled.div`
  & svg {
    height: 443px;
  }
`;
