export const names = [
  {
    "id": "relation/1314105",
    "name": "Москаленский район"
  },
  {
    "id": "relation/1314106",
    "name": "Колосовский район"
  },
  {
    "id": "relation/1314107",
    "name": "Называевский район"
  },
  {
    "id": "relation/1314108",
    "name": "Кормиловский район"
  },
  {
    "id": "relation/1314109",
    "name": "Любинский район"
  },
  {
    "id": "relation/1314110",
    "name": "Полтавский район"
  },
  {
    "id": "relation/1314111",
    "name": "Крутинский район"
  },
  {
    "id": "relation/1314112",
    "name": "Марьяновский район"
  },
  {
    "id": "relation/1314113",
    "name": "Большеуковский район"
  },
  {
    "id": "relation/1314114",
    "name": "Оконешниковский район"
  },
  {
    "id": "relation/1314115",
    "name": "Тарский район"
  },
  {
    "id": "relation/1314116",
    "name": "Тевризский район"
  },
  {
    "id": "relation/1314117",
    "name": "Большереченский район"
  },
  {
    "id": "relation/1314118",
    "name": "Черлакский район"
  },
  {
    "id": "relation/1314119",
    "name": "Саргатский район"
  },
  {
    "id": "relation/1314120",
    "name": "Исилькульский район"
  },
  {
    "id": "relation/1314121",
    "name": "Шербакульский район"
  },
  {
    "id": "relation/1314122",
    "name": "Азовский немецкий национальный район"
  },
  {
    "id": "relation/1314123",
    "name": "Усть-Ишимский район"
  },
  {
    "id": "relation/1314124",
    "name": "Знаменский район"
  },
  {
    "id": "relation/1314125",
    "name": "Одесский район"
  },
  {
    "id": "relation/1314126",
    "name": "Седельниковский район"
  },
  {
    "id": "relation/1314127",
    "name": "Нижнеомский район"
  },
  {
    "id": "relation/1314128",
    "name": "Тюкалинский район"
  },
  {
    "id": "relation/1314129",
    "name": "Нововаршавский район"
  },
  {
    "id": "relation/1314130",
    "name": "Русско-Полянский район"
  },
  {
    "id": "relation/1314131",
    "name": "Таврический район"
  },
  {
    "id": "relation/1314132",
    "name": "Павлоградский район"
  },
  {
    "id": "relation/1314133",
    "name": "Горьковский район"
  },
  {
    "id": "relation/1314134",
    "name": "Муромцевский район"
  },
  {
    "id": "relation/1314135",
    "name": "Омский район"
  },
  {
    "id": "relation/1314136",
    "name": "Калачинский район"
  },
  {
    "id": "relation/1558108",
    "name": "городской округ Омск"
  },
  {
    "id": "node/27503946"
  },
  {
    "id": "node/243993520"
  },
  {
    "id": "node/278876144"
  },
  {
    "id": "node/289980364"
  },
  {
    "id": "node/289992321"
  },
  {
    "id": "node/289994080"
  },
  {
    "id": "node/289996348"
  },
  {
    "id": "node/296138675"
  },
  {
    "id": "node/378946456"
  },
  {
    "id": "node/466973903"
  },
  {
    "id": "node/517658001"
  },
  {
    "id": "node/517682881"
  },
  {
    "id": "node/524331733"
  },
  {
    "id": "node/569157957"
  },
  {
    "id": "node/569158053"
  },
  {
    "id": "node/673459909"
  },
  {
    "id": "node/739265601"
  },
  {
    "id": "node/747783597"
  },
  {
    "id": "node/748727755"
  },
  {
    "id": "node/748727983"
  },
  {
    "id": "node/769049397"
  },
  {
    "id": "node/773512447"
  },
  {
    "id": "node/773512565"
  },
  {
    "id": "node/773512978"
  },
  {
    "id": "node/774520852"
  },
  {
    "id": "node/781601321"
  },
  {
    "id": "node/864635568"
  },
  {
    "id": "node/938450238"
  },
  {
    "id": "node/1028723970"
  },
  {
    "id": "node/1028724251"
  },
  {
    "id": "node/1276034408"
  },
  {
    "id": "node/1838482543"
  },
  {
    "id": "node/1866817120"
  }
]
;