export const names = [
  {
    "id": "relation/373649",
    "name": "Судогодский район"
  },
  {
    "id": "relation/389660",
    "name": "Суздальский район"
  },
  {
    "id": "relation/389661",
    "name": "Александровский район"
  },
  {
    "id": "relation/389662",
    "name": "Киржачский район"
  },
  {
    "id": "relation/389663",
    "name": "Петушинский район"
  },
  {
    "id": "relation/389664",
    "name": "Собинский район"
  },
  {
    "id": "relation/389665",
    "name": "Гусь-Хрустальный район"
  },
  {
    "id": "relation/389666",
    "name": "Меленковский район"
  },
  {
    "id": "relation/389667",
    "name": "Муромский район"
  },
  {
    "id": "relation/389668",
    "name": "Гороховецкий район"
  },
  {
    "id": "relation/389669",
    "name": "Вязниковский район"
  },
  {
    "id": "relation/389670",
    "name": "Ковровский район"
  },
  {
    "id": "relation/389671",
    "name": "Камешковский район"
  },
  {
    "id": "relation/389672",
    "name": "Юрьев-Польский район"
  },
  {
    "id": "relation/389673",
    "name": "Кольчугинский район"
  },
  {
    "id": "relation/389674",
    "name": "Селивановский район"
  },
  {
    "id": "relation/389675",
    "name": "городской округ Муром"
  },
  {
    "id": "relation/389676",
    "name": "городской округ Ковров"
  },
  {
    "id": "relation/389677",
    "name": "городской округ Владимир"
  },
  {
    "id": "relation/389678",
    "name": "городской округ Гусь-Хрустальный"
  },
  {
    "id": "relation/389679",
    "name": "ЗАТО Радужный"
  },
  {
    "id": "node/93387393"
  },
  {
    "id": "node/93390174"
  },
  {
    "id": "node/191698855"
  },
  {
    "id": "node/191866198"
  },
  {
    "id": "node/191884653"
  },
  {
    "id": "node/256361853"
  },
  {
    "id": "node/265316295"
  },
  {
    "id": "node/314413011"
  },
  {
    "id": "node/314794893"
  },
  {
    "id": "node/339251383"
  },
  {
    "id": "node/339251384"
  },
  {
    "id": "node/356482973"
  },
  {
    "id": "node/560380081"
  },
  {
    "id": "node/799990090"
  },
  {
    "id": "node/1404788510"
  },
  {
    "id": "node/1686969305"
  },
  {
    "id": "node/1686969311"
  },
  {
    "id": "node/1908737047"
  }
]
;