import styled from 'styled-components';


export const DataHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 5px 20px;

`;

export const DataInfoRow = styled.div`
  padding: 0 5px;
  display: flex;
  align-items: center;
  width: 100%;
  background-color: #ffffff;
  border-bottom: 1px solid var(--border-color);

  &:first-of-type {
    border-top: 2px solid var(--border-color);
  }

  &:nth-child(odd) {
    background-color: var(--row-d);
  }

  &:last-of-type {
    border: none;
  }
`;

export const DataInfoCol = styled.div`
  width: 60%;
  padding: 5px;

  &:nth-child(1) {
    width: 30%;
  }

  &:nth-child(2) {
    background-color: var(--overlay-dark);
  }

  &:nth-child(3) {
    width: 15px;
  }
`;

export const PointColor = styled.div.withConfig({
    shouldForwardProp: prop => !['color'].includes(prop)
})<{ color: string }>`
  background-color: ${({color}) => color};
  width: 15px;
  height: 15px;
  border-radius: 50%;
`;

export const TitleWrapper = styled.div`
  width: 100%;
`;

export const TitleRow = styled.div`
  display: flex;
  align-items: flex-end;
  width: 100%;
  padding: 0;
`;
