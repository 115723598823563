export const names = [
  {
    "id": "relation/192543",
    "name": "Усть-Коксинский район"
  },
  {
    "id": "relation/192544",
    "name": "Усть-Канский район"
  },
  {
    "id": "relation/192545",
    "name": "Шебалинский район"
  },
  {
    "id": "relation/192546",
    "name": "Онгудайский район"
  },
  {
    "id": "relation/192547",
    "name": "Кош-Агачский район"
  },
  {
    "id": "relation/192559",
    "name": "Улаганский район"
  },
  {
    "id": "relation/192560",
    "name": "Турочакский район"
  },
  {
    "id": "relation/192563",
    "name": "Майминский район"
  },
  {
    "id": "relation/192564",
    "name": "Чемальский район"
  },
  {
    "id": "relation/953581",
    "name": "Чойский район"
  },
  {
    "id": "relation/1875749",
    "name": "городской округ Горно-Алтайск"
  },
  {
    "id": "node/191636706"
  },
  {
    "id": "node/280404821"
  },
  {
    "id": "node/280513195"
  },
  {
    "id": "node/280513628"
  },
  {
    "id": "node/280515128"
  },
  {
    "id": "node/280521185"
  },
  {
    "id": "node/280550570"
  },
  {
    "id": "node/289835271"
  },
  {
    "id": "node/297513007"
  },
  {
    "id": "node/2500528613"
  },
  {
    "id": "node/3030450878"
  }
]
;