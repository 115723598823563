import styled from 'styled-components';

type RegionMapSProps = {
    height: number;
}

export const RegionMapS = styled.div.withConfig({
    shouldForwardProp: prop => !['height'].includes(prop)
})<RegionMapSProps>`
  width: 100%;
  height: ${({height}) => height + 'px'};
  display: flex;
  align-items: center;
  justify-content: center;

  path,
  rect,
  text {
    &:hover {
      cursor: pointer;
    }
  }
`;
