export const names = [
  {
    "id": "relation/269701",
    "name": "городской округ Краснодар"
  },
  {
    "id": "relation/364116",
    "name": "Динской район"
  },
  {
    "id": "relation/364123",
    "name": "Красноармейский район"
  },
  {
    "id": "relation/364150",
    "name": "Северский район"
  },
  {
    "id": "relation/364156",
    "name": "Абинский район"
  },
  {
    "id": "relation/364165",
    "name": "Славянский район"
  },
  {
    "id": "relation/364169",
    "name": "Крымский район"
  },
  {
    "id": "relation/364171",
    "name": "Темрюкский район"
  },
  {
    "id": "relation/364543",
    "name": "городской округ Анапа"
  },
  {
    "id": "relation/364545",
    "name": "городской округ Новороссийск"
  },
  {
    "id": "relation/364548",
    "name": "городской округ Геленджик"
  },
  {
    "id": "relation/365144",
    "name": "городской округ Горячий Ключ"
  },
  {
    "id": "relation/365147",
    "name": "Белореченский район"
  },
  {
    "id": "relation/365168",
    "name": "Апшеронский район"
  },
  {
    "id": "relation/365231",
    "name": "Туапсинский район"
  },
  {
    "id": "relation/365233",
    "name": "городской округ Сочи"
  },
  {
    "id": "relation/365297",
    "name": "Мостовский район"
  },
  {
    "id": "relation/365299",
    "name": "Ейский район"
  },
  {
    "id": "relation/365302",
    "name": "Щербиновский район"
  },
  {
    "id": "relation/365303",
    "name": "Староминский район"
  },
  {
    "id": "relation/365306",
    "name": "Приморско-Ахтарский район"
  },
  {
    "id": "relation/365308",
    "name": "Калининский район"
  },
  {
    "id": "relation/365309",
    "name": "Тимашёвский район"
  },
  {
    "id": "relation/365311",
    "name": "Брюховецкий район"
  },
  {
    "id": "relation/371552",
    "name": "Кущёвский район"
  },
  {
    "id": "relation/371556",
    "name": "Крыловский район"
  },
  {
    "id": "relation/371561",
    "name": "Каневской район"
  },
  {
    "id": "relation/371571",
    "name": "Ленинградский район"
  },
  {
    "id": "relation/371579",
    "name": "Лабинский район"
  },
  {
    "id": "relation/371580",
    "name": "Отрадненский район"
  },
  {
    "id": "relation/371607",
    "name": "Успенский район"
  },
  {
    "id": "relation/371614",
    "name": "городской округ Армавир"
  },
  {
    "id": "relation/371621",
    "name": "Новокубанский район"
  },
  {
    "id": "relation/918967",
    "name": "Курганинский район"
  },
  {
    "id": "relation/918974",
    "name": "Гулькевичский район"
  },
  {
    "id": "relation/918986",
    "name": "Кавказский район"
  },
  {
    "id": "relation/918989",
    "name": "Белоглинский район"
  },
  {
    "id": "relation/918994",
    "name": "Новопокровский район"
  },
  {
    "id": "relation/918995",
    "name": "Павловский район"
  },
  {
    "id": "relation/918999",
    "name": "Кореновский район"
  },
  {
    "id": "relation/919000",
    "name": "Тбилисский район"
  },
  {
    "id": "relation/919004",
    "name": "Тихорецкий район"
  },
  {
    "id": "relation/919005",
    "name": "Выселковский район"
  },
  {
    "id": "relation/919011",
    "name": "Усть-Лабинский район"
  },
  {
    "id": "relation/11865346",
    "name": "городской округ Сириус"
  },
  {
    "id": "node/27505129"
  },
  {
    "id": "node/29750243"
  },
  {
    "id": "node/29751525"
  },
  {
    "id": "node/29804102"
  },
  {
    "id": "node/29866496"
  },
  {
    "id": "node/29866526"
  },
  {
    "id": "node/34043670"
  },
  {
    "id": "node/34181150"
  },
  {
    "id": "node/36722707"
  },
  {
    "id": "node/36722824"
  },
  {
    "id": "node/87705691"
  },
  {
    "id": "node/191824980"
  },
  {
    "id": "node/253814474"
  },
  {
    "id": "node/270691553"
  },
  {
    "id": "node/295381557"
  },
  {
    "id": "node/295754500"
  },
  {
    "id": "node/296161951"
  },
  {
    "id": "node/296346416"
  },
  {
    "id": "node/296376924"
  },
  {
    "id": "node/296379511"
  },
  {
    "id": "node/296537017"
  },
  {
    "id": "node/296884989"
  },
  {
    "id": "node/296886272"
  },
  {
    "id": "node/296886274"
  },
  {
    "id": "node/297082357"
  },
  {
    "id": "node/297958924"
  },
  {
    "id": "node/298061350"
  },
  {
    "id": "node/298352406"
  },
  {
    "id": "node/298364538"
  },
  {
    "id": "node/301489268"
  },
  {
    "id": "node/301490224"
  },
  {
    "id": "node/304718444"
  },
  {
    "id": "node/304718565"
  },
  {
    "id": "node/356773068"
  },
  {
    "id": "node/356773072"
  },
  {
    "id": "node/356773132"
  },
  {
    "id": "node/356773141"
  },
  {
    "id": "node/356773465"
  },
  {
    "id": "node/356773588"
  },
  {
    "id": "node/653145095"
  },
  {
    "id": "node/1123091817"
  },
  {
    "id": "node/1645221793"
  },
  {
    "id": "node/1845838485"
  },
  {
    "id": "node/8097262896"
  },
  {
    "id": "node/5618896828"
  }
]
;