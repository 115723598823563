export const names = [
  {
    "id": "relation/1746013",
    "name": "Александрово-Гайский район"
  },
  {
    "id": "relation/1746014",
    "name": "Аркадакский район"
  },
  {
    "id": "relation/1746015",
    "name": "Аткарский район"
  },
  {
    "id": "relation/1746016",
    "name": "Базарно-Карабулакский район"
  },
  {
    "id": "relation/1746017",
    "name": "Балаковский район"
  },
  {
    "id": "relation/1746018",
    "name": "Балашовский район"
  },
  {
    "id": "relation/1746019",
    "name": "Балтайский район"
  },
  {
    "id": "relation/1746020",
    "name": "Вольский район"
  },
  {
    "id": "relation/1746021",
    "name": "Воскресенский район"
  },
  {
    "id": "relation/1746023",
    "name": "Дергачёвский район"
  },
  {
    "id": "relation/1746024",
    "name": "Духовницкий район"
  },
  {
    "id": "relation/1746025",
    "name": "Екатериновский район"
  },
  {
    "id": "relation/1746026",
    "name": "Ершовский район"
  },
  {
    "id": "relation/1746027",
    "name": "Ивантеевский район"
  },
  {
    "id": "relation/1746028",
    "name": "Калининский район"
  },
  {
    "id": "relation/1746029",
    "name": "Красноармейский район"
  },
  {
    "id": "relation/1746030",
    "name": "Краснокутский район"
  },
  {
    "id": "relation/1746031",
    "name": "Краснопартизанский район"
  },
  {
    "id": "relation/1746032",
    "name": "Лысогорский район"
  },
  {
    "id": "relation/1746033",
    "name": "Марксовский район"
  },
  {
    "id": "relation/1746034",
    "name": "городской округ Михайловский"
  },
  {
    "id": "relation/1746035",
    "name": "Новобурасский район"
  },
  {
    "id": "relation/1746036",
    "name": "Новоузенский район"
  },
  {
    "id": "relation/1746037",
    "name": "Озинский район"
  },
  {
    "id": "relation/1746038",
    "name": "Перелюбский район"
  },
  {
    "id": "relation/1746039",
    "name": "Петровский район"
  },
  {
    "id": "relation/1746040",
    "name": "Питерский район"
  },
  {
    "id": "relation/1746041",
    "name": "Пугачёвский район"
  },
  {
    "id": "relation/1746042",
    "name": "Ровенский район"
  },
  {
    "id": "relation/1746043",
    "name": "Романовский район"
  },
  {
    "id": "relation/1746044",
    "name": "Ртищевский район"
  },
  {
    "id": "relation/1746045",
    "name": "Самойловский район"
  },
  {
    "id": "relation/1746048",
    "name": "Советский район"
  },
  {
    "id": "relation/1746049",
    "name": "Татищевский район"
  },
  {
    "id": "relation/1746088",
    "name": "Турковский район"
  },
  {
    "id": "relation/1746089",
    "name": "Фёдоровский район"
  },
  {
    "id": "relation/1746090",
    "name": "Хвалынский район"
  },
  {
    "id": "relation/1746091",
    "name": "городской округ Шиханы"
  },
  {
    "id": "relation/1746092",
    "name": "Энгельсский район"
  },
  {
    "id": "relation/2725551",
    "name": "городской округ Саратов"
  },
  {
    "id": "relation/3149067",
    "name": "ЗАТО Светлый"
  },
  {
    "id": "node/27504590"
  },
  {
    "id": "node/90441479"
  },
  {
    "id": "node/101480194"
  },
  {
    "id": "node/281613050"
  },
  {
    "id": "node/287005365"
  },
  {
    "id": "node/287756202"
  },
  {
    "id": "node/336140336"
  },
  {
    "id": "node/336140395"
  },
  {
    "id": "node/336140412"
  },
  {
    "id": "node/336140509"
  },
  {
    "id": "node/336140567"
  },
  {
    "id": "node/336140569"
  },
  {
    "id": "node/336140607"
  },
  {
    "id": "node/336140761"
  },
  {
    "id": "node/336141212"
  },
  {
    "id": "node/344967838"
  },
  {
    "id": "node/344967862"
  },
  {
    "id": "node/344967884"
  },
  {
    "id": "node/344967885"
  },
  {
    "id": "node/344967887"
  },
  {
    "id": "node/344967893"
  },
  {
    "id": "node/344967905"
  },
  {
    "id": "node/344967910"
  },
  {
    "id": "node/344967920"
  },
  {
    "id": "node/344967937"
  },
  {
    "id": "node/344967942"
  },
  {
    "id": "node/344967943"
  },
  {
    "id": "node/344967973"
  },
  {
    "id": "node/344967989"
  },
  {
    "id": "node/344967991"
  },
  {
    "id": "node/344968002"
  },
  {
    "id": "node/914747093"
  },
  {
    "id": "node/914747140"
  },
  {
    "id": "node/915072945"
  },
  {
    "id": "node/1028724026"
  },
  {
    "id": "node/1028724183"
  },
  {
    "id": "node/1213967431"
  },
  {
    "id": "node/1219930932"
  },
  {
    "id": "node/1427063759"
  },
  {
    "id": "node/1427063760"
  },
  {
    "id": "node/1567120290"
  }
]
;