import React, {ReactNode, useState} from 'react';
import styled from 'styled-components';
import {Info, TreeLine} from '../assets/icons';

type SidebarWrapperProps = {
    children: ReactNode
}

export const SidebarWrapper = ({children}: SidebarWrapperProps) => {
    const [open, setOpen] = useState<boolean>(true);

    return (
        <DataColumnS width={open ? '200px' : '40px'}>
            <DataToggle onClick={() => setOpen(!open)}>
                <TreeLine rotate={open}/>
            </DataToggle>
            {open ? children : <InfoWrapper><Info/></InfoWrapper>}
        </DataColumnS>
    );
};

type DataColumnType = {
    width: string;
}
const DataColumnS = styled.div.withConfig({
    shouldForwardProp: prop => !['width'].includes(prop)
})<DataColumnType>`
  width: ${({width}) => width};
  border: 1px solid var(--border-color);
  background-color: #ffffff;
  padding: 5px;
  border-radius: 4px;
  min-height: 300px;
  overflow: scroll;
  transition: 0.3s;
`;

const DataToggle = styled.div`
  width: 16px;
  height: 16px;
  margin-left: auto;
  margin-right: 7px;
  margin-bottom: 10px;

  &:hover {
    cursor: pointer;
  }
`;

const InfoWrapper = styled.div`
  text-align: center;
  margin-top: 20px;
`;

export const SidebarSeparator = styled.div.withConfig({
    shouldForwardProp: prop => !['spaceVertical'].includes(prop)
})<{ spaceVertical: number }>`
  width: 100%;
  height: 1px;
  background-color: var(--border-color);
  margin: ${({spaceVertical}) => `${spaceVertical}px 0`};
`;
