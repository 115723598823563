export const names = [
  {
    "id": "relation/1692008",
    "name": "Алеутский муниципальный округ"
  },
  {
    "id": "relation/1783590",
    "name": "Быстринский район"
  },
  {
    "id": "relation/1783591",
    "name": "Вилючинский городской округ"
  },
  {
    "id": "relation/1783592",
    "name": "Елизовский район"
  },
  {
    "id": "relation/1783593",
    "name": "Карагинский район"
  },
  {
    "id": "relation/1783594",
    "name": "Мильковский муниципальный округ"
  },
  {
    "id": "relation/1783595",
    "name": "Олюторский район"
  },
  {
    "id": "relation/1783596",
    "name": "Пенжинский район"
  },
  {
    "id": "relation/1783597",
    "name": "городской округ Петропавловск-Камчатский"
  },
  {
    "id": "relation/1783599",
    "name": "Соболевский район"
  },
  {
    "id": "relation/1783600",
    "name": "Тигильский район"
  },
  {
    "id": "relation/1783601",
    "name": "Усть-Большерецкий район"
  },
  {
    "id": "relation/1783602",
    "name": "Усть-Камчатский район"
  },
  {
    "id": "relation/1854136",
    "name": "городской округ Палана"
  },
  {
    "id": "node/191667890"
  },
  {
    "id": "node/360831011"
  },
  {
    "id": "node/360831467"
  },
  {
    "id": "node/360831600"
  },
  {
    "id": "node/360831660"
  },
  {
    "id": "node/360831763"
  },
  {
    "id": "node/512485280"
  },
  {
    "id": "node/1028723737"
  },
  {
    "id": "node/1028723986"
  },
  {
    "id": "node/1028724118"
  },
  {
    "id": "node/1217834609"
  },
  {
    "id": "node/1343665331"
  },
  {
    "id": "node/1384368023"
  },
  {
    "id": "node/1607975716"
  }
]
;