import {api} from '../../../store/api';
import {ListOptions, ListRegions, ListSects} from './types';
import {Option} from '../../../types';

export const homeApi = api.injectEndpoints({
    endpoints: (build) => ({
        getSchemas: build.query<Option[], void>({
            query: () => ({
                url: `/listData/listSchemes`,
                method: 'GET'
            }),
            keepUnusedDataFor: 0,
            transformResponse: ({listSchemes}) => {
                return listSchemes.map((i: ListOptions) => ({label: i.Name, value: i.ID_Shema}));
            }
        }),
        getSchemaQuestion: build.query<{ status: boolean; listSects: ListSects[] }, { ID_Shema: string }>({
            query: ({ID_Shema}) => ({
                url: `/listData/listSects?ID_Shema=${ID_Shema}`
            })
        }),
        getNameRegions: build.query<Option[], void>({
            query: () => ({
                url: '/listData/listRegions',
                method: 'GET'
            }),
            transformResponse: ({listRegions}) => {
                return listRegions.map((i: ListRegions) => ({
                    label: i.Name,
                    value: i.ID_Tab
                })).filter((i: Option) => i.label !== '_ТЕСТ');
            }
        }),
    }), overrideExisting: true
});

export const {
    useGetSchemasQuery,
    useLazyGetSchemaQuestionQuery,
    useGetNameRegionsQuery,
} = homeApi;
