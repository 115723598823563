import styled from 'styled-components';

export const RisingWrapper = styled.div`
  height: calc(100vh - 153px);
  overflow-y: auto;
  padding-right: 5px;

  &::-webkit-scrollbar {
    background-color: var(--border-color);
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--title-light-color);
    border-radius: 8px;
  }
`;

export const RisingRowS = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  column-gap: 5px;

`;

export const ColumnRisingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: fit-content;
  position: relative;
  overflow: hidden;
  background-color: #f5f5f5;
  margin: 3px 0;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  padding: 10px 0 0 10px;
`;

export const ColumnBlock = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 10px;
`;

export const ColumnRising = styled.div.withConfig({
    shouldForwardProp: prop => !['color'].includes(prop)
})<{ color: string }>`
  height: 30px;
  background-color: ${({color}) => color};
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  padding-right: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const Value = styled.div`
  font-size: 12px;
  font-weight: 600;
  color: black;
  text-shadow: 2px 2px 0 white,
  -2px 2px 0 white,
    2px -2px 0 white,
    -2px -2px 0 white;
`;

export const DataTextWrap = styled.div`
  z-index: 2;
  display: flex;
  align-items: center;

  & p {
    text-shadow: 1px 1px 0 white,
    -1px 1px 0 white,
      1px -1px 0 white,
      -1px -1px 0 white;
  }
`;
