import styled from 'styled-components';

type FlexProps = {
    flexDirection?: 'row' | 'column';
    alignItems?: 'start' | 'center' | 'end';
    justifyContent?: 'start' | 'center' | 'end';
    gap?: string;
    flexGrow?: boolean;
    hovered?: boolean;
}
export const Flex = styled.div.withConfig({
    shouldForwardProp: prop => !['flexDirection', 'alignItems', 'justifyContent', 'gap', 'hovered'].includes(prop)
})<FlexProps>`
  display: flex;
  flex-direction: ${({flexDirection}) => flexDirection ? flexDirection : 'row'};
  align-items: ${({alignItems}) => alignItems ? alignItems : 'flex-start'};
  justify-content: ${({justifyContent}) => justifyContent ? justifyContent : 'flex-start'};
  gap: ${({gap}) => gap ? gap : '0px'};
  flex-grow: ${({flexGrow}) => flexGrow ? 1 : 0};

  &:hover {
    cursor: ${({hovered}) => hovered ? 'pointer' : 'default'};
  }
`;

export const Wrapper = styled.div`
  display: flex;
  column-gap: 10px;
  flex-grow: 1;
`;

export const SidebarS = styled.div`
  width: 200px;
  border: 1px solid var(--border-color);
  background-color: #ffffff;
  padding: 5px;
  border-radius: 4px;
  min-height: 300px;
  overflow: scroll;
`;


export const Content = styled.div.withConfig({
    shouldForwardProp: prop => !['inner'].includes(prop)
})<{ inner?: boolean }>`
  width: 780px;
  display: flex;
  flex-direction: ${({inner}) => inner ? 'row' : 'column'};
  justify-content: ${({inner}) => inner ? 'space-between' : 'flex-start'};
  flex-grow: 1;
  column-gap: ${({inner}) => inner ? '5px' : '0'};
  border-radius: 8px;
  padding: ${({inner}) => inner ? '2px' : '0'};
  background-color: ${({inner}) => inner ? 'var(--overlay)' : 'transparent'};
`;

export const ContentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--overlay);
  width: 100%;
  padding: 3px 3px 3px 10px;
  border-radius: 4px;
`;

export const Box = styled.div.withConfig({
    shouldForwardProp: prop => ![''].includes(prop)
})`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0 10px 0;

  & path,
  rect,
  text {
    &:hover {
      cursor: pointer;
      background-color: red;
    }
  }
`;

