export const names = [
  {
    "id": "relation/11955188",
    "name": "Старобельский район"
  },
  {
    "id": "relation/11956581",
    "name": "Сватовский район"
  },
  {
    "id": "relation/11957819",
    "name": "Счастьинский район"
  },
  {
    "id": "relation/11968100",
    "name": "Северскодонецкий район"
  },
  {
    "id": "relation/17938099",
    "name": "Должанский район"
  },
  {
    "id": "relation/17938112",
    "name": "Ровеньский район"
  },
  {
    "id": "relation/17938126",
    "name": "Луганский район"
  },
  {
    "id": "relation/17938128",
    "name": "Алчевский район"
  },
  {
    "id": "node/253874196"
  },
  {
    "id": "node/253877159"
  },
  {
    "id": "node/337564337"
  },
  {
    "id": "node/337578362"
  },
  {
    "id": "node/337579368"
  },
  {
    "id": "node/337660247"
  },
  {
    "id": "node/1419442319"
  },
  {
    "id": "node/2135849341"
  }
]
;