export const names = [
  {
    "id": "relation/1059350",
    "name": "Баксанский район"
  },
  {
    "id": "relation/1059355",
    "name": "Зольский район"
  },
  {
    "id": "relation/1059363",
    "name": "Майский район"
  },
  {
    "id": "relation/1059369",
    "name": "Прохладненский район"
  },
  {
    "id": "relation/1059401",
    "name": "Черекский район"
  },
  {
    "id": "relation/1059416",
    "name": "Урванский район"
  },
  {
    "id": "relation/1059419",
    "name": "Чегемский район"
  },
  {
    "id": "relation/1059420",
    "name": "городской округ Нальчик"
  },
  {
    "id": "relation/1059421",
    "name": "Эльбрусский район"
  },
  {
    "id": "relation/1065962",
    "name": "Терский район"
  },
  {
    "id": "relation/1070931",
    "name": "Лескенский район"
  },
  {
    "id": "relation/1070945",
    "name": "городской округ Прохладный"
  },
  {
    "id": "relation/1070946",
    "name": "городской округ Баксан"
  },
  {
    "id": "node/93701882"
  },
  {
    "id": "node/93711720"
  },
  {
    "id": "node/287411141"
  },
  {
    "id": "node/413055139"
  },
  {
    "id": "node/413084059"
  },
  {
    "id": "node/652081328"
  },
  {
    "id": "node/652439062"
  },
  {
    "id": "node/1026705983"
  },
  {
    "id": "node/1028161907"
  },
  {
    "id": "node/1574509870"
  },
  {
    "id": "node/2127195931"
  }
]
;