import {createSlice} from '@reduxjs/toolkit';
import {defaultUserData, profileStore} from './types';
import {authApi} from '../../ayth/store/auth.api';

const initialState: profileStore = {
    data: defaultUserData
};

const profileSlice = createSlice({
    name: 'profile',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addMatcher(
            authApi.endpoints.signIn.matchFulfilled,
            (state, {payload}) => {
                state.data = payload.user;
            }
        );
    }
});

export default profileSlice.reducer;
