export const names = [
  {
    "id": "relation/335494",
    "name": "городской округ Дзержинск"
  },
  {
    "id": "relation/335495",
    "name": "городской округ Нижний Новгород"
  },
  {
    "id": "relation/335496",
    "name": "Балахнинский муниципальный округ"
  },
  {
    "id": "relation/1086956",
    "name": "Володарский муниципальный округ"
  },
  {
    "id": "relation/1086957",
    "name": "Богородский муниципальный округ"
  },
  {
    "id": "relation/1086958",
    "name": "городской округ Чкаловск"
  },
  {
    "id": "relation/1086959",
    "name": "Городецкий муниципальный округ"
  },
  {
    "id": "relation/1086960",
    "name": "Кстовский муниципальный округ"
  },
  {
    "id": "relation/1086961",
    "name": "Дальнеконстантиновский муниципальный округ"
  },
  {
    "id": "relation/1086962",
    "name": "городской округ Бор"
  },
  {
    "id": "relation/1102442",
    "name": "Павловский муниципальный округ"
  },
  {
    "id": "relation/1102445",
    "name": "Сосновский муниципальный округ"
  },
  {
    "id": "relation/1102502",
    "name": "Лысковский муниципальный округ"
  },
  {
    "id": "relation/1102503",
    "name": "Воротынский городской округ"
  },
  {
    "id": "relation/1110098",
    "name": "Ардатовский муниципальный округ"
  },
  {
    "id": "relation/1110099",
    "name": "Вачский муниципальный округ"
  },
  {
    "id": "relation/1111192",
    "name": "городской округ Выкса"
  },
  {
    "id": "relation/1111193",
    "name": "городской округ Кулебаки"
  },
  {
    "id": "relation/1111194",
    "name": "Навашинский городской округ"
  },
  {
    "id": "relation/1111195",
    "name": "Дивеевский муниципальный округ"
  },
  {
    "id": "relation/1111196",
    "name": "Вознесенский муниципальный округ"
  },
  {
    "id": "relation/1130661",
    "name": "городской округ Арзамас"
  },
  {
    "id": "relation/1157417",
    "name": "Ветлужский муниципальный округ"
  },
  {
    "id": "relation/1157728",
    "name": "Тоншаевский муниципальный округ"
  },
  {
    "id": "relation/1158162",
    "name": "городской округ Шахунья"
  },
  {
    "id": "relation/1159289",
    "name": "Сокольский городской округ"
  },
  {
    "id": "relation/1159363",
    "name": "Ковернинский муниципальный округ"
  },
  {
    "id": "relation/1159499",
    "name": "Варнавинский муниципальный округ"
  },
  {
    "id": "relation/1159549",
    "name": "Уренский муниципальный округ"
  },
  {
    "id": "relation/1159598",
    "name": "Тонкинский муниципальный округ"
  },
  {
    "id": "relation/1161648",
    "name": "Перевозский городской округ"
  },
  {
    "id": "relation/1161649",
    "name": "Спасский муниципальный округ"
  },
  {
    "id": "relation/1161650",
    "name": "Бутурлинский муниципальный округ"
  },
  {
    "id": "relation/1161651",
    "name": "Большемурашкинский муниципальный округ"
  },
  {
    "id": "relation/1161652",
    "name": "Сергачский муниципальный округ"
  },
  {
    "id": "relation/1161653",
    "name": "Пильнинский муниципальный округ"
  },
  {
    "id": "relation/1161654",
    "name": "Княгининский муниципальный округ"
  },
  {
    "id": "relation/1161655",
    "name": "Вадский муниципальный округ"
  },
  {
    "id": "relation/1164831",
    "name": "Шарангский муниципальный округ"
  },
  {
    "id": "relation/1164885",
    "name": "Воскресенский муниципальный округ"
  },
  {
    "id": "relation/1165446",
    "name": "Краснобаковский муниципальный округ"
  },
  {
    "id": "relation/1165477",
    "name": "Семёновский городской округ"
  },
  {
    "id": "relation/1165778",
    "name": "Сеченовский муниципальный округ"
  },
  {
    "id": "relation/1166765",
    "name": "Большеболдинский муниципальный округ"
  },
  {
    "id": "relation/1166766",
    "name": "Краснооктябрьский муниципальный округ"
  },
  {
    "id": "relation/1168240",
    "name": "Починковский муниципальный округ"
  },
  {
    "id": "relation/1170923",
    "name": "городской округ Первомайск"
  },
  {
    "id": "relation/1171137",
    "name": "Шатковский муниципальный округ"
  },
  {
    "id": "relation/1171345",
    "name": "Лукояновский муниципальный округ"
  },
  {
    "id": "relation/1171346",
    "name": "Гагинский муниципальный округ"
  },
  {
    "id": "relation/2031968",
    "name": "городской округ Саров"
  },
  {
    "id": "node/27505889"
  },
  {
    "id": "node/191761177"
  },
  {
    "id": "node/191902906"
  },
  {
    "id": "node/264351243"
  },
  {
    "id": "node/264365777"
  },
  {
    "id": "node/264366303"
  },
  {
    "id": "node/264367550"
  },
  {
    "id": "node/265727566"
  },
  {
    "id": "node/266213137"
  },
  {
    "id": "node/266213444"
  },
  {
    "id": "node/292879578"
  },
  {
    "id": "node/303016392"
  },
  {
    "id": "node/303017781"
  },
  {
    "id": "node/336520767"
  },
  {
    "id": "node/336520802"
  },
  {
    "id": "node/336520819"
  },
  {
    "id": "node/336520825"
  },
  {
    "id": "node/336520880"
  },
  {
    "id": "node/336521252"
  },
  {
    "id": "node/336521564"
  },
  {
    "id": "node/336521599"
  },
  {
    "id": "node/336521663"
  },
  {
    "id": "node/336523799"
  },
  {
    "id": "node/336524307"
  },
  {
    "id": "node/336524356"
  },
  {
    "id": "node/353177210"
  },
  {
    "id": "node/356482851"
  },
  {
    "id": "node/356482960"
  },
  {
    "id": "node/356482977"
  },
  {
    "id": "node/356483013"
  },
  {
    "id": "node/356483058"
  },
  {
    "id": "node/360701733"
  },
  {
    "id": "node/382475256"
  },
  {
    "id": "node/382475272"
  },
  {
    "id": "node/448923162"
  },
  {
    "id": "node/484806132"
  },
  {
    "id": "node/487085839"
  },
  {
    "id": "node/487090169"
  },
  {
    "id": "node/506348948"
  },
  {
    "id": "node/540536570"
  },
  {
    "id": "node/670493290"
  },
  {
    "id": "node/887652602"
  },
  {
    "id": "node/887669031"
  },
  {
    "id": "node/887756869"
  },
  {
    "id": "node/887757565"
  },
  {
    "id": "node/888048330"
  },
  {
    "id": "node/888411296"
  },
  {
    "id": "node/888428997"
  },
  {
    "id": "node/899653374"
  },
  {
    "id": "node/906098216"
  },
  {
    "id": "node/2533472682"
  }
]
;