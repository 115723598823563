import {api} from '../../../store/api';
import {RegionData, RegionDataRes, RegionObject} from './types';
import {findMinMaxValue} from './helper';

export const regionMapApi = api.injectEndpoints({
    endpoints: (build) => ({
        getRegionsParams: build.query<RegionDataRes, { params: string }>({
            query: ({params}) => ({
                url: `/reports/kbrParamValues?${params}`
            }),
            transformResponse: ({response}) => {
                const totalValues = response.map((i: RegionData) => i?.Value).reduce((acc: number, i: number) => acc + i, 0);
                const {min, max} = findMinMaxValue(response);
                return {data: response, totalValues, min, max};
            }
        }),
        getRegionObjectList: build.query<RegionObject[], void>({
            query: () => ({
                url: '/listData/listObjects',
                method: 'GET'
            }),
            keepUnusedDataFor: 0,
            transformResponse: ({listObjects}) => {
                return listObjects;
            }
        })
    }), overrideExisting: true
});

export const {
    useGetRegionsParamsQuery,
    useGetRegionObjectListQuery
} = regionMapApi;
