export const names = [
  {
    "id": "relation/1020356",
    "name": "Кондопожский район"
  },
  {
    "id": "relation/1020357",
    "name": "Прионежский район"
  },
  {
    "id": "relation/1020358",
    "name": "Петрозаводский городской округ"
  },
  {
    "id": "relation/1020359",
    "name": "Кемский район"
  },
  {
    "id": "relation/1020360",
    "name": "Беломорский муниципальный округ"
  },
  {
    "id": "relation/1020361",
    "name": "Питкярантский муниципальный округ"
  },
  {
    "id": "relation/1020362",
    "name": "Олонецкий район"
  },
  {
    "id": "relation/1020363",
    "name": "Лахденпохский район"
  },
  {
    "id": "relation/1020364",
    "name": "Пудожский район"
  },
  {
    "id": "relation/1020365",
    "name": "Сегежский муниципальный округ"
  },
  {
    "id": "relation/1020366",
    "name": "Костомукшский городской округ"
  },
  {
    "id": "relation/1020367",
    "name": "Пряжинский район"
  },
  {
    "id": "relation/1020368",
    "name": "Калевальский район"
  },
  {
    "id": "relation/1020369",
    "name": "Лоухский район"
  },
  {
    "id": "relation/1020370",
    "name": "Сортавальский район"
  },
  {
    "id": "relation/1020371",
    "name": "Суоярвский муниципальный округ"
  },
  {
    "id": "relation/1020372",
    "name": "Медвежьегорский район"
  },
  {
    "id": "relation/1020373",
    "name": "Муезерский район"
  },
  {
    "id": "node/26898768"
  },
  {
    "id": "node/26898825"
  },
  {
    "id": "node/26903504"
  },
  {
    "id": "node/26921263"
  },
  {
    "id": "node/244130101"
  },
  {
    "id": "node/247216329"
  },
  {
    "id": "node/276627958"
  },
  {
    "id": "node/309493556"
  },
  {
    "id": "node/309773771"
  },
  {
    "id": "node/309965794"
  },
  {
    "id": "node/347359894"
  },
  {
    "id": "node/387417700"
  },
  {
    "id": "node/1828409507"
  },
  {
    "id": "node/1839536171"
  },
  {
    "id": "node/1858199523"
  },
  {
    "id": "node/1858201567"
  },
  {
    "id": "node/2078883019"
  }
]
;