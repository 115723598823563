export const names = [
  {
    "id": "relation/386599",
    "name": "городской округ Козьмодемьянск"
  },
  {
    "id": "relation/900970",
    "name": "Волжский район"
  },
  {
    "id": "relation/901019",
    "name": "Звениговский район"
  },
  {
    "id": "relation/901020",
    "name": "Моркинский район"
  },
  {
    "id": "relation/901028",
    "name": "Мари-Турекский район"
  },
  {
    "id": "relation/901029",
    "name": "Параньгинский район"
  },
  {
    "id": "relation/917774",
    "name": "Сернурский район"
  },
  {
    "id": "relation/917832",
    "name": "Новоторъяльский район"
  },
  {
    "id": "relation/917833",
    "name": "Куженерский район"
  },
  {
    "id": "relation/917834",
    "name": "Советский район"
  },
  {
    "id": "relation/917837",
    "name": "Оршанский район"
  },
  {
    "id": "relation/917852",
    "name": "Медведевский район"
  },
  {
    "id": "relation/921546",
    "name": "Горномарийский район"
  },
  {
    "id": "relation/921558",
    "name": "Юринский район"
  },
  {
    "id": "relation/921563",
    "name": "Килемарский район"
  },
  {
    "id": "relation/1662421",
    "name": "городской округ Йошкар-Ола"
  },
  {
    "id": "relation/2632442",
    "name": "городской округ Волжск"
  },
  {
    "id": "node/191640862"
  },
  {
    "id": "node/324648017"
  },
  {
    "id": "node/336523790"
  },
  {
    "id": "node/336523845"
  },
  {
    "id": "node/378731567"
  },
  {
    "id": "node/378731831"
  },
  {
    "id": "node/598156536"
  },
  {
    "id": "node/604388758"
  },
  {
    "id": "node/701760403"
  },
  {
    "id": "node/701808009"
  },
  {
    "id": "node/701808010"
  },
  {
    "id": "node/703076601"
  },
  {
    "id": "node/737168420"
  },
  {
    "id": "node/738194171"
  },
  {
    "id": "node/1741212632"
  }
]
;