import React, {useEffect} from 'react';
import {useAppDispatch, useAppSelector} from '../hooks';
import {Navigate, Outlet, useLocation} from 'react-router-dom';
import {MainContainer} from '../layouts';
import {Header} from '../components/header';
import {BreadCrumbs} from '../components/breadCrumbs';
import {Wrapper} from '../layouts/template';
import {changeBreadCrumbs, defaultBreadCrumbs} from '../screens/home/store/home.slice';

export const Private = () => {
    const {pathname} = useLocation();
    const dispatch = useAppDispatch();
    const {isAuth} = useAppSelector(state => state.auth);
    const {breadCrumbs} = useAppSelector(state => state.home);

    useEffect(() => {
        const path = pathname.split('?')[0];
        const currentPage = breadCrumbs.find(i => i.path.includes(path));
        if (currentPage) {
            const indexOf = breadCrumbs.findIndex(i => i.path.includes(path));
            const updatedBreadCrumbs = breadCrumbs.slice(0, indexOf + 1);
            dispatch(changeBreadCrumbs(updatedBreadCrumbs));
        } else {
            dispatch(changeBreadCrumbs([defaultBreadCrumbs]));
        }
    }, [pathname]);

    return isAuth ? (
        <MainContainer>
            <Header/>
            <BreadCrumbs/>
            <Wrapper>
                <Outlet/>
            </Wrapper>

        </MainContainer>
    ) : (
        <Navigate to={'/login'}/>
    );
};
