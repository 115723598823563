export const names = [
  {
    "id": "relation/1113196",
    "name": "Грязинский район"
  },
  {
    "id": "relation/1113569",
    "name": "Воловский муниципальный округ"
  },
  {
    "id": "relation/1113578",
    "name": "Тербунский район"
  },
  {
    "id": "relation/1113581",
    "name": "Добринский район"
  },
  {
    "id": "relation/1113711",
    "name": "Липецкий район"
  },
  {
    "id": "relation/1113768",
    "name": "Усманский район"
  },
  {
    "id": "relation/1113771",
    "name": "Добровский муниципальный округ"
  },
  {
    "id": "relation/1114800",
    "name": "Чаплыгинский район"
  },
  {
    "id": "relation/1114801",
    "name": "Лев-Толстовский район"
  },
  {
    "id": "relation/1114805",
    "name": "Данковский район"
  },
  {
    "id": "relation/1114884",
    "name": "Лебедянский район"
  },
  {
    "id": "relation/1114903",
    "name": "Краснинский район"
  },
  {
    "id": "relation/1114910",
    "name": "Задонский район"
  },
  {
    "id": "relation/1114911",
    "name": "Хлевенский район"
  },
  {
    "id": "relation/1116513",
    "name": "Елецкий район"
  },
  {
    "id": "relation/1116708",
    "name": "Долгоруковский район"
  },
  {
    "id": "relation/1116714",
    "name": "Измалковский муниципальный округ"
  },
  {
    "id": "relation/1116715",
    "name": "Становлянский муниципальный округ"
  },
  {
    "id": "relation/1273067",
    "name": "городской округ Липецк"
  },
  {
    "id": "relation/3515980",
    "name": "городской округ Елец"
  },
  {
    "id": "node/76611479"
  },
  {
    "id": "node/191891797"
  },
  {
    "id": "node/332353039"
  },
  {
    "id": "node/334723509"
  },
  {
    "id": "node/334723512"
  },
  {
    "id": "node/334723521"
  },
  {
    "id": "node/334723568"
  },
  {
    "id": "node/334723573"
  },
  {
    "id": "node/336140276"
  },
  {
    "id": "node/336140320"
  },
  {
    "id": "node/336140371"
  },
  {
    "id": "node/336140373"
  },
  {
    "id": "node/336140376"
  },
  {
    "id": "node/336140415"
  },
  {
    "id": "node/620395257"
  },
  {
    "id": "node/710667288"
  },
  {
    "id": "node/1587170300"
  },
  {
    "id": "node/2591848730"
  }
]
;