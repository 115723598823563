import React from 'react';
import {BreadCrumbsItem, BreadCrumbsRow} from './styled';
import {useAppSelector} from '../../hooks';

export const BreadCrumbs = () => {
    const {breadCrumbs} = useAppSelector(state => state.home);

    return (
        <BreadCrumbsRow>
            {breadCrumbs.map((item, index) =>
                <BreadCrumbsItem
                    key={index}
                    to={item.path}>
                    {item.name}
                </BreadCrumbsItem>)}
        </BreadCrumbsRow>
    );
};
