import React from 'react';

export const Info = () => {
    return (
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12.25 5.75C12.25 5.05965 11.6903 4.5 11 4.5C10.3097 4.5 9.75 5.05965 9.75 5.75C9.75 6.44035 10.3097 7 11 7C11.6903 7 12.25 6.44035 12.25 5.75Z"
                fill={'rgb(32, 89, 152)'} fillOpacity="0.85"/>
            <path
                d="M9.25 9C9.25 8.44771 9.69771 8 10.25 8H11.25C11.8023 8 12.25 8.44771 12.25 9V15H13C13.5523 15 14 15.4477 14 16C14 16.5523 13.5523 17 13 17H9.5C8.94771 17 8.5 16.5523 8.5 16C8.5 15.4477 8.94771 15 9.5 15H10.25V10C9.69771 10 9.25 9.55229 9.25 9Z"
                fill={'rgb(32, 89, 152)'} fillOpacity="0.85"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M11 0C7.96273 0 5.21136 1.23232 3.22183 3.22182C1.23233 5.21135 0 7.96273 0 11C0 14.0373 1.23231 16.7886 3.22182 18.7782C5.21135 20.7677 7.96273 22 11 22C14.0373 22 16.7886 20.7677 18.7782 18.7782C20.7677 16.7886 22 14.0373 22 11C22 7.96273 20.7677 5.21136 18.7782 3.22183C16.7886 1.23232 14.0373 0 11 0ZM4.63603 4.63604C6.26584 3.00626 8.51447 2 11 2C13.4855 2 15.7342 3.00626 17.3639 4.63604C18.9937 6.26584 20 8.51447 20 11C20 13.4855 18.9937 15.7342 17.3639 17.3639C15.7342 18.9937 13.4855 20 11 20C8.51447 20 6.26584 18.9937 4.63604 17.3639C3.00626 15.7342 2 13.4855 2 11C2 8.51447 3.00625 6.26585 4.63603 4.63604Z"
                  fill={'rgb(32, 89, 152)'} fillOpacity="0.85"/>
        </svg>

    );
};
