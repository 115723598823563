import React, {useRef} from 'react';
import {RU} from '../../components/map/mapLayouts/RU';
import {RegionMapS} from '../../components/map/styled';
import {HEADER_HEIGHT} from '../../utils/constants';

export const Login = () => {
    const svgRef = useRef<SVGSVGElement>(null);
    return (
        <RegionMapS height={window.innerHeight - (HEADER_HEIGHT * 2)}>
            <RU ref={svgRef}/>
        </RegionMapS>

    );
};

