import React from 'react';
import styled, {keyframes} from 'styled-components';

type LoaderProps = {
    type: 'small' | 'large'
}

export const Loader = ({type}: LoaderProps) => {

    if (type === 'small') {
        return (
            <InfinitySmallLoader/>
        );
    }

    return (
        <LoaderWrapper>
            <InfinityLoader/>
            <Overlay/>
        </LoaderWrapper>
    );
};

const LoaderWrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
`;

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.55);
  position: absolute;
  z-index: 1;
`;

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const InfinityLoader = styled.div`
  width: 50px;
  height: 50px;
  border: 6px solid #f3f3f3;
  border-top: 6px solid #3498db;
  border-radius: 50%;
  animation: ${spin} 1s linear infinite;
  position: relative;
  z-index: 2;
`;

const InfinitySmallLoader = styled.span`
  width: 10px;
  height: 10px;
  border: 1px solid #ffffff;
  border-top: 1px solid #3498db;
  border-radius: 50%;
  animation: ${spin} 1s linear infinite;
  position: relative;
  z-index: 2;
  display: inline-block;
  margin: 0 auto;
`;
