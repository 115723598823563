export const names = [
  {
    "id": "relation/331414",
    "name": "городской округ Калининград"
  },
  {
    "id": "relation/1179566",
    "name": "Краснознаменский муниципальный округ"
  },
  {
    "id": "relation/1179573",
    "name": "Нестеровский муниципальный округ"
  },
  {
    "id": "relation/1179580",
    "name": "Озёрский муниципальный округ"
  },
  {
    "id": "relation/1179589",
    "name": "Неманский муниципальный округ"
  },
  {
    "id": "relation/1179598",
    "name": "Советский городской округ"
  },
  {
    "id": "relation/1179608",
    "name": "Черняховский муниципальный округ"
  },
  {
    "id": "relation/1179611",
    "name": "Правдинский муниципальный округ"
  },
  {
    "id": "relation/1179626",
    "name": "Славский муниципальный округ"
  },
  {
    "id": "relation/1179633",
    "name": "Полесский муниципальный округ"
  },
  {
    "id": "relation/1179641",
    "name": "Гвардейский муниципальный округ"
  },
  {
    "id": "relation/1179697",
    "name": "Гурьевский муниципальный округ"
  },
  {
    "id": "relation/1179704",
    "name": "Багратионовский муниципальный округ"
  },
  {
    "id": "relation/1179705",
    "name": "Мамоновский городской округ"
  },
  {
    "id": "relation/1179711",
    "name": "Балтийский городской округ"
  },
  {
    "id": "relation/1179722",
    "name": "Зеленоградский муниципальный округ"
  },
  {
    "id": "relation/1179723",
    "name": "Светлогорский городской округ"
  },
  {
    "id": "relation/1179724",
    "name": "Ладушкинский городской округ"
  },
  {
    "id": "relation/1179725",
    "name": "Пионерский городской округ"
  },
  {
    "id": "relation/1179727",
    "name": "Светловский городской округ"
  },
  {
    "id": "relation/1179728",
    "name": "Янтарный городской округ"
  },
  {
    "id": "relation/1736274",
    "name": "Гусевский городской округ"
  },
  {
    "id": "node/27048976"
  },
  {
    "id": "node/27048978"
  },
  {
    "id": "node/60237699"
  },
  {
    "id": "node/60237889"
  },
  {
    "id": "node/60238036"
  },
  {
    "id": "node/60238153"
  },
  {
    "id": "node/60238387"
  },
  {
    "id": "node/60238408"
  },
  {
    "id": "node/60238585"
  },
  {
    "id": "node/255910496"
  },
  {
    "id": "node/261008814"
  },
  {
    "id": "node/266714836"
  },
  {
    "id": "node/301246150"
  },
  {
    "id": "node/304337561"
  },
  {
    "id": "node/394996639"
  },
  {
    "id": "node/478808135"
  },
  {
    "id": "node/767068249"
  },
  {
    "id": "node/914387646"
  },
  {
    "id": "node/1420696955"
  },
  {
    "id": "node/1681538643"
  },
  {
    "id": "node/1855447909"
  },
  {
    "id": "node/5356827947"
  }
]
;