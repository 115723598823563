export const names = [
  {
    "id": "relation/954159",
    "name": "Каракулинский район"
  },
  {
    "id": "relation/954181",
    "name": "Камбарский район"
  },
  {
    "id": "relation/954515",
    "name": "городской округ Ижевск"
  },
  {
    "id": "relation/956043",
    "name": "Киясовский район"
  },
  {
    "id": "relation/956078",
    "name": "Сарапульский район"
  },
  {
    "id": "relation/956084",
    "name": "Малопургинский район"
  },
  {
    "id": "relation/956089",
    "name": "Завьяловский район"
  },
  {
    "id": "relation/956093",
    "name": "Алнашский район"
  },
  {
    "id": "relation/956094",
    "name": "Граховский район"
  },
  {
    "id": "relation/956100",
    "name": "Кизнерский район"
  },
  {
    "id": "relation/956104",
    "name": "Можгинский район"
  },
  {
    "id": "relation/956106",
    "name": "Вавожский район"
  },
  {
    "id": "relation/956108",
    "name": "Увинский район"
  },
  {
    "id": "relation/956109",
    "name": "Сюмсинский район"
  },
  {
    "id": "relation/956111",
    "name": "Селтинский район"
  },
  {
    "id": "relation/956114",
    "name": "Воткинский район"
  },
  {
    "id": "relation/956115",
    "name": "Якшур-Бодьинский район"
  },
  {
    "id": "relation/956118",
    "name": "Шарканский район"
  },
  {
    "id": "relation/956124",
    "name": "Дебёсский район"
  },
  {
    "id": "relation/956127",
    "name": "Игринский район"
  },
  {
    "id": "relation/956130",
    "name": "Красногорский район"
  },
  {
    "id": "relation/956132",
    "name": "Юкаменский район"
  },
  {
    "id": "relation/956133",
    "name": "Ярский район"
  },
  {
    "id": "relation/956135",
    "name": "Кезский район"
  },
  {
    "id": "relation/956136",
    "name": "Балезинский район"
  },
  {
    "id": "relation/956137",
    "name": "Глазовский район"
  },
  {
    "id": "relation/1027529",
    "name": "городской округ Воткинск"
  },
  {
    "id": "relation/1078857",
    "name": "городской округ Можга"
  },
  {
    "id": "relation/1105125",
    "name": "городской округ Сарапул"
  },
  {
    "id": "relation/1186575",
    "name": "городской округ Глазов"
  },
  {
    "id": "node/79736088"
  },
  {
    "id": "node/255594586"
  },
  {
    "id": "node/255954981"
  },
  {
    "id": "node/260964928"
  },
  {
    "id": "node/272616739"
  },
  {
    "id": "node/287050506"
  },
  {
    "id": "node/288089948"
  },
  {
    "id": "node/296120086"
  },
  {
    "id": "node/296130162"
  },
  {
    "id": "node/331569994"
  },
  {
    "id": "node/403305470"
  },
  {
    "id": "node/621090917"
  },
  {
    "id": "node/647804891"
  },
  {
    "id": "node/647804925"
  },
  {
    "id": "node/768275072"
  },
  {
    "id": "node/768275075"
  },
  {
    "id": "node/768275077"
  },
  {
    "id": "node/768275079"
  },
  {
    "id": "node/768275083"
  },
  {
    "id": "node/768275085"
  },
  {
    "id": "node/768275089"
  },
  {
    "id": "node/768275090"
  },
  {
    "id": "node/768275091"
  },
  {
    "id": "node/920525617"
  },
  {
    "id": "node/1679639726"
  },
  {
    "id": "node/2337276352"
  },
  {
    "id": "node/4043325260"
  },
  {
    "id": "node/10573768280"
  }
]
;