export const names = [
  {
    "id": "relation/939688",
    "name": "Краснозёрский район"
  },
  {
    "id": "relation/939765",
    "name": "Карасукский район"
  },
  {
    "id": "relation/939766",
    "name": "Баганский район"
  },
  {
    "id": "relation/939767",
    "name": "Купинский район"
  },
  {
    "id": "relation/960081",
    "name": "Сузунский район"
  },
  {
    "id": "relation/960082",
    "name": "Ордынский район"
  },
  {
    "id": "relation/960083",
    "name": "Черепановский район"
  },
  {
    "id": "relation/960084",
    "name": "Кочковский район"
  },
  {
    "id": "relation/960828",
    "name": "Искитимский район"
  },
  {
    "id": "relation/960829",
    "name": "Маслянинский район"
  },
  {
    "id": "relation/960830",
    "name": "Болотнинский район"
  },
  {
    "id": "relation/960831",
    "name": "Тогучинский район"
  },
  {
    "id": "relation/1070660",
    "name": "Венгеровский район"
  },
  {
    "id": "relation/1070661",
    "name": "Усть-Таркский район"
  },
  {
    "id": "relation/1070662",
    "name": "Чистоозёрный район"
  },
  {
    "id": "relation/1070663",
    "name": "Татарский район"
  },
  {
    "id": "relation/1070664",
    "name": "Кыштовский район"
  },
  {
    "id": "relation/1070665",
    "name": "Чановский район"
  },
  {
    "id": "relation/1070666",
    "name": "Северный район"
  },
  {
    "id": "relation/1070667",
    "name": "Куйбышевский район"
  },
  {
    "id": "relation/1074003",
    "name": "Новосибирский район"
  },
  {
    "id": "relation/1074004",
    "name": "Барабинский район"
  },
  {
    "id": "relation/1074005",
    "name": "Колыванский район"
  },
  {
    "id": "relation/1074006",
    "name": "Каргатский район"
  },
  {
    "id": "relation/1074007",
    "name": "Мошковский район"
  },
  {
    "id": "relation/1074008",
    "name": "Здвинский район"
  },
  {
    "id": "relation/1074009",
    "name": "Доволенский район"
  },
  {
    "id": "relation/1074010",
    "name": "Чулымский район"
  },
  {
    "id": "relation/1074011",
    "name": "Коченёвский район"
  },
  {
    "id": "relation/1074012",
    "name": "Убинский район"
  },
  {
    "id": "relation/1307315",
    "name": "городской округ Бердск"
  },
  {
    "id": "relation/1701916",
    "name": "городской округ Обь"
  },
  {
    "id": "relation/1701956",
    "name": "городской округ Кольцово"
  },
  {
    "id": "relation/1751445",
    "name": "городской округ Новосибирск"
  },
  {
    "id": "relation/3396079",
    "name": "городской округ Искитим"
  },
  {
    "id": "node/54"
  },
  {
    "id": "node/278023046"
  },
  {
    "id": "node/278991732"
  },
  {
    "id": "node/280304251"
  },
  {
    "id": "node/280352393"
  },
  {
    "id": "node/280352878"
  },
  {
    "id": "node/281427282"
  },
  {
    "id": "node/281431442"
  },
  {
    "id": "node/281431605"
  },
  {
    "id": "node/281431826"
  },
  {
    "id": "node/281433268"
  },
  {
    "id": "node/281433500"
  },
  {
    "id": "node/282914618"
  },
  {
    "id": "node/290215681"
  },
  {
    "id": "node/291860771"
  },
  {
    "id": "node/293591177"
  },
  {
    "id": "node/312701724"
  },
  {
    "id": "node/378946490"
  },
  {
    "id": "node/378946548"
  },
  {
    "id": "node/378946571"
  },
  {
    "id": "node/442693826"
  },
  {
    "id": "node/660581639"
  },
  {
    "id": "node/663979337"
  },
  {
    "id": "node/663979358"
  },
  {
    "id": "node/667953317"
  },
  {
    "id": "node/672612000"
  },
  {
    "id": "node/911959278"
  },
  {
    "id": "node/929414144"
  },
  {
    "id": "node/953665409"
  },
  {
    "id": "node/958281766"
  },
  {
    "id": "node/1156528460"
  },
  {
    "id": "node/1282776137"
  },
  {
    "id": "node/2713736090"
  }
]
;