import React, {useEffect} from 'react';
import {useAppDispatch, useAppSelector, useCreateParams} from '../../hooks';
import {RenderMap} from '../../components/map';
import {Content, ContentHeader} from '../../layouts/template';
import {TextS} from '../../fields';
import {BoxMap} from '../home/styles';
import {changeSubjectRegionName} from './store/regionMap.slice';
import {groupByName} from '../../helpers';
import {SelectSects} from '../../components/boxSelectSects';
import {useNavigate, useParams} from 'react-router-dom';
import {useGetRegionObjectListQuery, useGetRegionsParamsQuery} from './store/regionMap.api';
import {Chart} from '../../components/chart';
import {toast} from 'react-toastify';
import {DataColumn} from '../../components/dataColumn';
import {UseSvgHookOnClick} from '../../hooks/useSvgInteractive';
import {changeBreadCrumbs} from '../home/store/home.slice';
import {Sidebar} from '../../components/sidebar';

export const RegionMap = () => {
    const {regionId} = useParams<{ regionId: string }>();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const {regionName, breadCrumbs} = useAppSelector(state => state.home);
    const {subjectRegionName, regions, selectedGroupIndex} = useAppSelector(state => state.regionMap);
    const {params} = useCreateParams();

    const {error} = useGetRegionsParamsQuery({params}, {skip: params === '', refetchOnMountOrArgChange: true});
    const {data} = useGetRegionObjectListQuery();


    useEffect(() => {
        if (error) {
            const message = (error as any).data?.message;
            toast.error(message, {
                autoClose: false
            });
        }
    }, [error]);

    const handleRegionName = (name: string) => {
        dispatch(changeSubjectRegionName(name));
    };
    const handleRegionId = ({name}: UseSvgHookOnClick) => {
        dispatch(changeBreadCrumbs([...breadCrumbs, {path: `/region-info/${regionId}?regionName=${name}`, name}]));
        navigate(`/region-info/${regionId}?regionName=${name}`);
    };

    const group = groupByName(regions?.data ?? []).map(i => i.items.flatMap(i => ({
        value: Number(i.Value.toFixed()),
        year: i.Year
    })));

    return (
        <>
            <Sidebar/>
            <Content>
                <ContentHeader>
                    <TextS
                        as={'span'}
                        fontSize={0.8}
                        fontWeight={600}
                        color={'var(--text-color)'}>{regionName} / {subjectRegionName}</TextS>
                    {/*<SelectS*/}
                    {/*    showSearch*/}
                    {/*    placeholder={'Регионы'}*/}
                    {/*    tooltip={'Выбрать регион из списка'}*/}
                    {/*    options={listRegions}*/}
                    {/*    optionFilterProp="children"*/}
                    {/*    filterOption={(input, option) => (option?.label.toUpperCase() ?? '').includes(input.toUpperCase())}*/}
                    {/*/>*/}
                </ContentHeader>
                <BoxMap>
                    <SelectSects/>
                    <RenderMap
                        regionId={`relation/${regionId}` ?? ''}
                        onlyRu={false}
                        onHover={handleRegionName}
                        onClick={handleRegionId}/>
                    {group.length > 0
                        ? <Chart
                            width={740}
                            isMultiple={true}
                            data={selectedGroupIndex !== null ? [group[selectedGroupIndex]] : group}/>
                        : null}
                </BoxMap>
            </Content>
            <DataColumn/>
        </>
    );
};
