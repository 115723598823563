import styled from 'styled-components';

export const HeaderS = styled.header`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--light-color);
  padding-right: 20px;
`;
