import React, {useState} from 'react';
import {FormS, MenuDropS, MenuS, Navigate, NavItem} from './styled';
import {InputS, SpaceS, TextS} from '../../fields';
import {useAppDispatch, useAppSelector} from '../../hooks';
import {Modal} from '../modal';
import {useForm} from 'react-hook-form';
import CryptoJS from 'crypto-js';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {ButtonS} from '../../fields/ButtonS';
import {SignForm} from '../../screens/ayth/store/types';
import {useSignInMutation} from '../../screens/ayth/store/auth.api';
import {Loader} from '../loader';
import {NavLink} from 'react-router-dom';
import {logout} from '../../screens/ayth/store/auth.slice';

const schema = yup.object().shape({
    login: yup.string().required('Поле обязательно для заполнения'),
    pwd: yup.string().required('Поле обязательно для заполнения')
});

export const Menu = () => {
    const dispatch = useAppDispatch();
    const {isAuth} = useAppSelector(state => state.auth);
    const {data} = useAppSelector(state => state.profile);
    const [open, setOpen] = useState<boolean>(false);
    const [visibleDrop, setVisibleDrop] = useState<boolean>(false);

    const [signIn, {isLoading}] = useSignInMutation();

    const {register, handleSubmit, formState: {errors}} = useForm<SignForm>({
        resolver: yupResolver(schema)
    });

    const onSubmit = async (values: SignForm) => {
        const md5Hash = CryptoJS.MD5(values.pwd).toString().toUpperCase();
        setOpen(false);
        signIn({login: values.login, pwd: md5Hash})
            .unwrap()
            .then(({status}) => {
                if (!status) {
                    setOpen(true);
                }
            })
            .catch(() => setOpen(true));
    };

    const handleLogout = () => {
        dispatch(logout());
    };

    if (!isAuth) {
        return (
            <>
                {isLoading ? <Loader type={'large'}/> : null}
                <MenuS onClick={() => setOpen(true)}>
                    <TextS
                        as={'span'}
                        color={'var(--text-color)'}
                        fontSize={1}
                        fontWeight={600}
                        tooltip={'Вход в аккаунт'}
                    >Аккаунт</TextS>
                </MenuS>
                <Modal open={open} onClose={() => setOpen(false)}>
                    <FormS onSubmit={handleSubmit(onSubmit)}>
                        <InputS
                            {...register('login')}
                            isError={errors?.login !== undefined}
                            placeholder={'логин'}/>
                        {errors?.login &&
                            <TextS as={'span'} fontSize={0.5} fontWeight={300}
                                   color={'var(--alert-color)'}>{errors.login.message}</TextS>}
                        <SpaceS height={10}/>
                        <InputS
                            {...register('pwd')}
                            isError={errors?.pwd !== undefined}
                            type={'password'}
                            placeholder={'пароль'}/>
                        {errors?.pwd &&
                            <TextS as={'span'} fontSize={0.5} fontWeight={300}
                                   color={'var(--alert-color)'}>{errors.pwd.message}</TextS>}
                        <SpaceS height={10}/>
                        <ButtonS
                            width={'100%'}
                            color={'#ffffff'}
                            type={'submit'}
                        >Вход</ButtonS>
                    </FormS>

                </Modal>
            </>
        );
    }
    return (
        <MenuS
            onMouseEnter={() => setVisibleDrop(true)}
            onMouseLeave={() => setVisibleDrop(false)}
        >
            <TextS
                as={'span'}
                color={'var(--text-color)'}
                fontSize={1}
                fontWeight={600}
            >{data.name}</TextS>
            <MenuDropS visible={visibleDrop}>
                <Navigate>
                    <NavItem as={NavLink} to={'/profile'}>Профиль</NavItem>
                    <NavItem as={'div'} onClick={handleLogout}>Выход</NavItem>
                </Navigate>
            </MenuDropS>
        </MenuS>
    );
};
