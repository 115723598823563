export const names = [
  {
    "id": "relation/189679",
    "name": "Катангский район"
  },
  {
    "id": "relation/190095",
    "name": "Усть-Илимский район"
  },
  {
    "id": "relation/190097",
    "name": "Чунский район"
  },
  {
    "id": "relation/190098",
    "name": "Тайшетский район"
  },
  {
    "id": "relation/190110",
    "name": "Нижнеудинский район"
  },
  {
    "id": "relation/190128",
    "name": "Тулунский район"
  },
  {
    "id": "relation/190136",
    "name": "Заларинский район"
  },
  {
    "id": "relation/1104970",
    "name": "Слюдянский район"
  },
  {
    "id": "relation/1430613",
    "name": "городской округ Иркутск"
  },
  {
    "id": "relation/1443759",
    "name": "Бодайбинский район"
  },
  {
    "id": "relation/1445620",
    "name": "Мамско-Чуйский район"
  },
  {
    "id": "relation/1454435",
    "name": "Киренский район"
  },
  {
    "id": "relation/1454640",
    "name": "Казачинско-Ленский район"
  },
  {
    "id": "relation/1454692",
    "name": "Усть-Кутский район"
  },
  {
    "id": "relation/1456220",
    "name": "Жигаловский район"
  },
  {
    "id": "relation/1456241",
    "name": "Качугский район"
  },
  {
    "id": "relation/1456395",
    "name": "Ольхонский район"
  },
  {
    "id": "relation/1456786",
    "name": "Осинский район"
  },
  {
    "id": "relation/1456787",
    "name": "Боханский район"
  },
  {
    "id": "relation/1456788",
    "name": "Эхирит-Булагатский район"
  },
  {
    "id": "relation/1456789",
    "name": "Баяндаевский район"
  },
  {
    "id": "relation/1458139",
    "name": "Черемховский район"
  },
  {
    "id": "relation/1458140",
    "name": "Аларский район"
  },
  {
    "id": "relation/1459516",
    "name": "Шелеховский район"
  },
  {
    "id": "relation/1459545",
    "name": "Иркутский район"
  },
  {
    "id": "relation/1459563",
    "name": "Усть-Удинский район"
  },
  {
    "id": "relation/1460641",
    "name": "Нукутский район"
  },
  {
    "id": "relation/1460653",
    "name": "Балаганский район"
  },
  {
    "id": "relation/1460711",
    "name": "Зиминский район"
  },
  {
    "id": "relation/1460823",
    "name": "Куйтунский район"
  },
  {
    "id": "relation/1463469",
    "name": "Усольский район"
  },
  {
    "id": "relation/1464421",
    "name": "Нижнеилимский район"
  },
  {
    "id": "relation/1464422",
    "name": "Братский район"
  },
  {
    "id": "relation/1465764",
    "name": "Ангарский городской округ"
  },
  {
    "id": "relation/1465802",
    "name": "городской округ Усолье-Сибирское"
  },
  {
    "id": "relation/1465834",
    "name": "Зиминское городское муниципальное образование"
  },
  {
    "id": "relation/1469709",
    "name": "городской округ Черемхово"
  },
  {
    "id": "relation/1469710",
    "name": "городской округ Саянск"
  },
  {
    "id": "relation/1469711",
    "name": "городской округ Братск"
  },
  {
    "id": "relation/1469712",
    "name": "городской округ Усть-Илимск"
  },
  {
    "id": "relation/1469713",
    "name": "городской округ Тулун"
  },
  {
    "id": "relation/1469714",
    "name": "городской округ Свирск"
  },
  {
    "id": "node/27505335"
  },
  {
    "id": "node/191758663"
  },
  {
    "id": "node/191773560"
  },
  {
    "id": "node/204764214"
  },
  {
    "id": "node/210323639"
  },
  {
    "id": "node/243083247"
  },
  {
    "id": "node/243329007"
  },
  {
    "id": "node/254587738"
  },
  {
    "id": "node/275772536"
  },
  {
    "id": "node/276092772"
  },
  {
    "id": "node/276092773"
  },
  {
    "id": "node/276092774"
  },
  {
    "id": "node/276092781"
  },
  {
    "id": "node/276092950"
  },
  {
    "id": "node/276092975"
  },
  {
    "id": "node/276092977"
  },
  {
    "id": "node/279978754"
  },
  {
    "id": "node/283703527"
  },
  {
    "id": "node/289843365"
  },
  {
    "id": "node/300612214"
  },
  {
    "id": "node/301636452"
  },
  {
    "id": "node/302036717"
  },
  {
    "id": "node/302372139"
  },
  {
    "id": "node/376237747"
  },
  {
    "id": "node/432636156"
  },
  {
    "id": "node/441108611"
  },
  {
    "id": "node/645153178"
  },
  {
    "id": "node/700761838"
  },
  {
    "id": "node/700761840"
  },
  {
    "id": "node/1028723768"
  },
  {
    "id": "node/1044077335"
  },
  {
    "id": "node/1067034192"
  },
  {
    "id": "node/1182206250"
  },
  {
    "id": "node/6855343246"
  },
  {
    "id": "node/4382960062"
  },
  {
    "id": "node/8383866283"
  }
]
;