import {combineReducers} from '@reduxjs/toolkit';
import authSlice from '../screens/ayth/store/auth.slice';
import {api} from './api';
import profileSlice from '../screens/profile/store/profile.slice';
import homeSlice from '../screens/home/store/home.slice';
import regionMapSlice from '../screens/regionMap/store/regionMap.slice';

export const rootReducer = combineReducers({
    auth: authSlice,
    home: homeSlice,
    profile: profileSlice,
    regionMap: regionMapSlice,
    [api.reducerPath]: api.reducer,
});
