import React from 'react';
import {RisingRowS, RisingWrapper} from './styled';
import {RisingColumn} from './RisingColumn';
import {RisingType} from './config';

type RisingRowProps = {
    data: RisingType[];
}

export const RisingRow = ({data}: RisingRowProps) => {
    return (
        <RisingWrapper>
            <RisingRowS>
                {data.map((i, idx) => {
                    return (
                        <RisingColumn
                            key={idx}
                            text={i.text}
                            positiveValue={i.positiveValue}
                            negativeValue={i.negativeValue}
                        />
                    );
                })}
            </RisingRowS>
        </RisingWrapper>
    );
};
