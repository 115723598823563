export const names = [
  {
    "id": "relation/1128590",
    "name": "Сургутский район"
  },
  {
    "id": "relation/1128593",
    "name": "Белоярский район"
  },
  {
    "id": "relation/1129847",
    "name": "Нефтеюганский район"
  },
  {
    "id": "relation/1442360",
    "name": "городской округ Когалым"
  },
  {
    "id": "relation/1442570",
    "name": "Нижневартовский район"
  },
  {
    "id": "relation/1442693",
    "name": "Кондинский район"
  },
  {
    "id": "relation/1442694",
    "name": "Берёзовский район"
  },
  {
    "id": "relation/1442695",
    "name": "Советский район"
  },
  {
    "id": "relation/1761426",
    "name": "Октябрьский район"
  },
  {
    "id": "relation/1761427",
    "name": "Ханты-Мансийский район"
  },
  {
    "id": "relation/1761428",
    "name": "городской округ Мегион"
  },
  {
    "id": "relation/1761429",
    "name": "городской округ Нефтеюганск"
  },
  {
    "id": "relation/1761430",
    "name": "городской округ Нижневартовск"
  },
  {
    "id": "relation/1761431",
    "name": "городской округ Нягань"
  },
  {
    "id": "relation/1761432",
    "name": "городской округ Покачи"
  },
  {
    "id": "relation/1761433",
    "name": "городской округ Пыть-Ях"
  },
  {
    "id": "relation/1761434",
    "name": "городской округ Радужный"
  },
  {
    "id": "relation/1761435",
    "name": "городской округ Сургут"
  },
  {
    "id": "relation/1761436",
    "name": "городской округ Урай"
  },
  {
    "id": "relation/1761437",
    "name": "городской округ Ханты-Мансийск"
  },
  {
    "id": "relation/1761438",
    "name": "городской округ Югорск"
  },
  {
    "id": "relation/1761439",
    "name": "городской округ Лангепас"
  },
  {
    "id": "node/191736223"
  },
  {
    "id": "node/191754078"
  },
  {
    "id": "node/191774769"
  },
  {
    "id": "node/191894808"
  },
  {
    "id": "node/278211683"
  },
  {
    "id": "node/278216664"
  },
  {
    "id": "node/304605480"
  },
  {
    "id": "node/307089440"
  },
  {
    "id": "node/335978623"
  },
  {
    "id": "node/335978637"
  },
  {
    "id": "node/335978760"
  },
  {
    "id": "node/352776308"
  },
  {
    "id": "node/659342693"
  },
  {
    "id": "node/1016549636"
  },
  {
    "id": "node/1312634124"
  },
  {
    "id": "node/1416600659"
  },
  {
    "id": "node/1430774947"
  },
  {
    "id": "node/1607065754"
  }
]
;