export const names = [
  {
    "id": "relation/365768",
    "name": "Менделеевский район"
  },
  {
    "id": "relation/365769",
    "name": "Агрызский район"
  },
  {
    "id": "relation/365770",
    "name": "Тукаевский район"
  },
  {
    "id": "relation/366371",
    "name": "Мензелинский район"
  },
  {
    "id": "relation/366372",
    "name": "Актанышский район"
  },
  {
    "id": "relation/366373",
    "name": "Муслюмовский район"
  },
  {
    "id": "relation/366374",
    "name": "Азнакаевский район"
  },
  {
    "id": "relation/366375",
    "name": "Ютазинский район"
  },
  {
    "id": "relation/366376",
    "name": "Бавлинский район"
  },
  {
    "id": "relation/366377",
    "name": "Бугульминский район"
  },
  {
    "id": "relation/366378",
    "name": "Заинский район"
  },
  {
    "id": "relation/366379",
    "name": "Сармановский район"
  },
  {
    "id": "relation/366380",
    "name": "Нижнекамский район"
  },
  {
    "id": "relation/366381",
    "name": "Альметьевский район"
  },
  {
    "id": "relation/367101",
    "name": "Елабужский район"
  },
  {
    "id": "relation/367102",
    "name": "Лениногорский район"
  },
  {
    "id": "relation/367103",
    "name": "Мамадышский район"
  },
  {
    "id": "relation/367104",
    "name": "Новошешминский район"
  },
  {
    "id": "relation/367105",
    "name": "Черемшанский район"
  },
  {
    "id": "relation/367106",
    "name": "Кукморский район"
  },
  {
    "id": "relation/367107",
    "name": "Балтасинский район"
  },
  {
    "id": "relation/367108",
    "name": "Сабинский район"
  },
  {
    "id": "relation/367109",
    "name": "Тюлячинский район"
  },
  {
    "id": "relation/367110",
    "name": "Арский район"
  },
  {
    "id": "relation/367111",
    "name": "Атнинский район"
  },
  {
    "id": "relation/367112",
    "name": "Чистопольский район"
  },
  {
    "id": "relation/367113",
    "name": "Алексеевский район"
  },
  {
    "id": "relation/367114",
    "name": "Аксубаевский район"
  },
  {
    "id": "relation/367115",
    "name": "Нурлатский район"
  },
  {
    "id": "relation/367116",
    "name": "Алькеевский район"
  },
  {
    "id": "relation/367117",
    "name": "Спасский район"
  },
  {
    "id": "relation/367118",
    "name": "Дрожжановский район"
  },
  {
    "id": "relation/367119",
    "name": "Рыбно-Слободский район"
  },
  {
    "id": "relation/367120",
    "name": "Камско-Устьинский район"
  },
  {
    "id": "relation/367121",
    "name": "Тетюшский район"
  },
  {
    "id": "relation/367122",
    "name": "Буинский район"
  },
  {
    "id": "relation/367123",
    "name": "Апастовский район"
  },
  {
    "id": "relation/367124",
    "name": "Кайбицкий район"
  },
  {
    "id": "relation/367666",
    "name": "городской округ Казань"
  },
  {
    "id": "relation/367667",
    "name": "Высокогорский район"
  },
  {
    "id": "relation/367668",
    "name": "Пестречинский район"
  },
  {
    "id": "relation/367669",
    "name": "Лаишевский район"
  },
  {
    "id": "relation/367670",
    "name": "Верхнеуслонский район"
  },
  {
    "id": "relation/367671",
    "name": "Зеленодольский район"
  },
  {
    "id": "relation/367728",
    "name": "городской округ Набережные Челны"
  },
  {
    "id": "node/27504067"
  },
  {
    "id": "node/153568874"
  },
  {
    "id": "node/191749240"
  },
  {
    "id": "node/273310116"
  },
  {
    "id": "node/295862317"
  },
  {
    "id": "node/295864677"
  },
  {
    "id": "node/295868224"
  },
  {
    "id": "node/321322750"
  },
  {
    "id": "node/336524051"
  },
  {
    "id": "node/336524182"
  },
  {
    "id": "node/336524229"
  },
  {
    "id": "node/336524471"
  },
  {
    "id": "node/336524666"
  },
  {
    "id": "node/336524694"
  },
  {
    "id": "node/336524899"
  },
  {
    "id": "node/336525099"
  },
  {
    "id": "node/336525410"
  },
  {
    "id": "node/337839017"
  },
  {
    "id": "node/337839024"
  },
  {
    "id": "node/337839081"
  },
  {
    "id": "node/337839100"
  },
  {
    "id": "node/337839105"
  },
  {
    "id": "node/337839146"
  },
  {
    "id": "node/337839164"
  },
  {
    "id": "node/337839206"
  },
  {
    "id": "node/337839216"
  },
  {
    "id": "node/337839279"
  },
  {
    "id": "node/409487025"
  },
  {
    "id": "node/553037933"
  },
  {
    "id": "node/599924350"
  },
  {
    "id": "node/606106631"
  },
  {
    "id": "node/697241159"
  },
  {
    "id": "node/697241170"
  },
  {
    "id": "node/698459097"
  },
  {
    "id": "node/701721057"
  },
  {
    "id": "node/701847474"
  },
  {
    "id": "node/703166491"
  },
  {
    "id": "node/703197622"
  },
  {
    "id": "node/703680750"
  },
  {
    "id": "node/704304026"
  },
  {
    "id": "node/761247155"
  },
  {
    "id": "node/1707207947"
  },
  {
    "id": "node/1773881620"
  },
  {
    "id": "node/2173235770"
  }
]
;