import styled from 'styled-components';


export const QuestionItem = styled.div.withConfig({
    shouldForwardProp: prop => !['type'].includes(prop)
})<{ type: number }>`
  padding: 10px 10px 10px ${({type}) => type * 10}px;
  background-color: #ffffff;
  border-bottom: 1px solid var(--border-color);
  position: relative;

  &:nth-child(odd) {
    background-color: var(--row-d);
  }

  &:before {
    content: ${({type}) => type > 2 ? '\'\'' : 'none'};
    width: 1px;
    height: 60%;
    background-color: var(--title-light-color);
    position: absolute;
    left: ${({type}) => (type * 10) - 7}px;
  }

  &:hover {
    background-color: var(--light-color);
    cursor: pointer;
  }
`;

export const ReadyWrap = styled.div`
  padding: 5px 0;
  text-align: center;
`;
