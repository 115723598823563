export const names = [
  {
    "id": "relation/1077466",
    "name": "Урюпинский район"
  },
  {
    "id": "relation/1083662",
    "name": "Новониколаевский район"
  },
  {
    "id": "relation/1084791",
    "name": "Еланский район"
  },
  {
    "id": "relation/1084792",
    "name": "Киквидзенский район"
  },
  {
    "id": "relation/1085907",
    "name": "Руднянский район"
  },
  {
    "id": "relation/1085908",
    "name": "Жирновский район"
  },
  {
    "id": "relation/1086557",
    "name": "Старополтавский район"
  },
  {
    "id": "relation/1086558",
    "name": "Камышинский район"
  },
  {
    "id": "relation/1086559",
    "name": "Палласовский район"
  },
  {
    "id": "relation/1099978",
    "name": "Ленинский район"
  },
  {
    "id": "relation/1099980",
    "name": "Светлоярский район"
  },
  {
    "id": "relation/1099982",
    "name": "городской округ Волгоград"
  },
  {
    "id": "relation/1101524",
    "name": "Котельниковский район"
  },
  {
    "id": "relation/1101525",
    "name": "Октябрьский район"
  },
  {
    "id": "relation/1101549",
    "name": "Чернышковский район"
  },
  {
    "id": "relation/1103654",
    "name": "Серафимовичский район"
  },
  {
    "id": "relation/1103655",
    "name": "Клетский район"
  },
  {
    "id": "relation/1103656",
    "name": "Кумылженский район"
  },
  {
    "id": "relation/1103657",
    "name": "Суровикинский район"
  },
  {
    "id": "relation/1104547",
    "name": "Алексеевский район"
  },
  {
    "id": "relation/1104554",
    "name": "Нехаевский район"
  },
  {
    "id": "relation/1105470",
    "name": "Новоаннинский район"
  },
  {
    "id": "relation/1105513",
    "name": "городской округ Михайловка"
  },
  {
    "id": "relation/1106196",
    "name": "Даниловский район"
  },
  {
    "id": "relation/1106197",
    "name": "Котовский район"
  },
  {
    "id": "relation/1106384",
    "name": "Фроловский район"
  },
  {
    "id": "relation/1106825",
    "name": "Калачёвский район"
  },
  {
    "id": "relation/1106840",
    "name": "Иловлинский район"
  },
  {
    "id": "relation/1107378",
    "name": "Городищенский район"
  },
  {
    "id": "relation/1107379",
    "name": "Дубовский район"
  },
  {
    "id": "relation/1107382",
    "name": "Ольховский район"
  },
  {
    "id": "relation/1107967",
    "name": "Николаевский район"
  },
  {
    "id": "relation/1107968",
    "name": "Быковский район"
  },
  {
    "id": "relation/1108299",
    "name": "городской округ Волжский"
  },
  {
    "id": "relation/1108341",
    "name": "городской округ Камышин"
  },
  {
    "id": "relation/1108343",
    "name": "Среднеахтубинский район"
  },
  {
    "id": "relation/1112274",
    "name": "городской округ Урюпинск"
  },
  {
    "id": "relation/1112275",
    "name": "городской округ Фролово"
  },
  {
    "id": "node/27504363"
  },
  {
    "id": "node/90471328"
  },
  {
    "id": "node/191752285"
  },
  {
    "id": "node/191882252"
  },
  {
    "id": "node/286982957"
  },
  {
    "id": "node/336140852"
  },
  {
    "id": "node/336140855"
  },
  {
    "id": "node/336140859"
  },
  {
    "id": "node/336140881"
  },
  {
    "id": "node/336140924"
  },
  {
    "id": "node/336140930"
  },
  {
    "id": "node/336140943"
  },
  {
    "id": "node/336141025"
  },
  {
    "id": "node/344967984"
  },
  {
    "id": "node/356772181"
  },
  {
    "id": "node/356772279"
  },
  {
    "id": "node/356772353"
  },
  {
    "id": "node/356772362"
  },
  {
    "id": "node/356772437"
  },
  {
    "id": "node/356772481"
  },
  {
    "id": "node/356772483"
  },
  {
    "id": "node/356772585"
  },
  {
    "id": "node/356772595"
  },
  {
    "id": "node/356772708"
  },
  {
    "id": "node/356772725"
  },
  {
    "id": "node/356772765"
  },
  {
    "id": "node/356772829"
  },
  {
    "id": "node/845688705"
  },
  {
    "id": "node/847640358"
  },
  {
    "id": "node/847924393"
  },
  {
    "id": "node/851387881"
  },
  {
    "id": "node/1010179630"
  },
  {
    "id": "node/1676020170"
  },
  {
    "id": "node/4808702171"
  },
  {
    "id": "node/4474489494"
  },
  {
    "id": "node/6000481500"
  }
]
;