export const names = [
  {
    "id": "relation/398386",
    "name": "Октябрьский район"
  },
  {
    "id": "relation/398387",
    "name": "Нязепетровский район"
  },
  {
    "id": "relation/398388",
    "name": "Верхнеуфалейский городской округ"
  },
  {
    "id": "relation/398389",
    "name": "Коркинский муниципальный округ"
  },
  {
    "id": "relation/398390",
    "name": "Копейский городской округ"
  },
  {
    "id": "relation/398391",
    "name": "Кусинский район"
  },
  {
    "id": "relation/398392",
    "name": "Нагайбакский район"
  },
  {
    "id": "relation/398393",
    "name": "Варненский район"
  },
  {
    "id": "relation/398394",
    "name": "Красноармейский район"
  },
  {
    "id": "relation/398395",
    "name": "Кунашакский район"
  },
  {
    "id": "relation/398396",
    "name": "Агаповский район"
  },
  {
    "id": "relation/398397",
    "name": "Магнитогорский городской округ"
  },
  {
    "id": "relation/398398",
    "name": "Каслинский район"
  },
  {
    "id": "relation/398399",
    "name": "Кизильский район"
  },
  {
    "id": "relation/398400",
    "name": "Карабашский городской округ"
  },
  {
    "id": "relation/398401",
    "name": "Брединский район"
  },
  {
    "id": "relation/398402",
    "name": "Кыштымский городской округ"
  },
  {
    "id": "relation/398405",
    "name": "Сосновский район"
  },
  {
    "id": "relation/398406",
    "name": "Миасский городской округ"
  },
  {
    "id": "relation/398407",
    "name": "Челябинский городской округ"
  },
  {
    "id": "relation/398408",
    "name": "Аргаяшский район"
  },
  {
    "id": "relation/398409",
    "name": "Чебаркульский городской округ"
  },
  {
    "id": "relation/398410",
    "name": "Златоустовский городской округ"
  },
  {
    "id": "relation/398411",
    "name": "Увельский район"
  },
  {
    "id": "relation/398412",
    "name": "Южноуральский городской округ"
  },
  {
    "id": "relation/398413",
    "name": "Еткульский район"
  },
  {
    "id": "relation/398414",
    "name": "Чебаркульский район"
  },
  {
    "id": "relation/398415",
    "name": "Еманжелинский район"
  },
  {
    "id": "relation/398416",
    "name": "Усть-Катавский городской округ"
  },
  {
    "id": "relation/398417",
    "name": "Ашинский район"
  },
  {
    "id": "relation/398418",
    "name": "Катав-Ивановский район"
  },
  {
    "id": "relation/398419",
    "name": "Саткинский район"
  },
  {
    "id": "relation/398420",
    "name": "Чесменский район"
  },
  {
    "id": "relation/398421",
    "name": "Уйский район"
  },
  {
    "id": "relation/398422",
    "name": "Верхнеуральский район"
  },
  {
    "id": "relation/398423",
    "name": "Троицкий район"
  },
  {
    "id": "relation/398424",
    "name": "Троицкий городской округ"
  },
  {
    "id": "relation/398425",
    "name": "Пластовский район"
  },
  {
    "id": "relation/1483866",
    "name": "Карталинский район"
  },
  {
    "id": "relation/1517631",
    "name": "Локомотивный городской округ"
  },
  {
    "id": "relation/1517659",
    "name": "Озёрский городской округ"
  },
  {
    "id": "relation/1517669",
    "name": "Трёхгорный городской округ"
  },
  {
    "id": "relation/1793195",
    "name": "Снежинский городской округ"
  },
  {
    "id": "node/27504156"
  },
  {
    "id": "node/191843200"
  },
  {
    "id": "node/191863710"
  },
  {
    "id": "node/191870333"
  },
  {
    "id": "node/251526742"
  },
  {
    "id": "node/251531240"
  },
  {
    "id": "node/271954653"
  },
  {
    "id": "node/286523928"
  },
  {
    "id": "node/288472058"
  },
  {
    "id": "node/288476845"
  },
  {
    "id": "node/288477933"
  },
  {
    "id": "node/290070312"
  },
  {
    "id": "node/292885144"
  },
  {
    "id": "node/321203443"
  },
  {
    "id": "node/334357259"
  },
  {
    "id": "node/334357327"
  },
  {
    "id": "node/334371765"
  },
  {
    "id": "node/334371776"
  },
  {
    "id": "node/334371783"
  },
  {
    "id": "node/334371785"
  },
  {
    "id": "node/334371787"
  },
  {
    "id": "node/334371788"
  },
  {
    "id": "node/334371795"
  },
  {
    "id": "node/339257834"
  },
  {
    "id": "node/339257858"
  },
  {
    "id": "node/339257865"
  },
  {
    "id": "node/339257866"
  },
  {
    "id": "node/340962185"
  },
  {
    "id": "node/347458922"
  },
  {
    "id": "node/355144185"
  },
  {
    "id": "node/364042192"
  },
  {
    "id": "node/364045431"
  },
  {
    "id": "node/364983579"
  },
  {
    "id": "node/436436682"
  },
  {
    "id": "node/439179012"
  },
  {
    "id": "node/586157009"
  },
  {
    "id": "node/945733667"
  },
  {
    "id": "node/1176680793"
  },
  {
    "id": "node/1244147408"
  },
  {
    "id": "node/1681575580"
  }
]
;