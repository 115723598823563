export const names = [
  {
    "id": "relation/270287",
    "name": "Первомайский район"
  },
  {
    "id": "relation/274189",
    "name": "Тегульдетский район"
  },
  {
    "id": "relation/274192",
    "name": "Верхнекетский район"
  },
  {
    "id": "relation/274193",
    "name": "Зырянский район"
  },
  {
    "id": "relation/274203",
    "name": "Асиновский район"
  },
  {
    "id": "relation/274205",
    "name": "Томский район"
  },
  {
    "id": "relation/333018",
    "name": "Каргасокский район"
  },
  {
    "id": "relation/333020",
    "name": "Александровский район"
  },
  {
    "id": "relation/1311339",
    "name": "Колпашевский район"
  },
  {
    "id": "relation/1311340",
    "name": "Бакчарский район"
  },
  {
    "id": "relation/1311342",
    "name": "Чаинский район"
  },
  {
    "id": "relation/1311343",
    "name": "Молчановский район"
  },
  {
    "id": "relation/1311345",
    "name": "Кривошеинский район"
  },
  {
    "id": "relation/1311351",
    "name": "Шегарский район"
  },
  {
    "id": "relation/1311352",
    "name": "Кожевниковский район"
  },
  {
    "id": "relation/1314035",
    "name": "Парабельский район"
  },
  {
    "id": "relation/1591240",
    "name": "ЗАТО Северск"
  },
  {
    "id": "relation/1591241",
    "name": "городской округ Томск"
  },
  {
    "id": "relation/1891607",
    "name": "городской округ Стрежевой"
  },
  {
    "id": "relation/2168100",
    "name": "городской округ Кедровый"
  },
  {
    "id": "node/163043169"
  },
  {
    "id": "node/178972986"
  },
  {
    "id": "node/179165864"
  },
  {
    "id": "node/186985543"
  },
  {
    "id": "node/186987910"
  },
  {
    "id": "node/187010245"
  },
  {
    "id": "node/187013636"
  },
  {
    "id": "node/191902212"
  },
  {
    "id": "node/203914619"
  },
  {
    "id": "node/203999445"
  },
  {
    "id": "node/205375412"
  },
  {
    "id": "node/206803392"
  },
  {
    "id": "node/206809742"
  },
  {
    "id": "node/210111403"
  },
  {
    "id": "node/210221063"
  },
  {
    "id": "node/210375564"
  },
  {
    "id": "node/210526082"
  },
  {
    "id": "node/210737785"
  },
  {
    "id": "node/244529460"
  }
]
;