export const names = [
  {
    "id": "relation/1749726",
    "name": "городской округ Аргун"
  },
  {
    "id": "relation/1749727",
    "name": "Ачхой-Мартановский район"
  },
  {
    "id": "relation/1749728",
    "name": "Веденский район"
  },
  {
    "id": "relation/1749729",
    "name": "Грозненский район"
  },
  {
    "id": "relation/1749731",
    "name": "Гудермесский район"
  },
  {
    "id": "relation/1749732",
    "name": "Итум-Калинский район"
  },
  {
    "id": "relation/1749733",
    "name": "Курчалоевский район"
  },
  {
    "id": "relation/1749734",
    "name": "Надтеречный район"
  },
  {
    "id": "relation/1749736",
    "name": "Наурский район"
  },
  {
    "id": "relation/1749737",
    "name": "Ножай-Юртовский район"
  },
  {
    "id": "relation/1749738",
    "name": "Серноводский район"
  },
  {
    "id": "relation/1749739",
    "name": "Урус-Мартановский район"
  },
  {
    "id": "relation/1749740",
    "name": "Шалинский район"
  },
  {
    "id": "relation/1749741",
    "name": "Шаройский район"
  },
  {
    "id": "relation/1749742",
    "name": "Шатойский район"
  },
  {
    "id": "relation/1749743",
    "name": "Шелковской район"
  },
  {
    "id": "relation/1957640",
    "name": "городской округ Грозный"
  },
  {
    "id": "node/287702846"
  },
  {
    "id": "node/287703730"
  },
  {
    "id": "node/287704409"
  },
  {
    "id": "node/391281814"
  },
  {
    "id": "node/846770282"
  },
  {
    "id": "node/962070555"
  },
  {
    "id": "node/962132224"
  },
  {
    "id": "node/962174016"
  },
  {
    "id": "node/1363367645"
  },
  {
    "id": "node/1427491388"
  },
  {
    "id": "node/1433086453"
  },
  {
    "id": "node/1433086456"
  },
  {
    "id": "node/1433086457"
  },
  {
    "id": "node/1433086458"
  },
  {
    "id": "node/1475068342"
  },
  {
    "id": "node/1522654732"
  },
  {
    "id": "node/2457002805"
  }
]
;