import React, {useEffect} from 'react';
import {Collapse, TextS} from '../../fields';
import {useGetSchemasQuery, useLazyGetSchemaQuestionQuery} from '../../screens/home/store/home.api';
import {ListSects} from '../../screens/home/store/types';
import {Loader} from '../loader';
import {QuestionItem, ReadyWrap} from './styled';
import {useAppDispatch, useCreateParams} from '../../hooks';
import {setSelectQuestion} from '../../screens/home/store/home.slice';
import {useGetRegionsParamsQuery} from '../../screens/regionMap/store/regionMap.api';
import {SidebarWrapper} from '../../layouts';
import {toast} from 'react-toastify';

function splitByIdType(label: string, data: ListSects[]) {
    const excludeRepeat = data.filter(i => i.SectName !== label);
    const result = [];
    let currentGroup: ListSects[] = [];

    excludeRepeat.forEach((item) => {
        if (item.ID_Type === 1) {
            // Если начинается новая группа, сохраняем текущую (если есть)
            if (currentGroup.length > 0) {
                result.push(currentGroup);
            }
            // Начинаем новую группу
            currentGroup = [item];
        } else {
            // Добавляем элемент в текущую группу
            currentGroup.push(item);
        }
    });

    // Добавляем последнюю группу в результат (если она не пустая)
    if (currentGroup.length > 0) {
        result.push(currentGroup);
    }

    return result;
}

export const Sidebar = () => {
    const dispatch = useAppDispatch();
    const {data: listSchemas} = useGetSchemasQuery();
    const [getQuestion, {data, isFetching}] = useLazyGetSchemaQuestionQuery();

    const {params, skip} = useCreateParams();
    const {error, isFetching: load} = useGetRegionsParamsQuery({params}, {skip, refetchOnMountOrArgChange: true});

    useEffect(() => {
        if (error) {
            const message = (error as any).data?.message;
            toast.error(message, {
                autoClose: false
            });
        }
    }, [error]);

    const handleGetQuestion = (ID_Shema: string) => {
        getQuestion({ID_Shema});
    };

    const selectElement = (item: ListSects) => {
        dispatch(setSelectQuestion(item));
    };

    return (
        <SidebarWrapper>
            {load ? <Loader type={'large'}/> : null}
            {listSchemas?.map((item, index) =>

                <Collapse
                    key={item.value}
                    buttonText={item.label}
                    fontSize={0.8}
                    border={true}
                    isInner={false}
                    showIcon={true}
                    renderTitle={
                        <TextS
                            as={'span'}
                            fontSize={0.8}
                            fontWeight={400}
                            color={'var(--title-light-color)'}
                        >{item.label}</TextS>
                    }
                    onClick={() => handleGetQuestion(item.value)}
                >
                    <>
                        {isFetching
                            ? <ReadyWrap><Loader type={'small'}/></ReadyWrap>
                            : splitByIdType(item.label, data?.listSects ?? []).map((item: ListSects[], idx: number) => (
                                <Collapse
                                    key={idx}
                                    buttonText={item[0].SectName}
                                    fontSize={0.7}
                                    border={false}
                                    isInner={true}
                                    showIcon={item.length > 1}
                                    onClick={() => {
                                    }}
                                >
                                    {item.map((i: ListSects, index: number) => {
                                        if (index === 0) return null;

                                        return (
                                            <QuestionItem
                                                key={i.ID_Sect}
                                                type={i.ID_Type}
                                                onClick={() => selectElement(i)}
                                            >
                                                <TextS
                                                    as={'p'}
                                                    fontSize={0.6}
                                                    fontWeight={400}
                                                    color={'var(--text-color)'}
                                                >{i.SectName}</TextS>
                                            </QuestionItem>
                                        );
                                    })}
                                </Collapse>
                            ))}
                    </>
                </Collapse>
            )}
        </SidebarWrapper>
    );
};
