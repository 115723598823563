const director_1 = require('../../assets/images/directors/director-1.jpeg');
const director_2 = require('../../assets/images/directors/director-2.jpeg');
const director_3 = require('../../assets/images/directors/director-3.jpeg');
const director_4 = require('../../assets/images/directors/director-4.jpeg');

export const directorInfo = [
    {
        id: 1,
        photo: director_1,
        name: 'Петров Иван Поликарпович',
        jobTitle: 'И.О Губернатора области',
        nationality: 'Русский',
        education: 'высшее',
        specialty: 'юрист',
        academicDegree: 'доктор наук'
    },
    {
        id: 2,
        photo: director_2,
        name: 'Иванова Светлана Юрьевна',
        jobTitle: 'И.О. Губернатора области',
        nationality: 'Русская',
        education: 'высшее',
        specialty: 'юрист',
        academicDegree: 'нет'
    },
    {
        id: 3,
        photo: director_3,
        name: 'Орлов Сергей Сергеевич',
        jobTitle: 'Губернатор области',
        nationality: 'Русский',
        education: 'высшее',
        specialty: 'юрист',
        academicDegree: 'доктор наук'
    },
    {
        id: 4,
        photo: director_4,
        name: 'Аркадов Дмитрий Иванович',
        jobTitle: 'И.О Губернатора области',
        nationality: 'Русский',
        education: 'высшее',
        specialty: 'юрист',
        academicDegree: 'бакалавр'
    },

];

export const workActivity = [
    {
        date: 'Нояб. 2014 по Н.В.',
        text: 'исполняющий обязанности главы, глава местной администрации городского округа Баксан, Кабардино-Балкарская Республика'
    },
    {date: 'Окт. 2012 - Нояб. 2014', text: 'заместитель министра спорта Кабардино-Балкарской Республики, г.Нальчик'},
    {
        date: 'Май. 2008 - Окт. 2012',
        text: 'заместитель министра спорта и туризма Кабардино-Балкарской Республики, г.Нальчик'
    },
    {
        date: 'Нояб. 1996 - Май. 2008',
        text: 'заместитель министра спорта, туризма и курортов Кабардино-Балкарской Республики, г.Нальчик'
    },
];
