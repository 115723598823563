export const names = [
  {
    "id": "relation/1057591",
    "name": "Адыге-Хабльский район"
  },
  {
    "id": "relation/1057789",
    "name": "Прикубанский район"
  },
  {
    "id": "relation/1058155",
    "name": "Хабезский район"
  },
  {
    "id": "relation/1058875",
    "name": "Урупский район"
  },
  {
    "id": "relation/1059258",
    "name": "Зеленчукский район"
  },
  {
    "id": "relation/1059259",
    "name": "Усть-Джегутинский район"
  },
  {
    "id": "relation/1059261",
    "name": "Малокарачаевский район"
  },
  {
    "id": "relation/1059283",
    "name": "Карачаевский район"
  },
  {
    "id": "relation/1059324",
    "name": "Абазинский район"
  },
  {
    "id": "relation/1059958",
    "name": "Ногайский район"
  },
  {
    "id": "relation/1067969",
    "name": "Черкесский городской округ"
  },
  {
    "id": "relation/1812040",
    "name": "Карачаевский городской округ"
  },
  {
    "id": "node/191638748"
  },
  {
    "id": "node/287387383"
  },
  {
    "id": "node/303486344"
  },
  {
    "id": "node/304718066"
  },
  {
    "id": "node/961867142"
  },
  {
    "id": "node/968109998"
  },
  {
    "id": "node/971798356"
  },
  {
    "id": "node/976690360"
  },
  {
    "id": "node/977415508"
  },
  {
    "id": "node/977498443"
  },
  {
    "id": "node/1879354139"
  }
]
;