export const findBreakPoints = (data: { value: number, year: number }[], threshold: number = 1): number[] => {
    const breakPoints: number[] = [];

    // Обязательно добавляем первую точку
    breakPoints.push(0);

    for (let i = 1; i < data.length - 1; i++) {
        const prevDelta = data[i].value - data[i - 1].value;
        const nextDelta = data[i + 1].value - data[i].value;
        
        // Проверяем разницу между соседними точками
        if (Math.abs(prevDelta) >= threshold || Math.abs(nextDelta) >= threshold) {
            breakPoints.push(i);
        }
    }

    // Обязательно добавляем последнюю точку
    breakPoints.push(data.length - 1);

    return breakPoints;
};
