import {createSlice} from '@reduxjs/toolkit';
import {AuthStore} from './types';
import {authApi} from './auth.api';

const initialState: AuthStore = {
    isAuth: false
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        logout: () => {
        }
    },
    extraReducers: builder => {
        builder.addMatcher(
            authApi.endpoints.signIn.matchFulfilled,
            (state, {payload}) => {
                state.isAuth = payload.status;
            }
        );
    }
});

export const {logout} = authSlice.actions;
export default authSlice.reducer;
