export const names = [
  {
    "id": "relation/1922202",
    "name": "Ольский муниципальный округ"
  },
  {
    "id": "relation/1922203",
    "name": "Омсукчанский муниципальный округ"
  },
  {
    "id": "relation/1922204",
    "name": "Северо-Эвенский муниципальный округ"
  },
  {
    "id": "relation/1922205",
    "name": "Среднеканский муниципальный округ"
  },
  {
    "id": "relation/1922206",
    "name": "Сусуманский муниципальный округ"
  },
  {
    "id": "relation/1922207",
    "name": "Тенькинский муниципальный округ"
  },
  {
    "id": "relation/1922208",
    "name": "Хасынский муниципальный округ"
  },
  {
    "id": "relation/1922209",
    "name": "Ягоднинский муниципальный округ"
  },
  {
    "id": "relation/2621518",
    "name": "городской округ Магадан"
  },
  {
    "id": "node/280182070"
  },
  {
    "id": "node/457275363"
  },
  {
    "id": "node/644293635"
  },
  {
    "id": "node/895223747"
  },
  {
    "id": "node/895223802"
  },
  {
    "id": "node/1734960621"
  },
  {
    "id": "node/2616532963"
  },
  {
    "id": "node/2616533929"
  },
  {
    "id": "node/2893369031"
  }
]
;