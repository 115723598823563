import React, {memo} from 'react';
import {Flex, HeaderS} from '../../layouts';
import {TitleS} from '../../fields';
import {HEADER_HEIGHT} from '../../utils/constants';
import {Logo} from '../../assets/icons';
import {LogoS} from './styled';
import {Menu} from './Menu';
import {useNavigate} from 'react-router-dom';

const Header = () => {
    const navigate = useNavigate();

    return (
        <HeaderS style={{height: HEADER_HEIGHT + 'px'}}>
            <Flex flexDirection={'row'} alignItems={'center'} hovered={true} onClick={() => navigate('/')}>
                <LogoS>
                    <Logo/>
                </LogoS>
                <TitleS
                    tag={'h5'}
                    fontSize={1.2}
                    fontWeight={600}
                    color={'var(--title-light-color)'}>
                    Информационная<br/>система</TitleS>
            </Flex>
            <Menu/>
        </HeaderS>
    );
};

export default memo(Header);
