import styled from 'styled-components';

type TextSProps = {
    as: 'p' | 'span';
    fontSize: number;
    fontWeight: 300 | 400 | 500 | 600;
    textCenter?: 'left' | 'center' | 'right';
    color: string;
    tooltip?: string;
    width?: string;
}

export const TextS = styled.p.attrs<TextSProps>(({as}) => ({
    as: as,
})).withConfig({
    shouldForwardProp: prop => !['as'].includes(prop)
})<TextSProps>`
  font-size: ${({fontSize}) => fontSize + 'rem'};
  line-height: ${({fontSize}) => `${fontSize + 0.2}rem`};
  font-weight: ${({fontWeight}) => fontWeight};
  color: ${({color}) => color};
  text-align: ${({textCenter}) => textCenter ? textCenter : 'left'};
  margin: 0;
  display: inline-block;
  width: ${({width}) => width ? width : 'auto'};

  &::after {
    content: ${({tooltip}) => (tooltip ? `"${tooltip}"` : 'none')};
    position: absolute;
    left: 50%;
    top: 110%;
    transform: translateX(-50%);
    background: #ffffff;
    color: var(--text-color);
    padding: 5px 10px;
    border-radius: 5px;
    border: 1px solid var(--light-color);
    font-size: 0.8rem;
    white-space: nowrap;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.2s;
    z-index: 100;
  }

  /* Показ тултипа при наведении */

  &:hover::after {
    opacity: 1;
  }

`;
