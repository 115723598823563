import React from 'react';

export const Profile = () => {


    return (
        <div>

        </div>
    );
};
