export const names = [
  {
    "id": "relation/1701712",
    "name": "Биробиджанский район"
  },
  {
    "id": "relation/1701713",
    "name": "Ленинский район"
  },
  {
    "id": "relation/1701714",
    "name": "Облученский район"
  },
  {
    "id": "relation/1701715",
    "name": "Октябрьский район"
  },
  {
    "id": "relation/1701716",
    "name": "Смидовичский район"
  },
  {
    "id": "relation/1701717",
    "name": "городской округ Биробиджан"
  },
  {
    "id": "node/191738860"
  },
  {
    "id": "node/452125401"
  },
  {
    "id": "node/452164141"
  },
  {
    "id": "node/1028723730"
  },
  {
    "id": "node/1028723825"
  }
]
;