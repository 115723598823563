import React, {useState} from 'react';
import {Content} from '../../layouts/template';
import {SidebarWrapper} from '../../layouts';
import {ContentCol, RegionName, TitleWrapper, Toggle, ToggleWrapper} from './styled';
import {directorInfo} from './config';
import {TextS, TitleS} from '../../fields';
import {useAppSelector, useGetSearchParams} from '../../hooks';
import {groupByName} from '../../helpers';
import {RisingRow} from '../../components/risingColumn';
import {WorkActivity} from './components';
import Director from './components/Director';
import {SidebarSeparator} from '../../layouts/SidebarWrapper';
import {risingOptions} from '../../components/risingColumn/config';
import {Sidebar} from '../../components/sidebar';

function getRandomNumber(num: number) {
    return Math.floor(Math.random() * num);
}

const randomDirector = getRandomNumber(4);

export const RegionInfo = () => {
    const regionName = useGetSearchParams('regionName');

    const {regions} = useAppSelector(state => state.regionMap);
    const {selectSchemaQuestion} = useAppSelector(state => state.home);

    const group = groupByName(regions?.data ?? []);

    const currentRegion = group.filter(i => i.name === regionName).pop();

    const [currentToggleIndex, setCurrentToggleIndex] = useState<number | null>(null);

    return (
        <>
            <Sidebar/>
            <Content inner={true}>
                <ContentCol>
                    {[directorInfo[randomDirector]].map(item => <Director key={item.id} item={item}/>)}
                    <TitleWrapper margin={'10px 0'} borderRadius={'4px'}>
                        <TitleS
                            tag={'h4'}
                            fontSize={1}
                            fontWeight={400}
                            color={'var(--text-color)'}>
                            Трудовая деятельность
                        </TitleS>
                    </TitleWrapper>

                    <WorkActivity/>
                </ContentCol>
                <ContentCol>
                    {selectSchemaQuestion !== null
                        ? <TitleWrapper margin={'0'} borderRadius={'4px 8px 4px 4px'}>
                            <TitleS
                                tag={'h4'}
                                fontSize={1}
                                fontWeight={400}
                                color={'var(--text-color)'}
                            >
                                {selectSchemaQuestion.SectName}
                            </TitleS>
                        </TitleWrapper>
                        : null}
                    {currentToggleIndex !== null ? <RisingRow data={risingOptions[currentToggleIndex]}/> : null}
                </ContentCol>
            </Content>
            <SidebarWrapper>
                <RegionName border={'0 0 1px 0 solid var(--border-color)'}>
                    <TitleS
                        tag={'h4'}
                        fontSize={1}
                        fontWeight={400}
                        color={'var(--text-color)'}
                    >
                        {currentRegion?.name ?? ''}
                    </TitleS>
                    <SidebarSeparator spaceVertical={3}/>
                </RegionName>
                <RegionName>
                    <TextS
                        as={'span'}
                        fontSize={0.8}
                        fontWeight={400}
                        color={'var(--text-color)'}
                    >
                        Сравнение с регионами<br/>по стране
                    </TextS>
                </RegionName>
                <ToggleWrapper>
                    <Toggle width={'100%'} onClick={() => setCurrentToggleIndex(0)}>Что лучше</Toggle>
                    <Toggle width={'100%'} onClick={() => setCurrentToggleIndex(1)}>Что хуже</Toggle>
                </ToggleWrapper>
                <SidebarSeparator spaceVertical={15}/>
                <RegionName>
                    <TextS
                        as={'span'}
                        fontSize={0.8}
                        fontWeight={400}
                        color={'var(--text-color)'}
                    >
                        Сравнение с предыдущими<br/>главами региона
                    </TextS>
                </RegionName>
                <ToggleWrapper>
                    <Toggle width={'100%'} onClick={() => setCurrentToggleIndex(2)}>Что лучше</Toggle>
                    <Toggle width={'100%'} onClick={() => setCurrentToggleIndex(3)}>Что хуже</Toggle>
                </ToggleWrapper>
            </SidebarWrapper>
        </>
    );
};
