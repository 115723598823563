export const names = [
  {
    "id": "relation/11795653",
    "name": "Генический район"
  },
  {
    "id": "relation/11874151",
    "name": "Каховский район"
  },
  {
    "id": "relation/11874245",
    "name": "Херсонский район"
  },
  {
    "id": "relation/11874246",
    "name": "Скадовский район"
  },
  {
    "id": "relation/11874322",
    "name": "Бериславский район"
  },
  {
    "id": "node/255466573"
  },
  {
    "id": "node/337686273"
  },
  {
    "id": "node/337686681"
  },
  {
    "id": "node/337691804"
  },
  {
    "id": "node/337692021"
  }
]
;