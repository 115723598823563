export const names = [
  {
    "id": "relation/190852",
    "name": "Таштыпский район"
  },
  {
    "id": "relation/190914",
    "name": "Алтайский район"
  },
  {
    "id": "relation/190915",
    "name": "Аскизский район"
  },
  {
    "id": "relation/190923",
    "name": "Бейский район"
  },
  {
    "id": "relation/190926",
    "name": "Боградский район"
  },
  {
    "id": "relation/190928",
    "name": "Орджоникидзевский район"
  },
  {
    "id": "relation/190931",
    "name": "Ширинский район"
  },
  {
    "id": "relation/190932",
    "name": "Усть-Абаканский район"
  },
  {
    "id": "relation/1474707",
    "name": "городской округ Абакан"
  },
  {
    "id": "relation/1491173",
    "name": "городской округ Черногорск"
  },
  {
    "id": "relation/2169622",
    "name": "городской округ Саяногорск"
  },
  {
    "id": "relation/2344980",
    "name": "городской округ Абаза"
  },
  {
    "id": "relation/2345013",
    "name": "городской округ Сорск"
  },
  {
    "id": "node/275808498"
  },
  {
    "id": "node/285340786"
  },
  {
    "id": "node/285360076"
  },
  {
    "id": "node/285365947"
  },
  {
    "id": "node/285889059"
  },
  {
    "id": "node/286214595"
  },
  {
    "id": "node/457453957"
  },
  {
    "id": "node/457497582"
  },
  {
    "id": "node/457505136"
  },
  {
    "id": "node/457507984"
  },
  {
    "id": "node/1028723854"
  },
  {
    "id": "node/1067034190"
  },
  {
    "id": "node/1588233141"
  }
]
;