export const names = [
  {
    "id": "relation/937320",
    "name": "Новохопёрский район"
  },
  {
    "id": "relation/937321",
    "name": "Поворинский район"
  },
  {
    "id": "relation/937332",
    "name": "Борисоглебский городской округ"
  },
  {
    "id": "relation/937345",
    "name": "Грибановский район"
  },
  {
    "id": "relation/937346",
    "name": "Терновский район"
  },
  {
    "id": "relation/937353",
    "name": "Эртильский район"
  },
  {
    "id": "relation/937354",
    "name": "Аннинский район"
  },
  {
    "id": "relation/938797",
    "name": "Таловский район"
  },
  {
    "id": "relation/938839",
    "name": "Бутурлиновский район"
  },
  {
    "id": "relation/938869",
    "name": "Воробьёвский район"
  },
  {
    "id": "relation/938974",
    "name": "Калачеевский район"
  },
  {
    "id": "relation/938981",
    "name": "Петропавловский район"
  },
  {
    "id": "relation/938986",
    "name": "Богучарский район"
  },
  {
    "id": "relation/939027",
    "name": "Верхнемамонский район"
  },
  {
    "id": "relation/939075",
    "name": "Бобровский район"
  },
  {
    "id": "relation/939115",
    "name": "Лискинский район"
  },
  {
    "id": "relation/939198",
    "name": "Панинский район"
  },
  {
    "id": "relation/939222",
    "name": "Верхнехавский район"
  },
  {
    "id": "relation/939223",
    "name": "Кантемировский район"
  },
  {
    "id": "relation/939404",
    "name": "Ольховатский район"
  },
  {
    "id": "relation/939413",
    "name": "Каменский район"
  },
  {
    "id": "relation/1066762",
    "name": "Нижнедевицкий район"
  },
  {
    "id": "relation/1066763",
    "name": "Репьёвский район"
  },
  {
    "id": "relation/1144811",
    "name": "городской округ Воронеж"
  },
  {
    "id": "relation/1144828",
    "name": "Каширский район"
  },
  {
    "id": "relation/1144850",
    "name": "Острогожский район"
  },
  {
    "id": "relation/1144853",
    "name": "Хохольский район"
  },
  {
    "id": "relation/1144860",
    "name": "Семилукский район"
  },
  {
    "id": "relation/1144868",
    "name": "Рамонский район"
  },
  {
    "id": "relation/1144870",
    "name": "Новоусманский район"
  },
  {
    "id": "relation/1144891",
    "name": "Россошанский район"
  },
  {
    "id": "relation/1144986",
    "name": "Подгоренский район"
  },
  {
    "id": "relation/1144994",
    "name": "Павловский район"
  },
  {
    "id": "relation/1145007",
    "name": "городской округ Нововоронеж"
  },
  {
    "id": "node/27505044"
  },
  {
    "id": "node/280822642"
  },
  {
    "id": "node/286831504"
  },
  {
    "id": "node/309065171"
  },
  {
    "id": "node/310586971"
  },
  {
    "id": "node/336140450"
  },
  {
    "id": "node/336140474"
  },
  {
    "id": "node/336140500"
  },
  {
    "id": "node/336140521"
  },
  {
    "id": "node/336140564"
  },
  {
    "id": "node/336140733"
  },
  {
    "id": "node/336140738"
  },
  {
    "id": "node/336140739"
  },
  {
    "id": "node/336140786"
  },
  {
    "id": "node/336140811"
  },
  {
    "id": "node/336140818"
  },
  {
    "id": "node/336140824"
  },
  {
    "id": "node/336140851"
  },
  {
    "id": "node/336140935"
  },
  {
    "id": "node/336140962"
  },
  {
    "id": "node/336140966"
  },
  {
    "id": "node/336141049"
  },
  {
    "id": "node/336141155"
  },
  {
    "id": "node/336141201"
  },
  {
    "id": "node/347571112"
  },
  {
    "id": "node/356772188"
  },
  {
    "id": "node/356772395"
  },
  {
    "id": "node/383727310"
  },
  {
    "id": "node/392895245"
  },
  {
    "id": "node/1022943447"
  },
  {
    "id": "node/1200220805"
  },
  {
    "id": "node/1306731707"
  },
  {
    "id": "node/2616628792"
  },
  {
    "id": "node/4697594038"
  }
]
;