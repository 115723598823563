import styled from 'styled-components';
import {CSSProperties} from 'react';

type TitleSProps = {
    tag: 'h1' | 'h2' | 'h3' | 'h4' | 'h5';
    fontSize: number;
    fontWeight: 300 | 400 | 500 | 600;
    color: string;
} & CSSProperties

export const TitleS = styled.p.attrs<TitleSProps>(({tag}) => ({
    as: tag || 'h1',
})).withConfig({
    shouldForwardProp: prop => !['tag'].includes(prop)
})<TitleSProps>`
  font-size: ${({fontSize}) => fontSize + 'rem'};
  line-height: ${({fontSize}) => (fontSize + 0.1) + 'rem'};
  font-weight: ${({fontWeight}) => fontWeight};
  color: ${({color}) => color};
  margin: 0;
`;
