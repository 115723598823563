export const names = [
  {
    "id": "relation/1144682",
    "name": "Юргинский городской округ"
  },
  {
    "id": "relation/1144740",
    "name": "Юргинский муниципальный округ"
  },
  {
    "id": "relation/1144998",
    "name": "Промышленновский муниципальный округ"
  },
  {
    "id": "relation/1145522",
    "name": "Яйский муниципальный округ"
  },
  {
    "id": "relation/1145962",
    "name": "Ижморский муниципальный округ"
  },
  {
    "id": "relation/1146107",
    "name": "Мариинский муниципальный округ"
  },
  {
    "id": "relation/1146148",
    "name": "Тяжинский муниципальный округ"
  },
  {
    "id": "relation/1146331",
    "name": "Чебулинский муниципальный округ"
  },
  {
    "id": "relation/1146372",
    "name": "Тисульский муниципальный округ"
  },
  {
    "id": "relation/1147328",
    "name": "Топкинский муниципальный округ"
  },
  {
    "id": "relation/1147596",
    "name": "Кемеровский муниципальный округ"
  },
  {
    "id": "relation/1148323",
    "name": "Таштагольский район"
  },
  {
    "id": "relation/1149100",
    "name": "Новокузнецкий муниципальный округ"
  },
  {
    "id": "relation/1149106",
    "name": "Прокопьевский муниципальный округ"
  },
  {
    "id": "relation/1149117",
    "name": "Беловский муниципальный округ"
  },
  {
    "id": "relation/1149118",
    "name": "Гурьевский муниципальный округ"
  },
  {
    "id": "relation/1149124",
    "name": "Ленинск-Кузнецкий муниципальный округ"
  },
  {
    "id": "relation/1150979",
    "name": "Анжеро-Судженский городской округ"
  },
  {
    "id": "relation/1150989",
    "name": "Беловский городской округ"
  },
  {
    "id": "relation/1150993",
    "name": "Крапивинский муниципальный округ"
  },
  {
    "id": "relation/1151007",
    "name": "Яшкинский муниципальный округ"
  },
  {
    "id": "relation/1151171",
    "name": "Берёзовский городской округ"
  },
  {
    "id": "relation/1151172",
    "name": "Тайгинский городской округ"
  },
  {
    "id": "relation/1151180",
    "name": "Калтанский городской округ"
  },
  {
    "id": "relation/1151241",
    "name": "Киселёвский городской округ"
  },
  {
    "id": "relation/1151391",
    "name": "Ленинск-Кузнецкий городской округ"
  },
  {
    "id": "relation/1151561",
    "name": "Междуреченский городской округ"
  },
  {
    "id": "relation/1151562",
    "name": "Мысковский городской округ"
  },
  {
    "id": "relation/1151631",
    "name": "Осинниковский городской округ"
  },
  {
    "id": "relation/1151634",
    "name": "Полысаевский городской округ"
  },
  {
    "id": "relation/1151638",
    "name": "Прокопьевский городской округ"
  },
  {
    "id": "relation/1153257",
    "name": "Кемеровский городской округ"
  },
  {
    "id": "relation/1153293",
    "name": "Новокузнецкий городской округ"
  },
  {
    "id": "node/27505477"
  },
  {
    "id": "node/178926370"
  },
  {
    "id": "node/191779212"
  },
  {
    "id": "node/191908717"
  },
  {
    "id": "node/191909355"
  },
  {
    "id": "node/205159518"
  },
  {
    "id": "node/205177205"
  },
  {
    "id": "node/205216827"
  },
  {
    "id": "node/205430993"
  },
  {
    "id": "node/205437775"
  },
  {
    "id": "node/206111919"
  },
  {
    "id": "node/206204308"
  },
  {
    "id": "node/216890905"
  },
  {
    "id": "node/221109841"
  },
  {
    "id": "node/221173014"
  },
  {
    "id": "node/221214205"
  },
  {
    "id": "node/277750915"
  },
  {
    "id": "node/280162925"
  },
  {
    "id": "node/295119416"
  },
  {
    "id": "node/390614421"
  },
  {
    "id": "node/470932636"
  },
  {
    "id": "node/470940629"
  },
  {
    "id": "node/665323408"
  },
  {
    "id": "node/673511072"
  },
  {
    "id": "node/687181032"
  },
  {
    "id": "node/833665925"
  },
  {
    "id": "node/919568369"
  }
]
;