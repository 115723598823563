import React from 'react';

type TreeLineProps = {
    rotate: boolean;
}

export const TreeLine = ({rotate}: TreeLineProps) => {
    return (
        <svg
            style={{transform: `rotate(${rotate ? 0 : 180}deg)`}}
            width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M1 0.5C0.447715 0.5 1.03217e-07 0.947715 1.03217e-07 1.5C1.03217e-07 2.05228 0.447715 2.5 1 2.5H17C17.5523 2.5 18 2.05228 18 1.5C18 0.947715 17.5523 0.5 17 0.5H1Z"
                fill={'rgb(32, 89, 152)'} fillOpacity="0.85"/>
            <path
                d="M0.00496062 8.10023C-0.0239544 7.81198 0.0720012 7.51356 0.292827 7.29274L3.12124 4.46428C3.51176 4.07376 4.14492 4.07375 4.53545 4.46427C4.92598 4.8548 4.92598 5.48796 4.53546 5.87849L3.41397 7H17C17.5523 7 18 7.44772 18 8C18 8.55229 17.5523 9 17 9H3.41429L4.53546 10.1212C4.92598 10.5117 4.92598 11.1449 4.53545 11.5354C4.14492 11.9259 3.51176 11.9259 3.12124 11.5354L0.301467 8.71558C0.270548 8.68539 0.241578 8.65322 0.214763 8.61926C0.158791 8.54849 0.113756 8.47191 0.0796591 8.39178C0.0408548 8.30073 0.0151644 8.20276 0.00496062 8.10023Z"
                fill={'rgb(32, 89, 152)'} fillOpacity="0.85"/>
            <path
                d="M1.03217e-07 14.5C1.03217e-07 13.9477 0.447715 13.5 1 13.5H17C17.5523 13.5 18 13.9477 18 14.5C18 15.0523 17.5523 15.5 17 15.5H1C0.447715 15.5 1.03217e-07 15.0523 1.03217e-07 14.5Z"
                fill={'rgb(32, 89, 152)'} fillOpacity="0.85"/>
        </svg>

    );
};

