import styled from 'styled-components';
import {NavLink} from 'react-router-dom';

export const BreadCrumbsRow = styled.div`
  padding: 5px 12px;
  height: 25px;
  display: flex;
  align-items: center;
  position: relative;
`;

export const BreadCrumbsItem = styled(NavLink)`
  display: inline-block;
  font-size: 0.8rem;
  font-weight: 400;
  color: var(--text-color-light);
  text-decoration: none;

  &:after {
    content: '/';
    font-weight: 300;
    display: inline-block;
    padding: 0 3px;
  }

  &:hover {
    color: var(--title-light-color);
    text-decoration: underline;
  }

  &:last-of-type {
    color: var(--text-color);
    font-weight: 600;
    text-decoration: none;
    cursor: default;

    &:after {
      content: none;
    }
  }
`;
