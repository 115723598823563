export const names = [
  {
    "id": "relation/1431876",
    "name": "городской округ Новый Уренгой"
  },
  {
    "id": "relation/1442837",
    "name": "муниципальный округ Красноселькупский район"
  },
  {
    "id": "relation/1442838",
    "name": "муниципальный округ Шурышкарский район"
  },
  {
    "id": "relation/1442840",
    "name": "муниципальный округ Приуральский район"
  },
  {
    "id": "relation/1761328",
    "name": "муниципальный округ Надымский район"
  },
  {
    "id": "relation/1761329",
    "name": "муниципальный округ Пуровский район"
  },
  {
    "id": "relation/1761330",
    "name": "муниципальный округ Тазовский район"
  },
  {
    "id": "relation/1761331",
    "name": "муниципальный округ Ямальский район"
  },
  {
    "id": "relation/1761332",
    "name": "городской округ Губкинский"
  },
  {
    "id": "relation/1761333",
    "name": "городской округ Лабытнанги"
  },
  {
    "id": "relation/1761334",
    "name": "городской округ Муравленко"
  },
  {
    "id": "relation/1761336",
    "name": "городской округ Салехард"
  },
  {
    "id": "relation/1963051",
    "name": "городской округ Ноябрьск"
  },
  {
    "id": "node/176957217"
  },
  {
    "id": "node/176960743"
  },
  {
    "id": "node/191897505"
  },
  {
    "id": "node/287337481"
  },
  {
    "id": "node/457894655"
  },
  {
    "id": "node/457970089"
  },
  {
    "id": "node/535141865"
  },
  {
    "id": "node/536380782"
  },
  {
    "id": "node/635936055"
  },
  {
    "id": "node/679619937"
  },
  {
    "id": "node/1214516212"
  },
  {
    "id": "node/1711559378"
  },
  {
    "id": "node/6568687418"
  }
]
;