export const names = [
  {
    "id": "relation/1059402",
    "name": "Алагирский район"
  },
  {
    "id": "relation/1060627",
    "name": "Дигорский район"
  },
  {
    "id": "relation/1060628",
    "name": "Кировский район"
  },
  {
    "id": "relation/1060629",
    "name": "Пригородный район"
  },
  {
    "id": "relation/1060630",
    "name": "Ардонский район"
  },
  {
    "id": "relation/1060631",
    "name": "Ирафский район"
  },
  {
    "id": "relation/1060632",
    "name": "Правобережный район"
  },
  {
    "id": "relation/1060633",
    "name": "городской округ Владикавказ"
  },
  {
    "id": "relation/1060634",
    "name": "Моздокский район"
  },
  {
    "id": "node/34395335"
  },
  {
    "id": "node/287516647"
  },
  {
    "id": "node/310022311"
  },
  {
    "id": "node/411082690"
  },
  {
    "id": "node/412963679"
  },
  {
    "id": "node/412992265"
  },
  {
    "id": "node/413040037"
  },
  {
    "id": "node/413331616"
  },
  {
    "id": "node/1168567868"
  }
]
;