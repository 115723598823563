export const names = [
  {
    "id": "relation/1650419",
    "name": "Охотский муниципальный округ"
  },
  {
    "id": "relation/1650458",
    "name": "Аяно-Майский район"
  },
  {
    "id": "relation/1651609",
    "name": "Николаевский район"
  },
  {
    "id": "relation/1651684",
    "name": "Амурский район"
  },
  {
    "id": "relation/1651685",
    "name": "Верхнебуреинский район"
  },
  {
    "id": "relation/1651686",
    "name": "район имени Полины Осипенко"
  },
  {
    "id": "relation/1651687",
    "name": "Солнечный район"
  },
  {
    "id": "relation/1651688",
    "name": "Тугуро-Чумиканский район"
  },
  {
    "id": "relation/1651689",
    "name": "Ульчский район"
  },
  {
    "id": "relation/1651725",
    "name": "Ванинский район"
  },
  {
    "id": "relation/1651726",
    "name": "Комсомольский район"
  },
  {
    "id": "relation/1651727",
    "name": "Нанайский район"
  },
  {
    "id": "relation/1651728",
    "name": "Советско-Гаванский район"
  },
  {
    "id": "relation/1651729",
    "name": "Хабаровский район"
  },
  {
    "id": "relation/1651737",
    "name": "Бикинский район"
  },
  {
    "id": "relation/1651738",
    "name": "Вяземский район"
  },
  {
    "id": "relation/1651739",
    "name": "район имени Лазо"
  },
  {
    "id": "relation/1782064",
    "name": "городской округ Хабаровск"
  },
  {
    "id": "relation/1788811",
    "name": "городской округ Комсомольск-на-Амуре"
  },
  {
    "id": "node/191754954"
  },
  {
    "id": "node/276994866"
  },
  {
    "id": "node/277060596"
  },
  {
    "id": "node/277060995"
  },
  {
    "id": "node/304663037"
  },
  {
    "id": "node/330401916"
  },
  {
    "id": "node/330823587"
  },
  {
    "id": "node/518743376"
  },
  {
    "id": "node/1028723755"
  },
  {
    "id": "node/1028724036"
  },
  {
    "id": "node/1028724073"
  },
  {
    "id": "node/1028724168"
  },
  {
    "id": "node/1028724277"
  },
  {
    "id": "node/1332198301"
  },
  {
    "id": "node/1712866108"
  },
  {
    "id": "node/1913939332"
  },
  {
    "id": "node/2173162616"
  }
]
;