import React, {useEffect} from 'react';
import {useAppDispatch, useAppSelector} from '../../hooks';
import {changeBreadCrumbs, changeRegionId, changeRegionName} from './store/home.slice';
import {RenderMap} from '../../components/map';
import {useNavigate} from 'react-router-dom';
import {Content, ContentHeader} from '../../layouts/template';
import {SelectS, TextS} from '../../fields';
import {BoxMap} from './styles';
import * as RU from '../../components/map/regionNames/RU';
import {useGetNameRegionsQuery} from './store/home.api';
import {changeSubjectRegionName} from '../regionMap/store/regionMap.slice';
import {SelectSects} from '../../components/boxSelectSects';
import {formatRegionId} from '../../helpers';
import {Chart} from '../../components/chart';
import {UseSvgHookOnClick} from '../../hooks/useSvgInteractive';
import {groupRegionByYear} from '../../helpers/groupRegionByYear';
import {SidebarWrapper} from '../../layouts';
import {Sidebar} from '../../components/sidebar';

export const Home = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const {data: listRegions} = useGetNameRegionsQuery();

    const {regionId: id, breadCrumbs, regionName} = useAppSelector(state => state.home);
    const {subjectRegionName, regions, selectedGroupIndex} = useAppSelector(state => state.regionMap);

    useEffect(() => {
        // if (breadCrumbs.length > 1) {
        //     dispatch(changeBreadCrumbs([defaultBreadCrumbs]));
        // }
        if (id !== 'rus') {
            dispatch(changeRegionName({regionName: 'Россия'}));
            dispatch(changeRegionId({regionId: 'rus'}));
        }
        if (subjectRegionName !== '') {
            dispatch(changeSubjectRegionName(''));
        }
    }, []);

    const handleRegionName = (name: string) => {
        dispatch(changeRegionName({regionName: name}));
    };
    const handleRegionId = ({regionId, name}: UseSvgHookOnClick) => {
        dispatch(changeRegionId({regionId}));
        if (typeof regionId === 'string') {
            dispatch(changeBreadCrumbs([...breadCrumbs, {
                path: `/region/${formatRegionId('removeRelation', regionId)}`,
                name
            }]));
            navigate(`/region/${formatRegionId('removeRelation', regionId)}`);
        }
    };


    const currentMapRegionName = id === 'rus' ? regionName : RU.names.find(i => i.id === id)?.name ?? '';
    // const barData = regions?.data?.map(i => ({value: i.Value, color: i.Color})) ?? [];
    // const data = regions?.data?.map(i => ({value: Number(i.Value.toFixed()), year: i.Year})) ?? [];

    const group = groupRegionByYear({regions: regions?.data || []});

    return (
        <>
            <Sidebar/>
            <Content>
                <ContentHeader>
                    <TextS
                        as={'span'}
                        fontSize={0.8}
                        fontWeight={600}
                        color={'var(--text-color)'}>{currentMapRegionName}</TextS>
                    <SelectS
                        showSearch
                        placeholder={'Регионы'}
                        tooltip={'Выбрать регион из списка'}
                        options={listRegions}
                        optionFilterProp="children"
                        filterOption={(input, option) => (option?.label.toUpperCase() ?? '').includes(input.toUpperCase())}
                    />
                </ContentHeader>
                <BoxMap>
                    <SelectSects/>
                    <RenderMap
                        regionId={id ?? ''}
                        onlyRu={true}
                        onHover={handleRegionName}
                        onClick={handleRegionId}/>

                    {group.length > 0
                        ? <Chart
                            width={740}
                            isMultiple={true}
                            data={selectedGroupIndex !== null ? [group[selectedGroupIndex]] : group}/>
                        : null}
                </BoxMap>
            </Content>
            <SidebarWrapper>

            </SidebarWrapper>
        </>
    );
};
