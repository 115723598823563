import React from 'react';
import {Route, Routes} from 'react-router-dom';
import {Public} from './routes';
import {Login} from './screens/ayth';
import {Private} from './routes/Private';
import {Home} from './screens/home';
import {Profile} from './screens/profile';
import {RegionMap} from './screens/regionMap';
import {RegionInfo} from './screens/regionInfo';

function App() {
    return (
        <Routes>
            <Route path={'/login'} element={<Public/>}>
                <Route path={''} element={<Login/>}/>
            </Route>
            <Route path={''} element={<Private/>}>
                <Route path={'/'} element={<Home/>}/>
                <Route path={'/profile'} element={<Profile/>}/>
                <Route path={'/region/:regionId'} element={<RegionMap/>}/>
                <Route path={'/region-info/:regionId'} element={<RegionInfo/>}/>
            </Route>
        </Routes>
    );
}

export default App;
