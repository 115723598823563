export const names = [
  {
    "id": "relation/11958110",
    "name": "Мариупольский район"
  },
  {
    "id": "relation/11970387",
    "name": "Бахмутский район"
  },
  {
    "id": "relation/11978037",
    "name": "Краматорский район"
  },
  {
    "id": "relation/11984324",
    "name": "Покровский район"
  },
  {
    "id": "relation/11984354",
    "name": "Волновахский район"
  },
  {
    "id": "relation/17937670",
    "name": "Горловский район"
  },
  {
    "id": "relation/17937787",
    "name": "Донецкий район"
  },
  {
    "id": "relation/17937854",
    "name": "Кальмиусский район"
  },
  {
    "id": "node/29980666"
  },
  {
    "id": "node/39682896"
  },
  {
    "id": "node/256613679"
  },
  {
    "id": "node/256613740"
  },
  {
    "id": "node/256613916"
  },
  {
    "id": "node/256615117"
  },
  {
    "id": "node/256618288"
  },
  {
    "id": "node/274929523"
  }
]
;