import React from 'react';
import {BoxSelectClose, BoxSelectSects, BoxText} from '../../screens/home/styles';
import {Close} from '../../assets/icons';
import {useAppDispatch, useAppSelector} from '../../hooks';
import {setSelectQuestion} from '../../screens/home/store/home.slice';
import {clearRegions} from '../../screens/regionMap/store/regionMap.slice';

export const SelectSects = () => {
    const dispatch = useAppDispatch();
    const {selectSchemaQuestion} = useAppSelector(state => state.home);

    const onReset = () => {
        dispatch(setSelectQuestion(null));
        dispatch(clearRegions());
    };

    return (
        <BoxSelectSects>
            {selectSchemaQuestion !== null
                ? <>
                    <BoxText>{selectSchemaQuestion.SectName}</BoxText>
                    <BoxSelectClose onClick={onReset}>
                        <Close/>
                    </BoxSelectClose>
                </>
                : null}
        </BoxSelectSects>
    );
};
