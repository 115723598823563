export function drawGrid(
    canvas: HTMLCanvasElement,
    ctx: CanvasRenderingContext2D,
    padding: number,
    width: number,
    height: number,
    minYear: number,
    maxYear: number,
    minValue: number,
    maxValue: number
): void {
    const numVerticalLines = maxYear - minYear + 1; // Количество вертикальных линий
    const numHorizontalLines = 10; // Количество горизонтальных линий

    // Рисуем вертикальные линии (по годам)
    for (let i = 0; i <= numVerticalLines; i++) {
        const x = padding + i * (width / numVerticalLines);
        ctx.beginPath();
        ctx.moveTo(x, padding);
        ctx.lineTo(x, canvas.height - padding);
        ctx.strokeStyle = '#e0e0e0';
        ctx.lineWidth = 1;
        ctx.stroke();
    }

    // Рисуем горизонтальные линии (по значениям)
    for (let i = 0; i <= numHorizontalLines; i++) {
        const y = canvas.height - padding - i * (height / numHorizontalLines);
        ctx.beginPath();
        ctx.moveTo(padding, y);
        ctx.lineTo(canvas.width - padding, y);
        ctx.strokeStyle = '#e0e0e0';
        ctx.lineWidth = 1;
        ctx.stroke();
    }
}
