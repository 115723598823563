export const names = [
  {
    "id": "relation/1793565",
    "name": "Джейрахский район"
  },
  {
    "id": "relation/1793566",
    "name": "Малгобекский район"
  },
  {
    "id": "relation/1793567",
    "name": "Назрановский район"
  },
  {
    "id": "relation/1793568",
    "name": "Сунженский район"
  },
  {
    "id": "relation/1793569",
    "name": "городской округ Карабулак"
  },
  {
    "id": "relation/1793570",
    "name": "городской округ Магас"
  },
  {
    "id": "relation/1793571",
    "name": "городской округ Малгобек"
  },
  {
    "id": "relation/1793572",
    "name": "городской округ Назрань"
  },
  {
    "id": "relation/3855483",
    "name": "городской округ Сунжа"
  },
  {
    "id": "node/34395338"
  },
  {
    "id": "node/34395341"
  },
  {
    "id": "node/287798315"
  },
  {
    "id": "node/1606998985"
  },
  {
    "id": "node/5440514035"
  },
  {
    "id": "node/5914501275"
  }
]
;