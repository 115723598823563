import React from 'react';
import {DataHead, DataInfoCol, DataInfoRow, PointColor, TitleRow, TitleWrapper} from './styled';
import {useAppDispatch, useAppSelector} from '../../hooks';
import {Collapse, TextS} from '../../fields';
import {groupByName} from '../../helpers';
import {setSelectedGroupIndex} from '../../screens/regionMap/store/regionMap.slice';
import {SidebarWrapper} from '../../layouts';


const Title = (
    {
        name, ie, value
    }: {
        name: string;
        value: number;
        ie: string
    }) => {
    return (
        <TitleWrapper>
            <TextS
                as={'span'}
                fontSize={0.8}
                fontWeight={400}
                color={'var(--title-light-color)'}
            >{name}</TextS>
            <TitleRow>
                <TextS
                    as={'span'}
                    fontSize={0.6}
                    fontWeight={400}
                    color={'var(--text-color)'}
                    style={{marginRight: '5px'}}
                >{value.toFixed(2)}</TextS>
                {' '}
                <TextS
                    as={'span'}
                    fontSize={0.6}
                    fontWeight={300}
                    color={'var(--text-color)'}
                >{ie}</TextS>
            </TitleRow>
        </TitleWrapper>
    );
};

export const DataColumn = () => {
    const dispatch = useAppDispatch();
    const {regions, selectedGroupIndex} = useAppSelector(state => state.regionMap);

    const handleSelectGroup = (index: number) => {
        dispatch(setSelectedGroupIndex(index === selectedGroupIndex ? null : index));
    };

    const group = groupByName(regions?.data ?? []);
    const IE = group.length > 0 ? group.map(i => i.items)[0].map(i => i.EI).pop() : '';

    if (group.length === 0) {
        return <></>;
    }

    return (
        <SidebarWrapper>
            <DataHead>
                <TextS
                    as={'span'}
                    fontSize={0.8}
                    fontWeight={300}
                    color={'var(--text-color)'}
                >
                    Всего:
                </TextS>
                <TextS
                    as={'span'}
                    fontSize={0.6}
                    fontWeight={300}
                    color={'var(--text-color)'}
                >
                    {regions.totalValues.toFixed(1)} {IE}
                </TextS>
            </DataHead>

            {group.map((group, idx) => {
                const total = group.items.map(i => i.Value).reduce((acc, i) => acc + i, 0);
                return (
                    <Collapse
                        key={idx}
                        buttonText={`${group.name} ${total.toFixed(2)}`}
                        fontSize={0.8}
                        border={true}
                        showIcon={true}
                        isInner={false}
                        open={selectedGroupIndex === idx}
                        renderTitle={<Title name={group.name} value={total} ie={String(IE)}/>}
                        onClick={() => handleSelectGroup(idx)}>
                        {group.items.map((item, index) => (
                            <DataInfoRow key={index}>
                                <DataInfoCol>
                                    <TextS
                                        as={'span'}
                                        fontSize={0.6}
                                        fontWeight={300}
                                        color={'var(--text-color)'}
                                    >{item.Year} г.</TextS>
                                </DataInfoCol>
                                <DataInfoCol>
                                    <TextS
                                        as={'span'}
                                        fontSize={0.6}
                                        fontWeight={300}
                                        color={'var(--text-color)'}
                                    >
                                        {item.Display} {item.EI}
                                    </TextS>
                                </DataInfoCol>
                                <DataInfoCol>
                                    <PointColor color={item.Color}/>
                                </DataInfoCol>
                            </DataInfoRow>
                        ))}
                    </Collapse>
                );
            })}
        </SidebarWrapper>
    );
};
