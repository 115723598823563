export const names = [
  {
    "id": "relation/398426",
    "name": "Петуховский муниципальный округ"
  },
  {
    "id": "relation/398427",
    "name": "городской округ Курган"
  },
  {
    "id": "relation/398428",
    "name": "городской округ Шадринск"
  },
  {
    "id": "relation/398429",
    "name": "Макушинский муниципальный округ"
  },
  {
    "id": "relation/398430",
    "name": "Частоозерский муниципальный округ"
  },
  {
    "id": "relation/398431",
    "name": "Кетовский муниципальный округ"
  },
  {
    "id": "relation/398432",
    "name": "Мишкинский муниципальный округ"
  },
  {
    "id": "relation/398433",
    "name": "Шадринский муниципальный округ"
  },
  {
    "id": "relation/398434",
    "name": "Белозерский муниципальный округ"
  },
  {
    "id": "relation/398435",
    "name": "Звериноголовский муниципальный округ"
  },
  {
    "id": "relation/398436",
    "name": "Далматовский муниципальный округ"
  },
  {
    "id": "relation/398437",
    "name": "Катайский муниципальный округ"
  },
  {
    "id": "relation/398438",
    "name": "Куртамышский муниципальный округ"
  },
  {
    "id": "relation/398439",
    "name": "Шумихинский муниципальный округ"
  },
  {
    "id": "relation/398440",
    "name": "Юргамышский муниципальный округ"
  },
  {
    "id": "relation/398441",
    "name": "Щучанский муниципальный округ"
  },
  {
    "id": "relation/398442",
    "name": "Каргапольский муниципальный округ"
  },
  {
    "id": "relation/398443",
    "name": "Шатровский муниципальный округ"
  },
  {
    "id": "relation/398444",
    "name": "Мокроусовский муниципальный округ"
  },
  {
    "id": "relation/398445",
    "name": "Целинный муниципальный округ"
  },
  {
    "id": "relation/398446",
    "name": "Лебяжьевский муниципальный округ"
  },
  {
    "id": "relation/398447",
    "name": "Альменевский муниципальный округ"
  },
  {
    "id": "relation/398448",
    "name": "Половинский муниципальный округ"
  },
  {
    "id": "relation/398449",
    "name": "Варгашинский муниципальный округ"
  },
  {
    "id": "relation/398450",
    "name": "Сафакулевский муниципальный округ"
  },
  {
    "id": "relation/398451",
    "name": "Притобольный муниципальный округ"
  },
  {
    "id": "node/179374935"
  },
  {
    "id": "node/243836016"
  },
  {
    "id": "node/420327055"
  },
  {
    "id": "node/432593615"
  },
  {
    "id": "node/569518115"
  },
  {
    "id": "node/583588924"
  },
  {
    "id": "node/583717935"
  },
  {
    "id": "node/583721589"
  },
  {
    "id": "node/583721641"
  },
  {
    "id": "node/591773116"
  },
  {
    "id": "node/591773136"
  },
  {
    "id": "node/591773144"
  },
  {
    "id": "node/591773189"
  },
  {
    "id": "node/591773200"
  },
  {
    "id": "node/600671079"
  },
  {
    "id": "node/622042482"
  },
  {
    "id": "node/804635670"
  },
  {
    "id": "node/865923188"
  },
  {
    "id": "node/890085038"
  },
  {
    "id": "node/990895081"
  },
  {
    "id": "node/1214196377"
  },
  {
    "id": "node/1315840274"
  },
  {
    "id": "node/1357711197"
  },
  {
    "id": "node/3189643211"
  },
  {
    "id": "node/3889268056"
  }
]
;