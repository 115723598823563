import React, {ReactNode} from 'react';
import {ModalOverlay, ModalS, ModalWrapper} from './styled';

interface ModalProps {
    open: boolean;
    onClose: () => void;
    children: ReactNode;
}

export const Modal = ({open, onClose, children}: ModalProps) => {
    if (!open) {
        return null;
    }
    return (
        <ModalWrapper>
            <ModalOverlay onClick={onClose}/>
            <ModalS>
                {children}
            </ModalS>
        </ModalWrapper>
    );
};
