export const names = [
  {
    "id": "relation/389791",
    "name": "городской округ Кохма"
  },
  {
    "id": "relation/389792",
    "name": "городской округ Шуя"
  },
  {
    "id": "relation/389793",
    "name": "городской округ Вичуга"
  },
  {
    "id": "relation/389794",
    "name": "городской округ Тейково"
  },
  {
    "id": "relation/389795",
    "name": "городской округ Кинешма"
  },
  {
    "id": "relation/389796",
    "name": "Заволжский район"
  },
  {
    "id": "relation/389797",
    "name": "Кинешемский район"
  },
  {
    "id": "relation/389798",
    "name": "Юрьевецкий район"
  },
  {
    "id": "relation/389799",
    "name": "Пучежский район"
  },
  {
    "id": "relation/389800",
    "name": "Пестяковский район"
  },
  {
    "id": "relation/389801",
    "name": "Верхнеландеховский район"
  },
  {
    "id": "relation/389802",
    "name": "Лухский район"
  },
  {
    "id": "relation/389803",
    "name": "Палехский район"
  },
  {
    "id": "relation/389804",
    "name": "Южский район"
  },
  {
    "id": "relation/389805",
    "name": "Савинский район"
  },
  {
    "id": "relation/389806",
    "name": "Шуйский район"
  },
  {
    "id": "relation/389807",
    "name": "Родниковский район"
  },
  {
    "id": "relation/389808",
    "name": "Вичугский район"
  },
  {
    "id": "relation/389809",
    "name": "Приволжский район"
  },
  {
    "id": "relation/389810",
    "name": "Фурмановский район"
  },
  {
    "id": "relation/389811",
    "name": "Ивановский район"
  },
  {
    "id": "relation/389812",
    "name": "Лежневский район"
  },
  {
    "id": "relation/389813",
    "name": "Тейковский район"
  },
  {
    "id": "relation/389814",
    "name": "Комсомольский район"
  },
  {
    "id": "relation/389815",
    "name": "Ильинский район"
  },
  {
    "id": "relation/389816",
    "name": "Гаврилово-Посадский район"
  },
  {
    "id": "relation/543140",
    "name": "городской округ Иваново"
  },
  {
    "id": "node/148901125"
  },
  {
    "id": "node/295896340"
  },
  {
    "id": "node/356482634"
  },
  {
    "id": "node/356482665"
  },
  {
    "id": "node/356482697"
  },
  {
    "id": "node/356482705"
  },
  {
    "id": "node/356482716"
  },
  {
    "id": "node/356482727"
  },
  {
    "id": "node/356482735"
  },
  {
    "id": "node/356482739"
  },
  {
    "id": "node/356482756"
  },
  {
    "id": "node/356482761"
  },
  {
    "id": "node/356482792"
  },
  {
    "id": "node/356482812"
  },
  {
    "id": "node/356482828"
  },
  {
    "id": "node/356482846"
  },
  {
    "id": "node/356482858"
  },
  {
    "id": "node/358898770"
  },
  {
    "id": "node/672673962"
  },
  {
    "id": "node/672676889"
  },
  {
    "id": "node/988052228"
  },
  {
    "id": "node/1006718396"
  }
]
;