export const names = [
  {
    "id": "relation/190356",
    "name": "Каа-Хемский кожуун"
  },
  {
    "id": "relation/190516",
    "name": "Овюрский кожуун"
  },
  {
    "id": "relation/190555",
    "name": "Бай-Тайгинский кожуун"
  },
  {
    "id": "relation/190558",
    "name": "Барун-Хемчикский кожуун"
  },
  {
    "id": "relation/190559",
    "name": "Дзун-Хемчикский кожуун"
  },
  {
    "id": "relation/190561",
    "name": "Монгун-Тайгинский кожуун"
  },
  {
    "id": "relation/190576",
    "name": "Сут-Хольский кожуун"
  },
  {
    "id": "relation/190738",
    "name": "Тандинский кожуун"
  },
  {
    "id": "relation/190742",
    "name": "Тере-Хольский кожуун"
  },
  {
    "id": "relation/190744",
    "name": "Тес-Хемский кожуун"
  },
  {
    "id": "relation/190745",
    "name": "Тоджинский кожуун"
  },
  {
    "id": "relation/190748",
    "name": "Улуг-Хемский кожуун"
  },
  {
    "id": "relation/190751",
    "name": "Чаа-Хольский кожуун"
  },
  {
    "id": "relation/190752",
    "name": "Чеди-Хольский кожуун"
  },
  {
    "id": "relation/190753",
    "name": "Эрзинский кожуун"
  },
  {
    "id": "relation/190766",
    "name": "Пий-Хемский кожуун"
  },
  {
    "id": "relation/1547276",
    "name": "Кызылский кожуун"
  },
  {
    "id": "relation/1550547",
    "name": "городской округ Ак-Довурак"
  },
  {
    "id": "relation/1551182",
    "name": "городской округ Кызыл"
  },
  {
    "id": "node/191655065"
  },
  {
    "id": "node/286084866"
  },
  {
    "id": "node/286085202"
  },
  {
    "id": "node/286085444"
  },
  {
    "id": "node/286085489"
  },
  {
    "id": "node/286130437"
  },
  {
    "id": "node/286200781"
  },
  {
    "id": "node/286203959"
  },
  {
    "id": "node/286206557"
  },
  {
    "id": "node/456960757"
  },
  {
    "id": "node/456994200"
  },
  {
    "id": "node/457113558"
  },
  {
    "id": "node/457337396"
  },
  {
    "id": "node/457340176"
  },
  {
    "id": "node/457342402"
  },
  {
    "id": "node/457350662"
  },
  {
    "id": "node/457351057"
  },
  {
    "id": "node/457351426"
  },
  {
    "id": "node/457354065"
  }
]
;