export const names = [
  {
    "id": "relation/1029091",
    "name": "Курский муниципальный округ"
  },
  {
    "id": "relation/1029107",
    "name": "Кировский муниципальный округ"
  },
  {
    "id": "relation/1029147",
    "name": "Степновский муниципальный округ"
  },
  {
    "id": "relation/1029150",
    "name": "Красногвардейский муниципальный округ"
  },
  {
    "id": "relation/1029981",
    "name": "Ипатовский муниципальный округ"
  },
  {
    "id": "relation/1029994",
    "name": "Апанасенковский муниципальный округ"
  },
  {
    "id": "relation/1030004",
    "name": "Новоалександровский муниципальный округ"
  },
  {
    "id": "relation/1030006",
    "name": "Труновский муниципальный округ"
  },
  {
    "id": "relation/1030016",
    "name": "Изобильненский муниципальный округ"
  },
  {
    "id": "relation/1030020",
    "name": "Грачёвский муниципальный округ"
  },
  {
    "id": "relation/1030030",
    "name": "Петровский муниципальный округ"
  },
  {
    "id": "relation/1030039",
    "name": "Туркменский муниципальный округ"
  },
  {
    "id": "relation/1030040",
    "name": "Арзгирский муниципальный округ"
  },
  {
    "id": "relation/1030057",
    "name": "Шпаковский муниципальный округ"
  },
  {
    "id": "relation/1030059",
    "name": "городской округ Ставрополь"
  },
  {
    "id": "relation/1030063",
    "name": "Благодарненский муниципальный округ"
  },
  {
    "id": "relation/1030065",
    "name": "Левокумский муниципальный округ"
  },
  {
    "id": "relation/1030066",
    "name": "Кочубеевский муниципальный округ"
  },
  {
    "id": "relation/1030099",
    "name": "городской округ Невинномысск"
  },
  {
    "id": "relation/1030115",
    "name": "Андроповский муниципальный округ"
  },
  {
    "id": "relation/1030121",
    "name": "Александровский муниципальный округ"
  },
  {
    "id": "relation/1030124",
    "name": "Новоселицкий муниципальный округ"
  },
  {
    "id": "relation/1030136",
    "name": "Будённовский муниципальный округ"
  },
  {
    "id": "relation/1030137",
    "name": "Нефтекумский муниципальный округ"
  },
  {
    "id": "relation/1030142",
    "name": "Минераловодский муниципальный округ"
  },
  {
    "id": "relation/1030145",
    "name": "Георгиевский муниципальный округ"
  },
  {
    "id": "relation/1030149",
    "name": "Советский муниципальный округ"
  },
  {
    "id": "relation/1030156",
    "name": "Предгорный муниципальный округ"
  },
  {
    "id": "relation/1030984",
    "name": "городской округ Ессентуки"
  },
  {
    "id": "relation/1030985",
    "name": "городской округ Железноводск"
  },
  {
    "id": "relation/1030986",
    "name": "городской округ Лермонтов"
  },
  {
    "id": "relation/1030988",
    "name": "городской округ Пятигорск"
  },
  {
    "id": "relation/1030989",
    "name": "городской округ Кисловодск"
  },
  {
    "id": "node/36714827"
  },
  {
    "id": "node/160258541"
  },
  {
    "id": "node/191872996"
  },
  {
    "id": "node/191875199"
  },
  {
    "id": "node/270529337"
  },
  {
    "id": "node/270531692"
  },
  {
    "id": "node/356773279"
  },
  {
    "id": "node/356773292"
  },
  {
    "id": "node/356773322"
  },
  {
    "id": "node/356773398"
  },
  {
    "id": "node/356773413"
  },
  {
    "id": "node/356773431"
  },
  {
    "id": "node/356773439"
  },
  {
    "id": "node/356773478"
  },
  {
    "id": "node/356773582"
  },
  {
    "id": "node/356773637"
  },
  {
    "id": "node/493613381"
  },
  {
    "id": "node/570766098"
  },
  {
    "id": "node/683814442"
  },
  {
    "id": "node/684380772"
  },
  {
    "id": "node/684455703"
  },
  {
    "id": "node/690825550"
  },
  {
    "id": "node/700072537"
  },
  {
    "id": "node/712451520"
  },
  {
    "id": "node/712604678"
  },
  {
    "id": "node/806869016"
  },
  {
    "id": "node/1028144158"
  },
  {
    "id": "node/1856336937"
  },
  {
    "id": "node/1871518036"
  },
  {
    "id": "node/1879354147"
  },
  {
    "id": "node/3033924842"
  },
  {
    "id": "node/4456067092"
  },
  {
    "id": "node/5597337313"
  }
]
;