export const names = [
  {
    "id": "relation/1431997",
    "name": "городской округ Железногорск"
  },
  {
    "id": "relation/1645639",
    "name": "Беловский район"
  },
  {
    "id": "relation/1645640",
    "name": "Большесолдатский район"
  },
  {
    "id": "relation/1645641",
    "name": "Глушковский район"
  },
  {
    "id": "relation/1645642",
    "name": "Дмитриевский район"
  },
  {
    "id": "relation/1645643",
    "name": "Железногорский район"
  },
  {
    "id": "relation/1645644",
    "name": "Конышёвский район"
  },
  {
    "id": "relation/1645645",
    "name": "Кореневский район"
  },
  {
    "id": "relation/1645646",
    "name": "городской округ Курчатов"
  },
  {
    "id": "relation/1645647",
    "name": "Курчатовский район"
  },
  {
    "id": "relation/1645648",
    "name": "городской округ Льгов"
  },
  {
    "id": "relation/1645649",
    "name": "Льговский район"
  },
  {
    "id": "relation/1645650",
    "name": "Рыльский район"
  },
  {
    "id": "relation/1645651",
    "name": "Суджанский район"
  },
  {
    "id": "relation/1645652",
    "name": "Хомутовский район"
  },
  {
    "id": "relation/1646077",
    "name": "Тимский район"
  },
  {
    "id": "relation/1646078",
    "name": "Октябрьский район"
  },
  {
    "id": "relation/1646079",
    "name": "Мантуровский район"
  },
  {
    "id": "relation/1646080",
    "name": "Щигровский район"
  },
  {
    "id": "relation/1646081",
    "name": "Солнцевский район"
  },
  {
    "id": "relation/1646082",
    "name": "Горшеченский район"
  },
  {
    "id": "relation/1646083",
    "name": "Медвенский район"
  },
  {
    "id": "relation/1646084",
    "name": "Касторенский район"
  },
  {
    "id": "relation/1646085",
    "name": "Черемисиновский район"
  },
  {
    "id": "relation/1646086",
    "name": "Пристенский район"
  },
  {
    "id": "relation/1646087",
    "name": "городской округ Щигры"
  },
  {
    "id": "relation/1646088",
    "name": "Обоянский район"
  },
  {
    "id": "relation/1646089",
    "name": "Золотухинский район"
  },
  {
    "id": "relation/1646090",
    "name": "городской округ Курск"
  },
  {
    "id": "relation/1646091",
    "name": "Фатежский район"
  },
  {
    "id": "relation/1646092",
    "name": "Советский район"
  },
  {
    "id": "relation/1646093",
    "name": "Курский район"
  },
  {
    "id": "relation/1646094",
    "name": "Поныровский район"
  },
  {
    "id": "node/27090043"
  },
  {
    "id": "node/336140317"
  },
  {
    "id": "node/336140367"
  },
  {
    "id": "node/336140369"
  },
  {
    "id": "node/336140440"
  },
  {
    "id": "node/336140448"
  },
  {
    "id": "node/336140452"
  },
  {
    "id": "node/336140471"
  },
  {
    "id": "node/336140489"
  },
  {
    "id": "node/336140540"
  },
  {
    "id": "node/336140550"
  },
  {
    "id": "node/336140608"
  },
  {
    "id": "node/336140616"
  },
  {
    "id": "node/336140628"
  },
  {
    "id": "node/336140636"
  },
  {
    "id": "node/336140669"
  },
  {
    "id": "node/336140670"
  },
  {
    "id": "node/336140693"
  },
  {
    "id": "node/336140698"
  },
  {
    "id": "node/336140701"
  },
  {
    "id": "node/336140755"
  },
  {
    "id": "node/336140766"
  },
  {
    "id": "node/383335227"
  },
  {
    "id": "node/422261659"
  },
  {
    "id": "node/425219949"
  },
  {
    "id": "node/1025597933"
  },
  {
    "id": "node/1399630435"
  },
  {
    "id": "node/1420804667"
  }
]
;