import styled from 'styled-components';

export const InputS = styled.input.withConfig({
    shouldForwardProp: prop => !['isError'].includes(prop)
})<{ isError: boolean }>`
  width: 100%;
  padding: 10px;
  border: 1px solid ${({isError}) => isError ? 'var(--alert-color)' : 'var(--border-color)'};
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  color: var(--text-color);

  &::placeholder {
    color: var(--light-color);
  }
`;
