export const names = [
  {
    "id": "relation/1778077",
    "name": "Александровск-Сахалинский район"
  },
  {
    "id": "relation/1778078",
    "name": "Анивский городской округ"
  },
  {
    "id": "relation/1778079",
    "name": "Долинский городской округ"
  },
  {
    "id": "relation/1778080",
    "name": "Корсаковский городской округ"
  },
  {
    "id": "relation/1778081",
    "name": "Курильский городской округ"
  },
  {
    "id": "relation/1778082",
    "name": "Макаровский городской округ"
  },
  {
    "id": "relation/1778083",
    "name": "Невельский городской округ"
  },
  {
    "id": "relation/1778084",
    "name": "Ногликский городской округ"
  },
  {
    "id": "relation/1778085",
    "name": "Охинский городской округ"
  },
  {
    "id": "relation/1778086",
    "name": "Поронайский городской округ"
  },
  {
    "id": "relation/1778087"
  },
  {
    "id": "relation/1778089",
    "name": "Томаринский городской округ"
  },
  {
    "id": "relation/1778090",
    "name": "Тымовский городской округ"
  },
  {
    "id": "relation/1778091",
    "name": "Углегорский городской округ"
  },
  {
    "id": "relation/1778092",
    "name": "Холмский городской округ"
  },
  {
    "id": "relation/1778093",
    "name": "Южно-Курильский городской округ"
  },
  {
    "id": "relation/1778094",
    "name": "городской округ Южно-Сахалинск"
  },
  {
    "id": "relation/3667580",
    "name": "Смирныховский городской округ"
  },
  {
    "id": "node/191723030"
  },
  {
    "id": "node/286560902"
  },
  {
    "id": "node/360790120"
  },
  {
    "id": "node/360790153"
  },
  {
    "id": "node/360790301"
  },
  {
    "id": "node/360790385"
  },
  {
    "id": "node/360790488"
  },
  {
    "id": "node/360790596"
  },
  {
    "id": "node/360790636"
  },
  {
    "id": "node/360790752"
  },
  {
    "id": "node/360790819"
  },
  {
    "id": "node/360790875"
  },
  {
    "id": "node/360790972"
  },
  {
    "id": "node/360791081"
  },
  {
    "id": "node/983942465"
  },
  {
    "id": "node/1108170436"
  },
  {
    "id": "node/1373610125"
  },
  {
    "id": "node/1457197051"
  }
]
;