export const names = [
  {
    "id": "relation/421732",
    "name": "городской округ Сыктывкар"
  },
  {
    "id": "relation/1047266",
    "name": "Троицко-Печорский район"
  },
  {
    "id": "relation/1082923",
    "name": "Корткеросский район"
  },
  {
    "id": "relation/1082924",
    "name": "муниципальный округ Усинск"
  },
  {
    "id": "relation/1082925",
    "name": "Усть-Куломский район"
  },
  {
    "id": "relation/1082926",
    "name": "городской округ Воркута"
  },
  {
    "id": "relation/1082927",
    "name": "Удорский район"
  },
  {
    "id": "relation/1082928",
    "name": "Ижемский район"
  },
  {
    "id": "relation/1082929",
    "name": "Койгородский район"
  },
  {
    "id": "relation/1082930",
    "name": "Сыктывдинский район"
  },
  {
    "id": "relation/1082931",
    "name": "Прилузский район"
  },
  {
    "id": "relation/1082932",
    "name": "муниципальный округ Ухта"
  },
  {
    "id": "relation/1082933",
    "name": "район Печора"
  },
  {
    "id": "relation/1082934",
    "name": "Усть-Цилемский район"
  },
  {
    "id": "relation/1082935",
    "name": "городской округ Инта"
  },
  {
    "id": "relation/1082936",
    "name": "Усть-Вымский район"
  },
  {
    "id": "relation/1082937",
    "name": "муниципальный округ Вуктыл"
  },
  {
    "id": "relation/1082938",
    "name": "Сысольский район"
  },
  {
    "id": "relation/1082939",
    "name": "Княжпогостский район"
  },
  {
    "id": "relation/1082940",
    "name": "район Сосногорск"
  },
  {
    "id": "node/34506389"
  },
  {
    "id": "node/191650708"
  },
  {
    "id": "node/191909841"
  },
  {
    "id": "node/330842003"
  },
  {
    "id": "node/331793608"
  },
  {
    "id": "node/335978319"
  },
  {
    "id": "node/335978361"
  },
  {
    "id": "node/335978390"
  },
  {
    "id": "node/335978406"
  },
  {
    "id": "node/335978447"
  },
  {
    "id": "node/335978558"
  },
  {
    "id": "node/335978609"
  },
  {
    "id": "node/344035312"
  },
  {
    "id": "node/457173591"
  },
  {
    "id": "node/457190064"
  },
  {
    "id": "node/500855169"
  },
  {
    "id": "node/502134324"
  },
  {
    "id": "node/1621509798"
  },
  {
    "id": "node/2578815250"
  },
  {
    "id": "node/2716780362"
  }
]
;