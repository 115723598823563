export type profileStore = {
    data: User;
};

export const defaultUserData = {
    token: '',
    refreshToken: '',
    notificationToken: '',
    id_con: '',
    id: 0,
    login: '',
    name: 'Тихонов Виктор',
    email: '',
};

export type User = {
    token: string;
    refreshToken: string | null;
    notificationToken: string | null;
    id_con: string;
    id: number;
    login: string;
    name: string;
    email: string | null
}
