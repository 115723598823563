export const names = [
  {
    "id": "relation/1761218",
    "name": "Алатырский муниципальный округ"
  },
  {
    "id": "relation/1761219",
    "name": "Аликовский муниципальный округ"
  },
  {
    "id": "relation/1761220",
    "name": "Батыревский муниципальный округ"
  },
  {
    "id": "relation/1761221",
    "name": "Вурнарский муниципальный округ"
  },
  {
    "id": "relation/1761222",
    "name": "городской округ Алатырь"
  },
  {
    "id": "relation/1761223",
    "name": "Ибресинский муниципальный округ"
  },
  {
    "id": "relation/1761224",
    "name": "Канашский муниципальный округ"
  },
  {
    "id": "relation/1761225",
    "name": "Козловский муниципальный округ"
  },
  {
    "id": "relation/1761226",
    "name": "Комсомольский муниципальный округ"
  },
  {
    "id": "relation/1761227",
    "name": "Красноармейский муниципальный округ"
  },
  {
    "id": "relation/1761228",
    "name": "Красночетайский муниципальный округ"
  },
  {
    "id": "relation/1761229",
    "name": "Мариинско-Посадский муниципальный округ"
  },
  {
    "id": "relation/1761230",
    "name": "Моргаушский муниципальный округ"
  },
  {
    "id": "relation/1761231",
    "name": "Порецкий муниципальный округ"
  },
  {
    "id": "relation/1761232",
    "name": "Урмарский муниципальный округ"
  },
  {
    "id": "relation/1761233",
    "name": "Цивильский муниципальный округ"
  },
  {
    "id": "relation/1761234",
    "name": "Чебоксарский муниципальный округ"
  },
  {
    "id": "relation/1761235",
    "name": "Шемуршинский муниципальный округ"
  },
  {
    "id": "relation/1761236",
    "name": "Шумерлинский муниципальный округ"
  },
  {
    "id": "relation/1761237",
    "name": "Ядринский муниципальный округ"
  },
  {
    "id": "relation/1761238",
    "name": "Яльчикский муниципальный округ"
  },
  {
    "id": "relation/1761239",
    "name": "Янтиковский муниципальный округ"
  },
  {
    "id": "relation/1761240",
    "name": "городской округ Канаш"
  },
  {
    "id": "relation/1761241",
    "name": "городской округ Новочебоксарск"
  },
  {
    "id": "relation/1761243",
    "name": "городской округ Шумерля"
  },
  {
    "id": "relation/1810276",
    "name": "городской округ Чебоксары"
  },
  {
    "id": "node/191879312"
  },
  {
    "id": "node/296134100"
  },
  {
    "id": "node/336524089"
  },
  {
    "id": "node/336524379"
  },
  {
    "id": "node/336524477"
  },
  {
    "id": "node/336524720"
  },
  {
    "id": "node/355658943"
  },
  {
    "id": "node/382547924"
  },
  {
    "id": "node/634802483"
  },
  {
    "id": "node/740732715"
  },
  {
    "id": "node/850408359"
  },
  {
    "id": "node/851505929"
  },
  {
    "id": "node/852548149"
  },
  {
    "id": "node/853525380"
  },
  {
    "id": "node/853568231"
  },
  {
    "id": "node/854182620"
  },
  {
    "id": "node/855155287"
  },
  {
    "id": "node/855192927"
  },
  {
    "id": "node/856139011"
  },
  {
    "id": "node/906795109"
  },
  {
    "id": "node/2288716213"
  },
  {
    "id": "node/2302513506"
  },
  {
    "id": "node/5255951743"
  }
]
;