export const names = [
  {
    "id": "relation/11857100",
    "name": "Запорожский район"
  },
  {
    "id": "relation/11857109",
    "name": "Пологовский район"
  },
  {
    "id": "relation/11857351",
    "name": "Бердянский район"
  },
  {
    "id": "relation/11872881",
    "name": "Мелитопольский район"
  },
  {
    "id": "relation/11872898",
    "name": "Васильевский район"
  },
  {
    "id": "node/27122612"
  },
  {
    "id": "node/258057686"
  },
  {
    "id": "node/337676690"
  },
  {
    "id": "node/1557426211"
  },
  {
    "id": "node/1756064266"
  }
]
;