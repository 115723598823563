export const names = [
  {
    "id": "relation/287507",
    "name": "городской округ Самара"
  },
  {
    "id": "relation/309111",
    "name": "Кинель-Черкасский район"
  },
  {
    "id": "relation/1244855",
    "name": "Алексеевский район"
  },
  {
    "id": "relation/1245501",
    "name": "Приволжский район"
  },
  {
    "id": "relation/1252015",
    "name": "Хворостянский район"
  },
  {
    "id": "relation/1253410",
    "name": "Красноармейский район"
  },
  {
    "id": "relation/1263373",
    "name": "городской округ Новокуйбышевск"
  },
  {
    "id": "relation/1270388",
    "name": "Красноярский район"
  },
  {
    "id": "relation/1332843",
    "name": "Похвистневский район"
  },
  {
    "id": "relation/1337712",
    "name": "Клявлинский район"
  },
  {
    "id": "relation/1337713",
    "name": "Камышлинский район"
  },
  {
    "id": "relation/1337717",
    "name": "Нефтегорский район"
  },
  {
    "id": "relation/1337718",
    "name": "Борский район"
  },
  {
    "id": "relation/1338341",
    "name": "Пестравский район"
  },
  {
    "id": "relation/1338342",
    "name": "Большечерниговский район"
  },
  {
    "id": "relation/1338382",
    "name": "Большеглушицкий район"
  },
  {
    "id": "relation/1338383",
    "name": "Кинельский район"
  },
  {
    "id": "relation/1338384",
    "name": "Богатовский район"
  },
  {
    "id": "relation/1338392",
    "name": "Исаклинский район"
  },
  {
    "id": "relation/1338520",
    "name": "Шенталинский район"
  },
  {
    "id": "relation/1338521",
    "name": "Кошкинский район"
  },
  {
    "id": "relation/1338522",
    "name": "Челно-Вершинский район"
  },
  {
    "id": "relation/1338531",
    "name": "Сергиевский район"
  },
  {
    "id": "relation/1340131",
    "name": "Елховский район"
  },
  {
    "id": "relation/1340132",
    "name": "Волжский район"
  },
  {
    "id": "relation/1340153",
    "name": "Сызранский район"
  },
  {
    "id": "relation/1340154",
    "name": "Шигонский район"
  },
  {
    "id": "relation/1340540",
    "name": "Ставропольский район"
  },
  {
    "id": "relation/1345135",
    "name": "городской округ Тольятти"
  },
  {
    "id": "relation/1345136",
    "name": "городской округ Чапаевск"
  },
  {
    "id": "relation/1345137",
    "name": "Безенчукский район"
  },
  {
    "id": "relation/1345138",
    "name": "городской округ Жигулёвск"
  },
  {
    "id": "relation/1345142",
    "name": "городской округ Похвистнево"
  },
  {
    "id": "relation/1345143",
    "name": "городской округ Сызрань"
  },
  {
    "id": "relation/1345144",
    "name": "городской округ Кинель"
  },
  {
    "id": "relation/1345145",
    "name": "городской округ Отрадный"
  },
  {
    "id": "relation/1345146",
    "name": "городской округ Октябрьск"
  },
  {
    "id": "node/27503945"
  },
  {
    "id": "node/27505046"
  },
  {
    "id": "node/191896570"
  },
  {
    "id": "node/277531139"
  },
  {
    "id": "node/308259963"
  },
  {
    "id": "node/308801345"
  },
  {
    "id": "node/308897102"
  },
  {
    "id": "node/336525541"
  },
  {
    "id": "node/336525587"
  },
  {
    "id": "node/336525933"
  },
  {
    "id": "node/336526294"
  },
  {
    "id": "node/336526375"
  },
  {
    "id": "node/336526531"
  },
  {
    "id": "node/337839285"
  },
  {
    "id": "node/337839295"
  },
  {
    "id": "node/337839323"
  },
  {
    "id": "node/337839326"
  },
  {
    "id": "node/337839371"
  },
  {
    "id": "node/337839448"
  },
  {
    "id": "node/337839503"
  },
  {
    "id": "node/337839599"
  },
  {
    "id": "node/337839647"
  },
  {
    "id": "node/344967823"
  },
  {
    "id": "node/760308990"
  },
  {
    "id": "node/1028724158"
  },
  {
    "id": "node/1028724310"
  },
  {
    "id": "node/1029249537"
  },
  {
    "id": "node/1030445849"
  },
  {
    "id": "node/1049115795"
  },
  {
    "id": "node/2415765525"
  },
  {
    "id": "node/4323731759"
  },
  {
    "id": "node/4501917665"
  }
]
;