export const names = [
  {
    "id": "relation/1752609",
    "name": "Мордовский муниципальный округ"
  },
  {
    "id": "relation/1752610",
    "name": "Петровский муниципальный округ"
  },
  {
    "id": "relation/1752950",
    "name": "Мичуринский муниципальный округ"
  },
  {
    "id": "relation/1752951",
    "name": "Никифоровский муниципальный округ"
  },
  {
    "id": "relation/1752952",
    "name": "Первомайский муниципальный округ"
  },
  {
    "id": "relation/1752953",
    "name": "Староюрьевский муниципальный округ"
  },
  {
    "id": "relation/1753389",
    "name": "Моршанский муниципальный округ"
  },
  {
    "id": "relation/1753390",
    "name": "Пичаевский муниципальный округ"
  },
  {
    "id": "relation/1777908",
    "name": "Бондарский муниципальный округ"
  },
  {
    "id": "relation/1777909",
    "name": "Гавриловский муниципальный округ"
  },
  {
    "id": "relation/1777910",
    "name": "Жердевский муниципальный округ"
  },
  {
    "id": "relation/1777911",
    "name": "Знаменский муниципальный округ"
  },
  {
    "id": "relation/1777912",
    "name": "Инжавинский муниципальный округ"
  },
  {
    "id": "relation/1777913",
    "name": "Кирсановский муниципальный округ"
  },
  {
    "id": "relation/1777914",
    "name": "Мучкапский муниципальный округ"
  },
  {
    "id": "relation/1777915",
    "name": "Рассказовский муниципальный округ"
  },
  {
    "id": "relation/1777916",
    "name": "Ржаксинский муниципальный округ"
  },
  {
    "id": "relation/1777917",
    "name": "Сампурский муниципальный округ"
  },
  {
    "id": "relation/1777918",
    "name": "Сосновский муниципальный округ"
  },
  {
    "id": "relation/1777919",
    "name": "Тамбовский муниципальный округ"
  },
  {
    "id": "relation/1777920",
    "name": "Токарёвский муниципальный округ"
  },
  {
    "id": "relation/1777921",
    "name": "Уваровский муниципальный округ"
  },
  {
    "id": "relation/1777922",
    "name": "Умётский муниципальный округ"
  },
  {
    "id": "relation/1777923",
    "name": "городской округ Кирсанов"
  },
  {
    "id": "relation/1777924",
    "name": "городской округ Котовск"
  },
  {
    "id": "relation/1777925",
    "name": "городской округ Мичуринск"
  },
  {
    "id": "relation/1777926",
    "name": "городской округ Рассказово"
  },
  {
    "id": "relation/1777927",
    "name": "городской округ Тамбов"
  },
  {
    "id": "relation/1777928",
    "name": "городской округ Уварово"
  },
  {
    "id": "relation/1782700",
    "name": "городской округ Моршанск"
  },
  {
    "id": "node/191725784"
  },
  {
    "id": "node/334723506"
  },
  {
    "id": "node/334723508"
  },
  {
    "id": "node/334723518"
  },
  {
    "id": "node/334723558"
  },
  {
    "id": "node/334723570"
  },
  {
    "id": "node/336140198"
  },
  {
    "id": "node/336140217"
  },
  {
    "id": "node/336140240"
  },
  {
    "id": "node/336140318"
  },
  {
    "id": "node/336140385"
  },
  {
    "id": "node/336140398"
  },
  {
    "id": "node/336140399"
  },
  {
    "id": "node/336140441"
  },
  {
    "id": "node/336140447"
  },
  {
    "id": "node/336523553"
  },
  {
    "id": "node/1026599162"
  },
  {
    "id": "node/1092011600"
  },
  {
    "id": "node/1129880648"
  },
  {
    "id": "node/1129880655"
  },
  {
    "id": "node/1129880678"
  },
  {
    "id": "node/1833015610"
  },
  {
    "id": "node/2714417300"
  },
  {
    "id": "node/8591857429"
  }
]
;