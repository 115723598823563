import {createSlice} from '@reduxjs/toolkit';
import {BreadCrumbs, homeStore} from './types';

export const defaultBreadCrumbs: BreadCrumbs = {path: '/', name: 'Главная'};

const initialState: homeStore = {
    regionId: 'rus',
    regionName: 'Россия',
    selectSchemaQuestion: null,
    breadCrumbs: [defaultBreadCrumbs]
};

const homeSlice = createSlice({
    name: 'home',
    initialState,
    reducers: {
        changeRegionName: (state, {payload}) => {
            state.regionName = payload.regionName;
        },
        changeRegionId: (state, {payload}) => {
            state.regionId = payload.regionId;
        },
        changeBreadCrumbs: (state, {payload}) => {
            state.breadCrumbs = payload;
        },
        setSelectQuestion: (state, {payload}) => {
            state.selectSchemaQuestion = payload;
        }
    }
});

export const {
    changeRegionName,
    changeRegionId,
    changeBreadCrumbs,
    setSelectQuestion
} = homeSlice.actions;

export default homeSlice.reducer;
