export const names = [
  {
    "id": "relation/1697480",
    "name": "Октябрьский район"
  },
  {
    "id": "relation/1697481",
    "name": "Поназыревский муниципальный округ"
  },
  {
    "id": "relation/1697482",
    "name": "Шарьинский район"
  },
  {
    "id": "relation/1697483",
    "name": "городской округ Шарья"
  },
  {
    "id": "relation/1697550",
    "name": "Вохомский район"
  },
  {
    "id": "relation/1697551",
    "name": "Межевской муниципальный округ"
  },
  {
    "id": "relation/1697553",
    "name": "Павинский муниципальный округ"
  },
  {
    "id": "relation/1697554",
    "name": "Пыщугский муниципальный округ"
  },
  {
    "id": "relation/1697801",
    "name": "Кологривский муниципальный округ"
  },
  {
    "id": "relation/1697802",
    "name": "Солигаличский муниципальный округ"
  },
  {
    "id": "relation/1697803",
    "name": "Чухломский район"
  },
  {
    "id": "relation/1697844",
    "name": "Нейский муниципальный округ"
  },
  {
    "id": "relation/1697845",
    "name": "Парфеньевский муниципальный округ"
  },
  {
    "id": "relation/1697847",
    "name": "Буйский район"
  },
  {
    "id": "relation/1697848",
    "name": "городской округ Буй"
  },
  {
    "id": "relation/1698924",
    "name": "Антроповский район"
  },
  {
    "id": "relation/1698925",
    "name": "Галичский район"
  },
  {
    "id": "relation/1698926",
    "name": "Кадыйский район"
  },
  {
    "id": "relation/1698927",
    "name": "Костромской район"
  },
  {
    "id": "relation/1698928",
    "name": "Красносельский район"
  },
  {
    "id": "relation/1698929",
    "name": "Макарьевский район"
  },
  {
    "id": "relation/1698930",
    "name": "Нерехтский район"
  },
  {
    "id": "relation/1698931",
    "name": "Островский муниципальный округ"
  },
  {
    "id": "relation/1698932",
    "name": "Судиславский район"
  },
  {
    "id": "relation/1698933",
    "name": "Сусанинский район"
  },
  {
    "id": "relation/1698934",
    "name": "городской округ Волгореченск"
  },
  {
    "id": "relation/1698935",
    "name": "городской округ Галич"
  },
  {
    "id": "relation/1698938",
    "name": "Мантуровский муниципальный округ"
  },
  {
    "id": "relation/2194955",
    "name": "городской округ Кострома"
  },
  {
    "id": "node/148901123"
  },
  {
    "id": "node/256825509"
  },
  {
    "id": "node/256825510"
  },
  {
    "id": "node/256827430"
  },
  {
    "id": "node/256827431"
  },
  {
    "id": "node/256827434"
  },
  {
    "id": "node/256827438"
  },
  {
    "id": "node/335978725"
  },
  {
    "id": "node/356482525"
  },
  {
    "id": "node/356482537"
  },
  {
    "id": "node/356482549"
  },
  {
    "id": "node/356482553"
  },
  {
    "id": "node/356482562"
  },
  {
    "id": "node/356482688"
  },
  {
    "id": "node/494895608"
  },
  {
    "id": "node/494895622"
  },
  {
    "id": "node/535384092"
  },
  {
    "id": "node/544529486"
  },
  {
    "id": "node/707796257"
  },
  {
    "id": "node/717410211"
  },
  {
    "id": "node/1025646631"
  },
  {
    "id": "node/1025646636"
  },
  {
    "id": "node/1542661472"
  },
  {
    "id": "node/4579427498"
  }
]
;