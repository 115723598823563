import React, {memo} from 'react';
import {DirectorCard, DirectorCardBody, DirectorPhoto, DirectorPhotoWrapper, Regalia} from '../styled';
import {TextS, TitleS} from '../../../fields';

const Director = ({item}: { item: any }) => {
    return (
        <DirectorCard>
            <DirectorPhotoWrapper>
                <DirectorPhoto src={item.photo} key={item.name}/>
            </DirectorPhotoWrapper>
            <DirectorCardBody>
                <TitleS
                    tag={'h5'}
                    fontSize={1}
                    fontWeight={400}
                    color={'var(--text-color)'}>
                    {item.name}
                </TitleS>
                <Regalia>
                    <TextS as={'span'} fontSize={0.6} fontWeight={400}
                           color={'var(--text-color-light)'}>Должность:</TextS>
                    <TextS as={'span'} fontSize={0.8} fontWeight={400}
                           color={'var(--text-color)'}>{item.jobTitle}</TextS>
                </Regalia>
                <Regalia>
                    <TextS as={'span'} fontSize={0.6} fontWeight={400}
                           color={'var(--text-color-light)'}>Национальность:</TextS>
                    <TextS as={'span'} fontSize={0.8} fontWeight={400}
                           color={'var(--text-color)'}>{item.nationality}</TextS>
                </Regalia>
                <Regalia>
                    <TextS as={'span'} fontSize={0.6} fontWeight={400}
                           color={'var(--text-color-light)'}>Образование:</TextS>
                    <TextS as={'span'} fontSize={0.8} fontWeight={400}
                           color={'var(--text-color)'}>{item.education}</TextS>
                </Regalia>
                <Regalia>
                    <TextS as={'span'} fontSize={0.6} fontWeight={400}
                           color={'var(--text-color-light)'}>Специальность:</TextS>
                    <TextS as={'span'} fontSize={0.8} fontWeight={400}
                           color={'var(--text-color)'}>{item.specialty}</TextS>
                </Regalia>
                <Regalia>
                    <TextS as={'span'} fontSize={0.6} fontWeight={400}
                           color={'var(--text-color-light)'}>Ученая степень:</TextS>
                    <TextS as={'span'} fontSize={0.8} fontWeight={400}
                           color={'var(--text-color)'}>{item.academicDegree}</TextS>
                </Regalia>

            </DirectorCardBody>
        </DirectorCard>
    );
};

export default memo(Director);
