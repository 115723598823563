import React, {useEffect, useState} from 'react';
import {ColumnBlock, ColumnRising, ColumnRisingContainer, DataTextWrap, Value} from './styled';
import {TextS} from '../../fields';
import {RisingType} from './config';

export type RisingColumnProps = {
    value: number;
    text: string;
    color: string;
}


export const RisingColumn = ({positiveValue, negativeValue, text}: RisingType) => {
    const [displayPositiveValue, setDisplayValue] = useState(0);
    const [displayNegativeValue, setDisplayNegativeValue] = useState(0);

    useEffect(() => {
        let currentValue = 0;
        const increment = Math.ceil(positiveValue / 100); // Скорость увеличения числа
        const interval = setInterval(() => {
            currentValue += increment;
            if (currentValue >= positiveValue) {
                currentValue = positiveValue;
                clearInterval(interval);
            }
            setDisplayValue(currentValue);
        }, 15);
        return () => clearInterval(interval);
    }, [positiveValue]);

    useEffect(() => {
        let currentValue = 0;
        const increment = Math.ceil(negativeValue / 100); // Скорость увеличения числа
        const interval = setInterval(() => {
            currentValue += increment;
            if (currentValue >= negativeValue) {
                currentValue = negativeValue;
                clearInterval(interval);
            }
            setDisplayNegativeValue(currentValue);
        }, 15);
        return () => clearInterval(interval);
    }, [negativeValue]);

    return (
        <ColumnRisingContainer>

            <DataTextWrap>
                {/*<Value>{displayValue.toFixed()}%</Value>*/}
                <TextS as={'p'} fontWeight={400} fontSize={0.6} color={'var(--text-color)'}
                       style={{marginLeft: '5px'}}>{text}г.</TextS>
            </DataTextWrap>
            <ColumnBlock>
                <ColumnRising
                    style={{width: `${displayPositiveValue}%`}}
                    color={'var(--green-graf)'}>
                    <Value>{displayPositiveValue}%</Value>
                </ColumnRising>
                <ColumnRising
                    style={{width: `${displayNegativeValue}%`}}
                    color={'var(--red-graf)'}>
                    <Value>{displayNegativeValue}%</Value>
                </ColumnRising>
            </ColumnBlock>
        </ColumnRisingContainer>
    );
};

