export const names = [
  {
    "id": "relation/1429031",
    "name": "Свердловский район"
  },
  {
    "id": "relation/1429032",
    "name": "Глазуновский район"
  },
  {
    "id": "relation/1429033",
    "name": "Орловский муниципальный округ"
  },
  {
    "id": "relation/1430747",
    "name": "Мценский район"
  },
  {
    "id": "relation/1431359",
    "name": "Корсаковский район"
  },
  {
    "id": "relation/1436146",
    "name": "Залегощенский район"
  },
  {
    "id": "relation/1436180",
    "name": "Новосильский район"
  },
  {
    "id": "relation/1436224",
    "name": "Покровский район"
  },
  {
    "id": "relation/1450050",
    "name": "Шаблыкинский район"
  },
  {
    "id": "relation/1451106",
    "name": "Кромской район"
  },
  {
    "id": "relation/1457530",
    "name": "Малоархангельский район"
  },
  {
    "id": "relation/1461478",
    "name": "городской округ Мценск"
  },
  {
    "id": "relation/1462542",
    "name": "Дмитровский район"
  },
  {
    "id": "relation/1467842",
    "name": "Троснянский район"
  },
  {
    "id": "relation/1502871",
    "name": "Сосковский район"
  },
  {
    "id": "relation/1615430",
    "name": "Краснозоренский район"
  },
  {
    "id": "relation/1615436",
    "name": "Ливенский район"
  },
  {
    "id": "relation/1615445",
    "name": "Должанский район"
  },
  {
    "id": "relation/1617653",
    "name": "Колпнянский район"
  },
  {
    "id": "relation/1617691",
    "name": "Верховский район"
  },
  {
    "id": "relation/1617694",
    "name": "Новодеревеньковский район"
  },
  {
    "id": "relation/1618112",
    "name": "Болховский район"
  },
  {
    "id": "relation/1618147",
    "name": "Знаменский район"
  },
  {
    "id": "relation/1618171",
    "name": "Хотынецкий район"
  },
  {
    "id": "relation/1618175",
    "name": "Урицкий район"
  },
  {
    "id": "relation/1776249",
    "name": "городской округ Орёл"
  },
  {
    "id": "relation/1938802",
    "name": "городской округ Ливны"
  },
  {
    "id": "node/27096008"
  },
  {
    "id": "node/310173070"
  },
  {
    "id": "node/336140167"
  },
  {
    "id": "node/336140200"
  },
  {
    "id": "node/336140258"
  },
  {
    "id": "node/336140287"
  },
  {
    "id": "node/336140301"
  },
  {
    "id": "node/336140305"
  },
  {
    "id": "node/336140362"
  },
  {
    "id": "node/336140383"
  },
  {
    "id": "node/336306532"
  },
  {
    "id": "node/336306573"
  },
  {
    "id": "node/336306575"
  },
  {
    "id": "node/336306590"
  },
  {
    "id": "node/336306596"
  },
  {
    "id": "node/336306598"
  },
  {
    "id": "node/410919506"
  },
  {
    "id": "node/414709086"
  },
  {
    "id": "node/417947852"
  },
  {
    "id": "node/465247374"
  },
  {
    "id": "node/1006266644"
  },
  {
    "id": "node/1026627257"
  },
  {
    "id": "node/1141132897"
  },
  {
    "id": "node/1444504020"
  }
]
;