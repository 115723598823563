import React from 'react';
import {MainContainer} from '../layouts';
import {Header} from '../components/header';
import {Navigate, Outlet} from 'react-router-dom';
import {useAppSelector} from '../hooks';

export const Public = () => {
    const {isAuth} = useAppSelector(state => state.auth);

    return !isAuth ? (
        <MainContainer>
            <Header/>
            <Outlet/>
        </MainContainer>
    ) : (
        <Navigate to={'/'}/>
    );
};
