export const names = [
  {
    "id": "relation/194267",
    "name": "Окинский район"
  },
  {
    "id": "relation/194275",
    "name": "Тункинский район"
  },
  {
    "id": "relation/194326",
    "name": "Закаменский район"
  },
  {
    "id": "relation/194457",
    "name": "Джидинский район"
  },
  {
    "id": "relation/194462",
    "name": "Кяхтинский район"
  },
  {
    "id": "relation/194478",
    "name": "Кабанский район"
  },
  {
    "id": "relation/194493",
    "name": "Северо-Байкальский район"
  },
  {
    "id": "relation/194598",
    "name": "Баунтовский эвенкийский район"
  },
  {
    "id": "relation/194766",
    "name": "Муйский район"
  },
  {
    "id": "relation/194785",
    "name": "Баргузинский район"
  },
  {
    "id": "relation/194798",
    "name": "Курумканский район"
  },
  {
    "id": "relation/194832",
    "name": "Еравнинский район"
  },
  {
    "id": "relation/194849",
    "name": "Кижингинский район"
  },
  {
    "id": "relation/194891",
    "name": "Мухоршибирский район"
  },
  {
    "id": "relation/194960",
    "name": "Бичурский район"
  },
  {
    "id": "relation/195014",
    "name": "Селенгинский район"
  },
  {
    "id": "relation/195044",
    "name": "Иволгинский район"
  },
  {
    "id": "relation/195055",
    "name": "Тарбагатайский район"
  },
  {
    "id": "relation/195068",
    "name": "Заиграевский район"
  },
  {
    "id": "relation/195104",
    "name": "Прибайкальский район"
  },
  {
    "id": "relation/195223",
    "name": "Хоринский район"
  },
  {
    "id": "relation/1899006",
    "name": "городской округ Северобайкальск"
  },
  {
    "id": "relation/1949930",
    "name": "городской округ Улан-Удэ"
  },
  {
    "id": "node/243347526"
  },
  {
    "id": "node/279980750"
  },
  {
    "id": "node/279983984"
  },
  {
    "id": "node/279985581"
  },
  {
    "id": "node/290889369"
  },
  {
    "id": "node/302019058"
  },
  {
    "id": "node/302524562"
  },
  {
    "id": "node/302534600"
  },
  {
    "id": "node/360837530"
  },
  {
    "id": "node/451844392"
  },
  {
    "id": "node/461742306"
  },
  {
    "id": "node/462102193"
  },
  {
    "id": "node/462126714"
  },
  {
    "id": "node/462129412"
  },
  {
    "id": "node/462164030"
  },
  {
    "id": "node/462167682"
  },
  {
    "id": "node/462202929"
  },
  {
    "id": "node/1457469393"
  },
  {
    "id": "node/1641900014"
  },
  {
    "id": "node/1665035561"
  },
  {
    "id": "node/1777949951"
  },
  {
    "id": "node/3474660190"
  },
  {
    "id": "node/3871442917"
  }
]
;