import {RegionData} from './types';

export const findMinMaxValue = (data: RegionData[]): { min: number, max: number } => {
    return data.reduce(
        (acc, obj) => {
            if (obj.Value < acc.min) acc.min = obj.Value;
            if (obj.Value > acc.max) acc.max = obj.Value;
            return acc;
        },
        {min: data[0].Value, max: data[0].Value}
    );
};
