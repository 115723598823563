export const names = [
  {
    "id": "relation/1949879",
    "name": "Анадырский район"
  },
  {
    "id": "relation/1949880",
    "name": "Билибинский район"
  },
  {
    "id": "relation/1949881",
    "name": "городской округ Эгвекинот"
  },
  {
    "id": "relation/1949882",
    "name": "Провиденский городской округ"
  },
  {
    "id": "relation/1949883",
    "name": "городской округ Певек"
  },
  {
    "id": "relation/1949884",
    "name": "Чукотский район"
  },
  {
    "id": "relation/1957550",
    "name": "городской округ Анадырь"
  },
  {
    "id": "node/191736988"
  },
  {
    "id": "node/457244467"
  },
  {
    "id": "node/457274206"
  },
  {
    "id": "node/527802328"
  },
  {
    "id": "node/1575069719"
  },
  {
    "id": "node/2891542781"
  }
]
;