export const names = [
  {
    "id": "relation/1328727",
    "name": "городской округ Нарьян-Мар"
  },
  {
    "id": "relation/1328729",
    "name": "Заполярный район"
  },
  {
    "id": "node/191733890"
  },
  {
    "id": "node/1016276147"
  }
]
;