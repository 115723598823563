import styled from 'styled-components';
import {Select} from 'antd';

export const SelectS = styled(Select).withConfig({})<{ tooltip?: string | undefined }>`
  width: 200px;
  padding: 5px 10px;
  border-radius: 4px;
  border: 1px solid var(--border-color);
  background-color: #ffffff;

  &::after {
    content: ${({tooltip}) => (tooltip ? `"${tooltip}"` : 'none')};
    position: absolute;
    left: 50%;
    top: 110%;
    transform: translateX(-50%);
    background: #ffffff;
    color: var(--text-color);
    padding: 5px 10px;
    border-radius: 5px;
    border: 1px solid var(--light-color);
    font-size: 0.8rem;
    white-space: nowrap;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.2s;
    z-index: 10;
  }

  /* Показ тултипа при наведении */

  &:hover::after {
    opacity: 1;
  }
`;
