export const names = [
  {
    "id": "relation/1029112",
    "name": "Ковдорский муниципальный округ"
  },
  {
    "id": "relation/1029128",
    "name": "муниципальный округ Оленегорск"
  },
  {
    "id": "relation/1029131",
    "name": "муниципальный округ Мончегорск"
  },
  {
    "id": "relation/1029133",
    "name": "Кандалакшский район"
  },
  {
    "id": "relation/1029489",
    "name": "городской округ Мурманск"
  },
  {
    "id": "relation/1029772",
    "name": "ЗАТО Видяево"
  },
  {
    "id": "relation/1043206",
    "name": "Печенгский муниципальный округ"
  },
  {
    "id": "relation/1043207",
    "name": "ЗАТО Островной"
  },
  {
    "id": "relation/1043209",
    "name": "ЗАТО Заозёрск"
  },
  {
    "id": "relation/1043325",
    "name": "Кольский район"
  },
  {
    "id": "relation/1043400",
    "name": "Терский район"
  },
  {
    "id": "relation/1043402",
    "name": "муниципальный округ Апатиты"
  },
  {
    "id": "relation/1043404",
    "name": "муниципальный округ Кировск"
  },
  {
    "id": "relation/1043549",
    "name": "муниципальный округ Полярные Зори"
  },
  {
    "id": "relation/1043558",
    "name": "ЗАТО Александровск"
  },
  {
    "id": "relation/1046992",
    "name": "ЗАТО Североморск"
  },
  {
    "id": "relation/1047010",
    "name": "Ловозерский район"
  },
  {
    "id": "node/26878551"
  },
  {
    "id": "node/26898767"
  },
  {
    "id": "node/26898769"
  },
  {
    "id": "node/269342417"
  },
  {
    "id": "node/272845466"
  },
  {
    "id": "node/292226474"
  },
  {
    "id": "node/292235034"
  },
  {
    "id": "node/293459492"
  },
  {
    "id": "node/303015164"
  },
  {
    "id": "node/303353860"
  },
  {
    "id": "node/303968215"
  },
  {
    "id": "node/303980218"
  },
  {
    "id": "node/304025149"
  },
  {
    "id": "node/986551118"
  },
  {
    "id": "node/1627107363"
  },
  {
    "id": "node/1628525065"
  },
  {
    "id": "node/1682453982"
  }
]
;