export const names = [
    {
        'id': 'relation/51490',
        'name': 'Московская область',
        'ISO3166-2': 'RU-MOS'
    },
    {
        'id': 'relation/71950',
        'name': 'Рязанская область',
        'ISO3166-2': 'RU-RYA'
    },
    {
        'id': 'relation/72169',
        'name': 'Липецкая область',
        'ISO3166-2': 'RU-LIP'
    },
    {
        'id': 'relation/72180',
        'name': 'Тамбовская область',
        'ISO3166-2': 'RU-TAM'
    },
    {
        'id': 'relation/72181',
        'name': 'Воронежская область',
        'ISO3166-2': 'RU-VOR'
    },
    {
        'id': 'relation/72182',
        'name': 'Пензенская область',
        'ISO3166-2': 'RU-PNZ'
    },
    {
        'id': 'relation/72192',
        'name': 'Ульяновская область',
        'ISO3166-2': 'RU-ULY'
    },
    {
        'id': 'relation/72193',
        'name': 'Саратовская область',
        'ISO3166-2': 'RU-SAR'
    },
    {
        'id': 'relation/72194',
        'name': 'Самарская область',
        'ISO3166-2': 'RU-SAM'
    },
    {
        'id': 'relation/72195',
        'name': 'Нижегородская область',
        'ISO3166-2': 'RU-NIZ'
    },
    {
        'id': 'relation/72196',
        'name': 'Мордовия',
        'ISO3166-2': 'RU-MO'
    },
    {
        'id': 'relation/72197',
        'name': 'Владимирская область',
        'ISO3166-2': 'RU-VLA'
    },
    {
        'id': 'relation/72223',
        'name': 'Курская область',
        'ISO3166-2': 'RU-KRS'
    },
    {
        'id': 'relation/72224',
        'name': 'Орловская область',
        'ISO3166-2': 'RU-ORL'
    },
    {
        'id': 'relation/72639',
        'name': 'Автономна Республіка Крим',
        'ISO3166-2': 'UA-43'
    },
    {
        'id': 'relation/77665',
        'name': 'Волгоградская область',
        'ISO3166-2': 'RU-VGG'
    },
    {
        'id': 'relation/77669',
        'name': 'Оренбургская область',
        'ISO3166-2': 'RU-ORE'
    },
    {
        'id': 'relation/77677',
        'name': 'Башкортостан',
        'ISO3166-2': 'RU-BA'
    },
    {
        'id': 'relation/77687',
        'name': 'Челябинская область',
        'ISO3166-2': 'RU-CHE'
    },
    {
        'id': 'relation/79374',
        'name': 'Татарстан',
        'ISO3166-2': 'RU-TA'
    },
    {
        'id': 'relation/79379',
        'name': 'Свердловская область',
        'ISO3166-2': 'RU-SVE'
    },
    {
        'id': 'relation/80513',
        'name': 'Чувашия',
        'ISO3166-2': 'RU-CU'
    },
    {
        'id': 'relation/81993',
        'name': 'Тульская область',
        'ISO3166-2': 'RU-TUL'
    },
    {
        'id': 'relation/81994',
        'name': 'Ярославская область',
        'ISO3166-2': 'RU-YAR'
    },
    // {
    //     'id': 'relation/81995',
    //     'name': 'Калужская область',
    //     'ISO3166-2': 'RU-KLU'
    // },
    {
        'id': 'relation/81996',
        'name': 'Смоленская область',
        'ISO3166-2': 'RU-SMO'
    },
    // {
    //     'id': 'relation/81997',
    //     'name': 'Брянская область',
    //     'ISO3166-2': 'RU-BRY'
    // },
    // {
    //     'id': 'relation/83184',
    //     'name': 'Белгородская область',
    //     'ISO3166-2': 'RU-BEL'
    // },
    {
        'id': 'relation/85606',
        'name': 'Ростовская область',
        'ISO3166-2': 'RU-ROS'
    },
    {
        'id': 'relation/85617',
        'name': 'Ивановская область',
        'ISO3166-2': 'RU-IVA'
    },
    {
        'id': 'relation/85963',
        'name': 'Костромская область',
        'ISO3166-2': 'RU-KOS'
    },
    {
        'id': 'relation/89331',
        'name': 'Новгородская область',
        'ISO3166-2': 'RU-NGR'
    },
    {
        'id': 'relation/102269',
        'name': 'Москва',
        'ISO3166-2': 'RU-MOW'
    },
    {
        'id': 'relation/103906',
        'name': 'Калининградская область',
        'ISO3166-2': 'RU-KGD'
    },
    {
        'id': 'relation/108081',
        'name': 'Ставропольский край',
        'ISO3166-2': 'RU-STA'
    },
    {
        'id': 'relation/108082',
        'name': 'Краснодарский край',
        'ISO3166-2': 'RU-KDA'
    },
    {
        'id': 'relation/108083',
        'name': 'Калмыкия',
        'ISO3166-2': 'RU-KL'
    },
    {
        'id': 'relation/109876',
        'name': 'Дагестан',
        'ISO3166-2': 'RU-DA'
    },
    {
        'id': 'relation/109877',
        'name': 'Чечня',
        'ISO3166-2': 'RU-CE'
    },
    {
        'id': 'relation/109878',
        'name': 'Карачаево-Черкесия',
        'ISO3166-2': 'RU-KC'
    },
    {
        'id': 'relation/109879',
        'name': 'Кабардино-Балкария',
        'ISO3166-2': 'RU-KB'
    },
    {
        'id': 'relation/110032',
        'name': 'Северная Осетия — Алания',
        'ISO3166-2': 'RU-SE'
    },
    // {
    //     'id': 'relation/112819',
    //     'name': 'Астраханская область',
    //     'ISO3166-2': 'RU-AST'
    // },
    {
        'id': 'relation/115100',
        'name': 'Кировская область',
        'ISO3166-2': 'RU-KIR'
    },
    {
        'id': 'relation/115106',
        'name': 'Вологодская область',
        'ISO3166-2': 'RU-VLG'
    },
    {
        'id': 'relation/115114',
        'name': 'Марий Эл',
        'ISO3166-2': 'RU-ME'
    },
    {
        'id': 'relation/115134',
        'name': 'Удмуртия',
        'ISO3166-2': 'RU-UD'
    },
    // {
    //     'id': 'relation/115135',
    //     'name': 'Пермский край',
    //     'ISO3166-2': 'RU-PER'
    // },
    {
        'id': 'relation/115136',
        'name': 'Республика Коми',
        'ISO3166-2': 'RU-KO'
    },
    {
        'id': 'relation/140290',
        'name': 'Курганская область',
        'ISO3166-2': 'RU-KGN'
    },
    {
        'id': 'relation/140291',
        'name': 'Тюменская область',
        'ISO3166-2': 'RU-TYU'
    },
    {
        'id': 'relation/140292',
        'name': 'Омская область',
        'ISO3166-2': 'RU-OMS'
    },
    {
        'id': 'relation/140294',
        'name': 'Новосибирская область',
        'ISO3166-2': 'RU-NVS'
    },
    {
        'id': 'relation/140295',
        'name': 'Томская область',
        'ISO3166-2': 'RU-TOM'
    },
    {
        'id': 'relation/140296',
        'name': 'Ханты-Мансийский автономный округ — Югра',
        'ISO3166-2': 'RU-KHM'
    },
    // {
    //     'id': 'relation/140337',
    //     'name': 'Архангельская область',
    //     'ISO3166-2': 'RU-ARK'
    // },
    {
        'id': 'relation/144763',
        'name': 'Кемеровская область',
        'ISO3166-2': 'RU-KEM'
    },
    // {
    //     'id': 'relation/144764',
    //     'name': 'Алтайский край',
    //     'ISO3166-2': 'RU-ALT'
    // },
    {
        'id': 'relation/145194',
        'name': 'Республика Алтай',
        'ISO3166-2': 'RU-AL'
    },
    {
        'id': 'relation/145195',
        'name': 'Республика Тыва',
        'ISO3166-2': 'RU-TY'
    },
    {
        'id': 'relation/145454',
        'name': 'Иркутская область',
        'ISO3166-2': 'RU-IRK'
    },
    {
        'id': 'relation/145729',
        'name': 'Республика Бурятия',
        'ISO3166-2': 'RU-BU'
    },
    // {
    //     'id': 'relation/145730',
    //     'name': 'Забайкальский край',
    //     'ISO3166-2': 'RU-ZAB'
    // },
    {
        'id': 'relation/147166',
        'name': 'Амурская область',
        'ISO3166-2': 'RU-AMU'
    },
    {
        'id': 'relation/147167',
        'name': 'Еврейская автономная область',
        'ISO3166-2': 'RU-YEV'
    },
    {
        'id': 'relation/151223',
        'name': 'Хабаровский край',
        'ISO3166-2': 'RU-KHA'
    },
    {
        'id': 'relation/151225',
        'name': 'Приморский край',
        'ISO3166-2': 'RU-PRI'
    },
    {
        'id': 'relation/151228',
        'name': 'Магаданская область',
        'ISO3166-2': 'RU-MAG'
    },
    {
        'id': 'relation/151231',
        'name': 'Чукотский автономный округ',
        'ISO3166-2': 'RU-CHU'
    },
    {
        'id': 'relation/151233',
        'name': 'Камчатский край',
        'ISO3166-2': 'RU-KAM'
    },
    {
        'id': 'relation/151234',
        'name': 'Республика Саха (Якутия)',
        'ISO3166-2': 'RU-SA'
    },
    {
        'id': 'relation/155262',
        'name': 'Псковская область',
        'ISO3166-2': 'RU-PSK'
    },
    {
        'id': 'relation/176095',
        'name': 'Ленинградская область',
        'ISO3166-2': 'RU-LEN'
    },
    {
        'id': 'relation/190090',
        'name': 'Красноярский край',
        'ISO3166-2': 'RU-KYA'
    },
    {
        'id': 'relation/190911',
        'name': 'Республика Хакасия',
        'ISO3166-2': 'RU-KK'
    },
    {
        'id': 'relation/191706',
        'name': 'Ямало-Ненецкий автономный округ',
        'ISO3166-2': 'RU-YAN'
    },
    {
        'id': 'relation/253252',
        'name': 'Ингушетия',
        'ISO3166-2': 'RU-IN'
    },
    // {
    //     'id': 'relation/253256',
    //     'name': 'Адыгея',
    //     'ISO3166-2': 'RU-AD'
    // },
    {
        'id': 'relation/274048',
        'name': 'Ненецкий автономный округ',
        'ISO3166-2': 'RU-NEN'
    },
    {
        'id': 'relation/337422',
        'name': 'Санкт-Петербург',
        'ISO3166-2': 'RU-SPE'
    },
    {
        'id': 'relation/393980',
        'name': 'Карелия',
        'ISO3166-2': 'RU-KR'
    },
    {
        'id': 'relation/394235',
        'name': 'Сахалинская область',
        'ISO3166-2': 'RU-SAK'
    },
    {
        'id': 'relation/1574364',
        'name': 'Севастополь',
        'ISO3166-2': 'UA-40'
    },
    {
        'id': 'relation/2095259',
        'name': 'Тверская область',
        'ISO3166-2': 'RU-TVE'
    },
    {
        'id': 'relation/2099216',
        'name': 'Мурманская область',
        'ISO3166-2': 'RU-MUR'
    },
    {
        'id': 'relation/3788485',
        'name': 'Севастополь'
    },
    {
        'id': 'relation/3795586',
        'name': 'Республика Крым'
    },
    {
        'id': 'relation/71022',
        'name': 'Херсонская область'
    },
    {
        'id': 'relation/71980',
        'name': 'Запорожская область'
    },
    {
        'id': 'relation/71973',
        'name': 'Донецкая область'
    },
    {
        'id': 'relation/71971',
        'name': 'Луганская область'
    },
];

