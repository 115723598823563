export const names = [
  {
    "id": "relation/369838",
    "name": "городской округ Тверь"
  },
  {
    "id": "relation/383694",
    "name": "Бологовский муниципальный округ"
  },
  {
    "id": "relation/546149",
    "name": "Удомельский городской округ"
  },
  {
    "id": "relation/546155",
    "name": "Вышневолоцкий городской округ"
  },
  {
    "id": "relation/546156",
    "name": "Фировский муниципальный округ"
  },
  {
    "id": "relation/554721",
    "name": "Лесной муниципальный округ"
  },
  {
    "id": "relation/555382",
    "name": "Кувшиновский муниципальный округ"
  },
  {
    "id": "relation/555383",
    "name": "Спировский муниципальный округ"
  },
  {
    "id": "relation/555384",
    "name": "Максатихинский муниципальный округ"
  },
  {
    "id": "relation/569744",
    "name": "Сандовский муниципальный округ"
  },
  {
    "id": "relation/569745",
    "name": "Весьегонский муниципальный округ"
  },
  {
    "id": "relation/569746",
    "name": "Молоковский муниципальный округ"
  },
  {
    "id": "relation/569747",
    "name": "Бежецкий муниципальный округ"
  },
  {
    "id": "relation/569748",
    "name": "Краснохолмский муниципальный округ"
  },
  {
    "id": "relation/569749",
    "name": "Сонковский муниципальный округ"
  },
  {
    "id": "relation/570636",
    "name": "Кесовогорский муниципальный округ"
  },
  {
    "id": "relation/570637",
    "name": "Кашинский городской округ"
  },
  {
    "id": "relation/570638",
    "name": "Калязинский муниципальный округ"
  },
  {
    "id": "relation/570639",
    "name": "Осташковский городской округ"
  },
  {
    "id": "relation/570640",
    "name": "Пеновский муниципальный округ"
  },
  {
    "id": "relation/570641",
    "name": "Андреапольский муниципальный округ"
  },
  {
    "id": "relation/570642",
    "name": "Торопецкий муниципальный округ"
  },
  {
    "id": "relation/570643",
    "name": "Торжокский район"
  },
  {
    "id": "relation/570644",
    "name": "Лихославльский муниципальный округ"
  },
  {
    "id": "relation/570645",
    "name": "Рамешковский муниципальный округ"
  },
  {
    "id": "relation/570646",
    "name": "Кимрский муниципальный округ"
  },
  {
    "id": "relation/570649",
    "name": "Конаковский муниципальный округ"
  },
  {
    "id": "relation/610775",
    "name": "Калининский муниципальный округ"
  },
  {
    "id": "relation/910734",
    "name": "Жарковский муниципальный округ"
  },
  {
    "id": "relation/910735",
    "name": "Западнодвинский муниципальный округ"
  },
  {
    "id": "relation/918729",
    "name": "Зубцовский муниципальный округ"
  },
  {
    "id": "relation/921388",
    "name": "Старицкий муниципальный округ"
  },
  {
    "id": "relation/921392",
    "name": "Селижаровский муниципальный округ"
  },
  {
    "id": "relation/930872",
    "name": "Ржевский муниципальный округ"
  },
  {
    "id": "relation/930884",
    "name": "Оленинский муниципальный округ"
  },
  {
    "id": "relation/930887",
    "name": "Нелидовский городской округ"
  },
  {
    "id": "relation/1043288",
    "name": "ЗАТО Озёрный"
  },
  {
    "id": "relation/1431724",
    "name": "Бельский муниципальный округ"
  },
  {
    "id": "relation/1466653",
    "name": "городской округ Торжок"
  },
  {
    "id": "relation/1724015",
    "name": "ЗАТО Солнечный"
  },
  {
    "id": "node/27000366"
  },
  {
    "id": "node/27001056"
  },
  {
    "id": "node/34389206"
  },
  {
    "id": "node/60405981"
  },
  {
    "id": "node/94091844"
  },
  {
    "id": "node/94102909"
  },
  {
    "id": "node/148899605"
  },
  {
    "id": "node/148901126"
  },
  {
    "id": "node/148912020"
  },
  {
    "id": "node/248561802"
  },
  {
    "id": "node/285962678"
  },
  {
    "id": "node/336306051"
  },
  {
    "id": "node/356482533"
  },
  {
    "id": "node/356482594"
  },
  {
    "id": "node/356482620"
  },
  {
    "id": "node/356482640"
  },
  {
    "id": "node/356482660"
  },
  {
    "id": "node/356482677"
  },
  {
    "id": "node/356482685"
  },
  {
    "id": "node/356482731"
  },
  {
    "id": "node/356482758"
  },
  {
    "id": "node/356482849"
  },
  {
    "id": "node/356482893"
  },
  {
    "id": "node/356482920"
  },
  {
    "id": "node/365919825"
  },
  {
    "id": "node/365919965"
  },
  {
    "id": "node/449802111"
  },
  {
    "id": "node/454998570"
  },
  {
    "id": "node/619189465"
  },
  {
    "id": "node/619245800"
  },
  {
    "id": "node/626927651"
  },
  {
    "id": "node/628649194"
  },
  {
    "id": "node/639116876"
  },
  {
    "id": "node/688916769"
  },
  {
    "id": "node/814620639"
  },
  {
    "id": "node/1187053943"
  },
  {
    "id": "node/2582015415"
  },
  {
    "id": "node/3210772411"
  }
]
;