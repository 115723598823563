export const names = [
  {
    "id": "relation/336200",
    "name": "Дюртюлинский район"
  },
  {
    "id": "relation/336201",
    "name": "Бирский район"
  },
  {
    "id": "relation/336331",
    "name": "Кушнаренковский район"
  },
  {
    "id": "relation/337745",
    "name": "Мишкинский район"
  },
  {
    "id": "relation/337765",
    "name": "Бураевский район"
  },
  {
    "id": "relation/357263",
    "name": "Янаульский район"
  },
  {
    "id": "relation/364048",
    "name": "Илишевский район"
  },
  {
    "id": "relation/398500",
    "name": "Татышлинский район"
  },
  {
    "id": "relation/398501",
    "name": "Аскинский район"
  },
  {
    "id": "relation/398503",
    "name": "Мечетлинский район"
  },
  {
    "id": "relation/398504",
    "name": "Белокатайский район"
  },
  {
    "id": "relation/398506",
    "name": "Балтачевский район"
  },
  {
    "id": "relation/398507",
    "name": "Караидельский район"
  },
  {
    "id": "relation/398508",
    "name": "Дуванский район"
  },
  {
    "id": "relation/398509",
    "name": "Кигинский район"
  },
  {
    "id": "relation/398510",
    "name": "Салаватский район"
  },
  {
    "id": "relation/398511",
    "name": "Нуримановский район"
  },
  {
    "id": "relation/398512",
    "name": "Благовещенский район"
  },
  {
    "id": "relation/398514",
    "name": "Иглинский район"
  },
  {
    "id": "relation/398515",
    "name": "Уфимский район"
  },
  {
    "id": "relation/398516",
    "name": "Чекмагушевский район"
  },
  {
    "id": "relation/398517",
    "name": "Бакалинский район"
  },
  {
    "id": "relation/398518",
    "name": "Шаранский район"
  },
  {
    "id": "relation/398519",
    "name": "Буздякский район"
  },
  {
    "id": "relation/398520",
    "name": "Благоварский район"
  },
  {
    "id": "relation/398521",
    "name": "Чишминский район"
  },
  {
    "id": "relation/398522",
    "name": "Кармаскалинский район"
  },
  {
    "id": "relation/398523",
    "name": "Архангельский район"
  },
  {
    "id": "relation/398524",
    "name": "Белорецкий район"
  },
  {
    "id": "relation/398525",
    "name": "Учалинский район"
  },
  {
    "id": "relation/398526",
    "name": "Абзелиловский район"
  },
  {
    "id": "relation/398527",
    "name": "Баймакский район"
  },
  {
    "id": "relation/398528",
    "name": "Хайбуллинский район"
  },
  {
    "id": "relation/398529",
    "name": "Зианчуринский район"
  },
  {
    "id": "relation/398530",
    "name": "Зилаирский район"
  },
  {
    "id": "relation/398531",
    "name": "Кугарчинский район"
  },
  {
    "id": "relation/398532",
    "name": "Куюргазинский район"
  },
  {
    "id": "relation/398533",
    "name": "Фёдоровский район"
  },
  {
    "id": "relation/398534",
    "name": "Бурзянский район"
  },
  {
    "id": "relation/398535",
    "name": "Мелеузовский район"
  },
  {
    "id": "relation/398536",
    "name": "Стерлибашевский район"
  },
  {
    "id": "relation/398537",
    "name": "Стерлитамакский район"
  },
  {
    "id": "relation/398538",
    "name": "Ишимбайский район"
  },
  {
    "id": "relation/398539",
    "name": "Бижбулякский район"
  },
  {
    "id": "relation/398541",
    "name": "Миякинский район"
  },
  {
    "id": "relation/398542",
    "name": "Альшеевский район"
  },
  {
    "id": "relation/398543",
    "name": "Белебеевский район"
  },
  {
    "id": "relation/398544",
    "name": "Давлекановский район"
  },
  {
    "id": "relation/398545",
    "name": "Ермекеевский район"
  },
  {
    "id": "relation/398546",
    "name": "Туймазинский район"
  },
  {
    "id": "relation/398547",
    "name": "Аургазинский район"
  },
  {
    "id": "relation/398548",
    "name": "Гафурийский район"
  },
  {
    "id": "relation/398549",
    "name": "Калтасинский район"
  },
  {
    "id": "relation/398551",
    "name": "городской округ Уфа"
  },
  {
    "id": "relation/1307226",
    "name": "городской округ Кумертау"
  },
  {
    "id": "relation/1320826",
    "name": "городской округ Агидель"
  },
  {
    "id": "relation/1476944",
    "name": "городской округ Салават"
  },
  {
    "id": "relation/1791629",
    "name": "городской округ Стерлитамак"
  },
  {
    "id": "relation/1850007",
    "name": "ЗАТО Межгорье"
  },
  {
    "id": "relation/1850009",
    "name": "городской округ Октябрьский"
  },
  {
    "id": "relation/1850010",
    "name": "городской округ Сибай"
  },
  {
    "id": "relation/2068952",
    "name": "городской округ Нефтекамск"
  },
  {
    "id": "relation/10813489",
    "name": "Краснокамский район"
  },
  {
    "id": "node/27504327"
  },
  {
    "id": "node/191756795"
  },
  {
    "id": "node/191886499"
  },
  {
    "id": "node/191901380"
  },
  {
    "id": "node/244396312"
  },
  {
    "id": "node/247077588"
  },
  {
    "id": "node/250622613"
  },
  {
    "id": "node/251069363"
  },
  {
    "id": "node/251069799"
  },
  {
    "id": "node/251071107"
  },
  {
    "id": "node/251072970"
  },
  {
    "id": "node/251073091"
  },
  {
    "id": "node/251524158"
  },
  {
    "id": "node/253241997"
  },
  {
    "id": "node/253243268"
  },
  {
    "id": "node/253258948"
  },
  {
    "id": "node/253261493"
  },
  {
    "id": "node/253266942"
  },
  {
    "id": "node/285339173"
  },
  {
    "id": "node/327380642"
  },
  {
    "id": "node/334357268"
  },
  {
    "id": "node/334357276"
  },
  {
    "id": "node/334371735"
  },
  {
    "id": "node/334371755"
  },
  {
    "id": "node/334371762"
  },
  {
    "id": "node/334371777"
  },
  {
    "id": "node/337838996"
  },
  {
    "id": "node/337839003"
  },
  {
    "id": "node/337839059"
  },
  {
    "id": "node/337839114"
  },
  {
    "id": "node/337839119"
  },
  {
    "id": "node/337839253"
  },
  {
    "id": "node/337839333"
  },
  {
    "id": "node/337839379"
  },
  {
    "id": "node/337839466"
  },
  {
    "id": "node/337839509"
  },
  {
    "id": "node/339257818"
  },
  {
    "id": "node/339257827"
  },
  {
    "id": "node/339257830"
  },
  {
    "id": "node/339257835"
  },
  {
    "id": "node/339257836"
  },
  {
    "id": "node/339257859"
  },
  {
    "id": "node/546288337"
  },
  {
    "id": "node/572116141"
  },
  {
    "id": "node/676729851"
  },
  {
    "id": "node/699702980"
  },
  {
    "id": "node/752807962"
  },
  {
    "id": "node/755600647"
  },
  {
    "id": "node/782832143"
  },
  {
    "id": "node/782895972"
  },
  {
    "id": "node/782998966"
  },
  {
    "id": "node/783045373"
  },
  {
    "id": "node/819251727"
  },
  {
    "id": "node/847912524"
  },
  {
    "id": "node/1039049719"
  },
  {
    "id": "node/1205446338"
  },
  {
    "id": "node/1805732865"
  },
  {
    "id": "node/1895912821"
  },
  {
    "id": "node/2279757010"
  },
  {
    "id": "node/4103536361"
  },
  {
    "id": "node/6528743285"
  }
]
;