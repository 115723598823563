import styled from 'styled-components';
import {NavLink} from 'react-router-dom';

export const LogoS = styled.div`
  width: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const MenuS = styled.div`
  position: relative;

  &:hover {
    cursor: pointer;
  }
`;

export const MenuDropS = styled.div.withConfig({
    shouldForwardProp: prop => !['visible'].includes(prop)
})<{ visible: boolean }>`
  position: absolute;
  right: 0;
  width: 200px;
  border: 1px solid var(--light-color);
  border-radius: 4px;
  overflow: hidden;
  z-index: 2;
  background-color: #ffffff;
  visibility: ${({visible}) => visible ? 'visible' : 'hidden'};

  @media
  max-width(600px) {
    width: 100%;
  }
`;

export const Navigate = styled.nav`
  display: flex;
  flex-direction: column;
`;

export const NavItem = styled.div.attrs<{ as: any }>(({as}) => ({
    as: NavLink || 'div'
}))`
  padding: 7px 10px;
  background-color: #ffffff;
  font-size: 14px;
  font-weight: 400;
  text-decoration: none;
  color: var(--text-color);

  &:hover {
    background-color: var(--overlay);
  }
`;

export const FormS = styled.form`
  width: 300px;
`;
