import React, {forwardRef} from 'react';

export const RuMow = forwardRef<SVGSVGElement, any>((props, ref) => {
    return (<svg className={'fade-in-svg'} ref={ref} xmlns="http://www.w3.org/2000/svg" version="1.2" baseProfile="tiny"
                 width="700" height="800"
                 viewBox="0 0 800 800" strokeLinecap="round" strokeLinejoin="round">
        <g id="RU-MOW">

        </g>
    </svg>);
});
