import {RefObject, useEffect, useRef} from 'react';
import {COLOR_MAP, COLOR_MAP_ACTIVE, COLOR_MAP_HOVER} from '../utils/constants';
import {regions} from '../components/map/RenderMap';

type SvgInteractiveHook = {
    svgRef: RefObject<SVGSVGElement>;
};

export type UseSvgHookOnClick = { regionId?: string, name?: string }

interface UseSvgProps {
    viewContent: any[],
    hover: (regionName: string) => void;
    click: ({regionId, name}: UseSvgHookOnClick) => void
}

export const useSvgInteractive = (
    {
        viewContent,
        click,
        hover
    }: UseSvgProps): SvgInteractiveHook => {
    const svgRef = useRef<SVGSVGElement>(null);

    useEffect(() => {
        const svgElement = svgRef.current;

        const handleMouseEnter = (event: Event) => {
            const target = event.target as SVGPathElement;
            const regionId = target.id;

            if (regionId) {
                target.setAttribute('fill', COLOR_MAP_HOVER); // Цвет при наведении

                // Ищем регион по ID
                const region = viewContent?.find(i => i.id === regionId);

                if (region) {
                    hover(region.name);
                }
            }
        };


        const handleMouseLeave = (event: Event) => {
            const target = event.target as SVGPathElement;
            const regionId = target.id;
            if (regionId) {
                target.setAttribute('fill', COLOR_MAP);
            }
        };


        const handleClick = (event: Event) => {
            const target = event.target as SVGPathElement;
            const regionId = target.id;

            if (regionId) {
                target.setAttribute('fill', COLOR_MAP_ACTIVE);
            }
            const region = viewContent?.find(i => i.id === regionId);

            if (region) {
                if (regions[regionId.replace('relation/', '')]) {
                    click({regionId, name: region.name || ''});
                } else {
                    click({regionId: region.id, name: region.name});
                }
            } else {
                event.preventDefault();
            }
        };

        if (svgElement) {
            svgElement.addEventListener('mouseenter', handleMouseEnter, true);
            svgElement.addEventListener('mouseleave', handleMouseLeave, true);
            svgElement.addEventListener('click', handleClick, true);
        }

        return () => {
            if (svgElement) {
                svgElement.removeEventListener('mouseenter', handleMouseEnter, true);
                svgElement.removeEventListener('mouseleave', handleMouseLeave, true);
                svgElement.removeEventListener('click', handleClick, true);
            }
        };
    }, [viewContent]);

    return {svgRef};
};
