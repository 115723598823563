export const names = [
  {
    "id": "relation/1395087",
    "name": "Домбаровский район"
  },
  {
    "id": "relation/1395088",
    "name": "Акбулакский район"
  },
  {
    "id": "relation/1395090",
    "name": "Гайский городской округ"
  },
  {
    "id": "relation/1395091",
    "name": "Беляевский район"
  },
  {
    "id": "relation/1395092",
    "name": "Кваркенский район"
  },
  {
    "id": "relation/1395094",
    "name": "Светлинский район"
  },
  {
    "id": "relation/1395095",
    "name": "Саракташский район"
  },
  {
    "id": "relation/1395096",
    "name": "городской округ Орск"
  },
  {
    "id": "relation/1395097",
    "name": "Тюльганский район"
  },
  {
    "id": "relation/1395099",
    "name": "Адамовский район"
  },
  {
    "id": "relation/1395127",
    "name": "Курманаевский район"
  },
  {
    "id": "relation/1395128",
    "name": "Ташлинский район"
  },
  {
    "id": "relation/1395129",
    "name": "Шарлыкский район"
  },
  {
    "id": "relation/1395130",
    "name": "Первомайский район"
  },
  {
    "id": "relation/1395131",
    "name": "Илекский район"
  },
  {
    "id": "relation/1395132",
    "name": "Соль-Илецкий городской округ"
  },
  {
    "id": "relation/1395133",
    "name": "Октябрьский район"
  },
  {
    "id": "relation/1395134",
    "name": "Северный район"
  },
  {
    "id": "relation/1395190",
    "name": "Тоцкий район"
  },
  {
    "id": "relation/1395226",
    "name": "Красногвардейский район"
  },
  {
    "id": "relation/1395227",
    "name": "Александровский район"
  },
  {
    "id": "relation/1395228",
    "name": "Грачёвский район"
  },
  {
    "id": "relation/1396080",
    "name": "Бузулукский район"
  },
  {
    "id": "relation/1396081",
    "name": "Бугурусланский район"
  },
  {
    "id": "relation/1396082",
    "name": "Асекеевский район"
  },
  {
    "id": "relation/1396083",
    "name": "Абдулинский городской округ"
  },
  {
    "id": "relation/1396084",
    "name": "Матвеевский район"
  },
  {
    "id": "relation/1396085",
    "name": "Пономарёвский район"
  },
  {
    "id": "relation/1396086",
    "name": "Сакмарский район"
  },
  {
    "id": "relation/1396087",
    "name": "Новосергиевский район"
  },
  {
    "id": "relation/1396088",
    "name": "Сорочинский городской округ"
  },
  {
    "id": "relation/1396089",
    "name": "Переволоцкий район"
  },
  {
    "id": "relation/1396090",
    "name": "Оренбургский район"
  },
  {
    "id": "relation/1396091",
    "name": "Новоорский район"
  },
  {
    "id": "relation/1396092",
    "name": "Ясненский городской округ"
  },
  {
    "id": "relation/1398505",
    "name": "городской округ Новотроицк"
  },
  {
    "id": "relation/1398584",
    "name": "городской округ Медногорск"
  },
  {
    "id": "relation/1398615",
    "name": "городской округ Оренбург"
  },
  {
    "id": "relation/1540277",
    "name": "городской округ Бузулук"
  },
  {
    "id": "relation/1927915",
    "name": "Кувандыкский городской округ"
  },
  {
    "id": "relation/2005276",
    "name": "городской округ Бугуруслан"
  },
  {
    "id": "relation/2573629",
    "name": "ЗАТО Комаровский"
  },
  {
    "id": "node/27505509"
  },
  {
    "id": "node/191907831"
  },
  {
    "id": "node/301014636"
  },
  {
    "id": "node/301425089"
  },
  {
    "id": "node/308877563"
  },
  {
    "id": "node/308879014"
  },
  {
    "id": "node/334357270"
  },
  {
    "id": "node/334357300"
  },
  {
    "id": "node/334357302"
  },
  {
    "id": "node/334357309"
  },
  {
    "id": "node/334357334"
  },
  {
    "id": "node/337839319"
  },
  {
    "id": "node/337839457"
  },
  {
    "id": "node/337839685"
  },
  {
    "id": "node/337839718"
  },
  {
    "id": "node/345178384"
  },
  {
    "id": "node/345178387"
  },
  {
    "id": "node/345178390"
  },
  {
    "id": "node/346597139"
  },
  {
    "id": "node/346802464"
  },
  {
    "id": "node/455899459"
  },
  {
    "id": "node/598985722"
  },
  {
    "id": "node/1013517210"
  },
  {
    "id": "node/1037436942"
  },
  {
    "id": "node/1109017198"
  },
  {
    "id": "node/1127773822"
  },
  {
    "id": "node/1131128968"
  },
  {
    "id": "node/1220099357"
  },
  {
    "id": "node/1220133697"
  },
  {
    "id": "node/1220133707"
  },
  {
    "id": "node/1220133722"
  },
  {
    "id": "node/1220133748"
  },
  {
    "id": "node/1309971091"
  },
  {
    "id": "node/1326360368"
  },
  {
    "id": "node/1368045362"
  },
  {
    "id": "node/1416924022"
  },
  {
    "id": "node/2013287005"
  },
  {
    "id": "node/4109311969"
  },
  {
    "id": "node/5050074013"
  }
]
;