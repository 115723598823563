import styled from 'styled-components';

type ButtonProps = {
    width: '100%' | number;
    color?: string;
}

export const ButtonS = styled.button.withConfig({})<ButtonProps>`
  width: ${({width}) => typeof width === 'string' ? width : `${width}px`};
  padding: 10px;
  font-size: 14px;
  font-weight: 400;
  color: ${({color}) => color};
  background-color: rgb(32, 89, 152);
  border: none;
  border-radius: 4px;
  outline: none;
  transition: 0.3s;
  transform: scale(1);

  &:hover {
    transition: 0.3s;
    cursor: pointer;
    background-color: rgb(21, 67, 121);
  }

  &:active {
    cursor: pointer;
    background-color: rgb(8, 39, 73);
    transform: scale(0.96);
  }
`;
