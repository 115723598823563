import styled from 'styled-components';
import {ButtonS} from '../../fields/ButtonS';

export const ContentCol = styled.div`
  width: 50%;
`;

export const TitleWrapper = styled.div.withConfig({
        shouldForwardProp: prop => !['margin', 'borderRadius'].includes(prop)
    }
)<{ margin: string; borderRadius: string }>`
  padding: 5px;
  border-radius: ${({borderRadius}) => borderRadius};
  background-color: #ffffff;
  width: 100%;
  margin: ${({margin}) => margin};
  border: 1px solid var(--border-color);
`;

export const DirectorCard = styled.div`
  width: 100%;
  padding: 10px;
  border: 1px solid var(--border-color);
  border-radius: 8px 4px 4px 4px;
  display: flex;
  column-gap: 10px;
  background-color: #ffffff;
`;

export const DirectorPhotoWrapper = styled.div`
  width: 80px;
  height: 120px;
  border-radius: 8px;
  border: 1px solid var(--border-color);
  overflow: hidden;
  display: flex;
  align-items: end;

`;

export const DirectorPhoto = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
`;

export const DirectorCardBody = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const Regalia = styled.div`
  display: flex;
  align-items: flex-end;
  width: 100%;
  padding: 5px 0;

  &:first-of-type {
    margin-top: 5px;
    border-top: 1px solid var(--border-color);
  }

  & span:first-of-type {
    width: 100px;
  }
`;

export const WorkActivityBlock = styled.div`
  width: 100%;
  padding: 5px;
  border-radius: 4px;
  border: 1px solid var(--border-color);
  display: flex;
  flex-direction: column;
  align-items: baseline;
`;

export const WorkActivityRow = styled.div.withConfig({
        shouldForwardProp: prop => !['reverse'].includes(prop)
    }
)<{ reverse: boolean }>`
  display: flex;
  align-items: center;
  flex-direction: ${({reverse}) => reverse ? 'row-reverse' : 'row'};
  width: 100%;
`;

export const WorkActivityCol = styled.div`
  width: calc(50% - 0.5px);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const WorkActivityCard = styled.div.withConfig({
        shouldForwardProp: prop => !['reverse'].includes(prop)
    }
)<{ reverse: boolean }>`
  background-color: #ffffff;
  border: 1px solid var(--border-color);
  border-radius: 8px;
  padding: 10px;
  width: 90%;
  ${({reverse}) => reverse ? 'margin-left: auto' : 'margin-right: auto'}
`;

export const WorkActivitySeparator = styled.div`
  width: 1px;
  background-color: var(--border-color);
  align-self: stretch;
`;

export const WorkActivityIcon = styled.div`
  display: flex;
  flex-grow: 1;
  height: 1px;
  background-color: var(--border-color);
  position: relative;
`;

export const WorkActivityCircle = styled.div.withConfig({
        shouldForwardProp: prop => !['reverse'].includes(prop)
    }
)<{ reverse: boolean }>`
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: var(--border-color);
  border-radius: 50%;
  top: -5px;
  ${({reverse}) => reverse ? 'left: -6px' : 'right: -6px'};
`;

export const RegionName = styled.div.withConfig({
    shouldForwardProp: prop => !['border'].includes(prop)
})<{ border?: string }>`
  padding: 0 0 10px 0;
  background-color: #ffffff;
  border: ${({border}) => border ? border : 'none'};
  border-radius: 4px;
`;

export const ToggleWrapper = styled.div`
  display: grid;
  row-gap: 5px;
`;

export const Toggle = styled(ButtonS)`
  background-color: var(--border-color);
  border: 1px solid var(--border-color);
  color: var(--title-light-color);

  &:hover {
    background-color: #ffffff;
    color: var(--title-light-color);
  }
`;
